import { Auth0Provider } from '@auth0/auth0-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import config from '../../api/config'
import { AUTH0_SCOPES } from '../../constants/auth0'

export const Auth0ProviderWithHistory: React.FC = ({ children }) => {
  const navigate = useNavigate()

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  if (!config?.auth0?.clientId || !config?.auth0?.domain || !config?.auth0?.audience) {
    throw Error('missing auth0 environment variables')
  }

  return (
    <Auth0Provider
      domain={config.auth0.domain}
      clientId={config.auth0.clientId}
      audience={config.auth0.audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      scope={AUTH0_SCOPES.join(' ')}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
