import { notification } from 'antd'
import { get } from 'lodash'

type Params = {
  onSuccessMsg?: string
  onErrorMsg?: string
  onSuccessCallback?: (data?: any) => void
  onErrorCallback?: () => void
}

export const mutationHandler = ({ onSuccessCallback, onErrorCallback, onSuccessMsg, onErrorMsg }: Params) => {
  return {
    onSuccess: (data: any) => {
      notification.success({ type: 'success', message: 'Success', description: onSuccessMsg, placement: 'bottomRight' })
      onSuccessCallback && onSuccessCallback(data)
    },
    onError: (err: any) => {
      notification.open({
        type: 'error',
        message: `Something went wrong - ${(get(err?.response?.data, 'message'), JSON.stringify(err?.response?.data))}`,
        description: onErrorMsg,
        placement: 'bottomRight',
      })
      onErrorCallback && onErrorCallback()
    },
  }
}
