import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { AdminWithRolesAndPermissions, adminService } from '../../../api/api-admin'

export const useAdminsWithRolesAndPermissions = (): UseQueryResult<AdminWithRolesAndPermissions[], Error> => {
  return useQuery(['getAllAdmins'], async () => {
    const { data: admins } = await adminService.getAllAdmins()

    if (admins) {
      const adminsWithRolesAndPermissions = admins.map(async (admin) => {
        const { data: roles } = await adminService.getAdminRoles(admin.user_id)
        const { data: permissions } = await adminService.getAdminPermissions(admin.user_id)
        return { ...admin, roles, permissions }
      })

      const results = await Promise.allSettled(adminsWithRolesAndPermissions)
      const filtered = results
        .filter((result) => result.status === 'fulfilled')
        .map((result) => (result as PromiseFulfilledResult<AdminWithRolesAndPermissions>).value)
      return filtered
    }
  })
}
