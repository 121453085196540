import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, notification, Radio, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { commentsService } from '../../api/api-comments'
import { GetAllCities, placeService } from '../../api/api-place'
import { userService } from '../../api/api-user'
import { Modal } from '../../components/Modal'
import { permissionsStore } from '../../data/permissions'
import { useStore } from '../../data/store'
import { useAllCountries } from '../../hooks/useAllCountries'
import { useCreateNewUser } from '../../hooks/useCreateNewUser'
import { User } from '../../types/user-api'
import AuthorizedButton from '../../components/Auth0/AuthorizedButton'

interface Props {
  data: any
  isModalVisible: boolean
  onClose: (updated: boolean) => void
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

const EditReviewForm: React.FC<Props> = ({ data, isModalVisible, onClose }) => {
  const { place } = useStore()
  const [disablePlacePick, setDisablePlacePick] = useState(false)
  const { continentData } = useAllCountries()
  const [form] = Form.useForm()
  const [allFakeUsers, setFakeUsers] = useState<Partial<User>[]>([])
  const [allCities, setAllCities] = useState<GetAllCities[]>([])
  const [searchedUsers, setSearchUsers] = useState<User[]>([])
  const [authorEmail, setAuthorEmail] = useState<string>('')

  const [hasPermissionToCreateUser, hasPermissionToUpdateComment] = permissionsStore
    .getState()
    .hasPermissions(['create:user', 'update:comment'])

  const { mutate: getAllCitiesMutation, isLoading: isGetAllCitiesLoading } = useMutation(
    ['getAllCities'],
    async (countryId: string) => {
      const { data } = await placeService.getAllCities(countryId)
      setAllCities(data)
    },
  )

  useQuery(['loadAllFakeUsers', setFakeUsers, setSearchUsers], async () => {
    const { data } = await userService.getAllFakeUsers()
    setFakeUsers(data)
    setSearchUsers(data)
  })

  const { mutate: createNewUserMutation, isLoading: isCreateUserLoading } = useCreateNewUser(authorEmail, (newuser) =>
    setFakeUsers([newuser, ...allFakeUsers]),
  )

  const { mutate: updateCommentMutation, isLoading: isUpdateCommentLoading } = useMutation(
    ['updateComment', form, searchedUsers],
    async () => {
      // Fix me - never mutate values
      const values = form.getFieldsValue()
      values.place_id = values.country
      if (values.city) {
        values.place_id = values.city
      }
      if (values.user_id) {
        const authorIndex = searchedUsers.findIndex((user) => user.id === values.user_id)
        values.comment_author_IP = searchedUsers[authorIndex].user_ip
      }
      return await commentsService.updateComment(data.id, values)
    },
    {
      onSuccess: (res) => {
        notification.open({
          type: 'success',
          message: 'Message',
          description: 'The Review was updated succesfully.',
        })
        if (place && place.place_type === 'city') {
          form.setFieldsValue({ country: place.parent.id, city: place.id })
        }
        onClose(true)
      },
    },
  )

  const { mutate: searchUserMutation, isLoading } = useMutation(['searchUser'], async (value: string) => {
    const { data } = await userService.searchUser(value)
    setSearchUsers(data)
  })

  const onChangeCountry = (val: string) => {
    form.setFieldsValue({ city: null })
    getAllCitiesMutation(val)
  }

  useEffect(() => {
    if (place && place.place_type === 'city') {
      form.setFieldsValue({ country: place.parent.id, city: place.id })
      getAllCitiesMutation(place.parent.id)
      setDisablePlacePick(true)
    } else {
      setDisablePlacePick(false)
    }
  }, [place])

  useEffect(() => {
    if (data) {
      if (data.place.place_type === 'country') {
        data.country = data.place.id
      }
      if (data.place.place_type === 'city') {
        data.city = data.place.id
        data.country = parseInt(data.place.place_parent)
        getAllCitiesMutation(data.place.place_parent)
      }
      form.setFieldsValue(data)
      setSearchUsers([...searchedUsers, data.author])
    }
  }, [data])

  return (
    <Modal
      title="Update Review"
      visible={isModalVisible}
      onOk={() => updateCommentMutation()}
      onCancel={() => onClose(false)}
      width={1000}
      footer={
        <div
          style={{
            display: 'grid',
            gap: 8,
            gridTemplateColumns: '1fr 1fr',
            width: 'fit-content',
            justifySelf: 'flex-end',
            marginLeft: 'auto',
          }}
        >
          <Button key="back" onClick={() => onClose(true)}>
            Close
          </Button>
          <AuthorizedButton
            hasPermission={hasPermissionToUpdateComment}
            key="submit"
            type="primary"
            loading={isUpdateCommentLoading}
            onClick={() => updateCommentMutation()}
          >
            Ok
          </AuthorizedButton>
        </div>
      }
    >
      <Form form={form} {...layout}>
        <Form.Item
          label="Author"
          name="user_id"
          tooltip={{ title: 'Author', icon: <InfoCircleOutlined /> }}
          required
          rules={[{ required: true, message: 'Author is required!' }]}
        >
          <Select
            showSearch
            style={{ width: '100%' }}
            notFoundContent={
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Not Found</span>{' '}
                <AuthorizedButton
                  hasPermission={hasPermissionToCreateUser}
                  loading={isCreateUserLoading}
                  onClick={() => createNewUserMutation()}
                >
                  Add New
                </AuthorizedButton>
              </div>
            }
            onSearch={searchUserMutation}
            loading={isLoading}
            filterOption={(input, option: any) => {
              setAuthorEmail(input.toLocaleLowerCase())
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
          >
            {searchedUsers.map((user) => (
              <Select.Option key={user.id} value={user.id}>
                {`${user.email} (${user.name})`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Country"
          name="country"
          required
          rules={[{ required: true, message: 'Country is required!' }]}
          tooltip={{ title: 'Country', icon: <InfoCircleOutlined /> }}
        >
          <Select
            disabled={disablePlacePick}
            showSearch
            style={{ width: '100%' }}
            loading={isGetAllCitiesLoading}
            onChange={onChangeCountry}
            filterOption={(input, option: any) => {
              if (option.children) {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              return false
            }}
          >
            {(continentData || []).map((continent, index) => (
              <Select.OptGroup key={index} label={continent.title}>
                {continent.countries.map((country, cIndex) => (
                  <Select.Option key={country.id} value={country.id}>
                    {country.title}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="City" name="city" tooltip={{ title: 'City', icon: <InfoCircleOutlined /> }}>
          <Select
            disabled={disablePlacePick}
            showSearch
            style={{ width: '100%' }}
            filterOption={(input, option: any) => {
              if (option.children) {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              return true
            }}
          >
            {allCities.map((city) => (
              <Select.Option key={city.id} value={city.id}>
                {city.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Reviews" name="comment_content" required>
          <Input.TextArea autoSize={true} showCount rows={8} />
        </Form.Item>

        <Form.Item label="Rating" name="ratings" required>
          <Radio.Group style={{ justifyContent: 'space-between', flexDirection: 'row', flex: '1' }} buttonStyle="solid">
            <Space size="small">
              <Radio.Button value={1}>1</Radio.Button>
              <Radio.Button value={2}>2</Radio.Button>
              <Radio.Button value={3}>3</Radio.Button>
              <Radio.Button value={4}>4</Radio.Button>
              <Radio.Button value={5}>5</Radio.Button>
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditReviewForm
