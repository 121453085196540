import { Layout, Menu } from 'antd'
import { PATHS, Routes } from '../constants/routes'
import { ToolOutlined } from '@ant-design/icons'
import { useMemo } from 'react'
import compact from 'lodash/compact'
import { useLocation } from 'react-router-dom'
import { CustomLink } from './CustomLink'
import { useTokenClaims } from '../hooks/useTokenClaims'
import { Auth0Roles, AUTH0_NAMESPACE } from '../constants/auth0'
import { isRoleAuthorized } from '../utils/auth/isRoleAuthorized'
import { get } from 'lodash'

type MenuItemProps = {
  to?: Routes
  icon?: any
  title: string
  children?: MenuItemProps[]
  type?: 'subMenu' | 'child'
}

function MenuItem({ to, icon, title, ...rest }: MenuItemProps) {
  return (
    <Menu.Item
      {...rest}
      icon={
        typeof icon === 'string' ? (
          <span>
            <img alt="" src={icon} />
          </span>
        ) : (
          icon
        )
      }
    >
      <CustomLink style={{ color: '#fff' }} to={to ?? ''}>
        {title}
      </CustomLink>
    </Menu.Item>
  )
}

const ownerAdminAuthorized = [Auth0Roles.OWNER, Auth0Roles.ADMIN]
const generalAuthorized = [...ownerAdminAuthorized, Auth0Roles.EDITOR]

export const Sidebar: React.FC = () => {
  const { claims } = useTokenClaims()
  const { roles } = get(claims, AUTH0_NAMESPACE) || {}
  const location = useLocation()

  const menuItems: MenuItemProps[] = useMemo(
    () =>
      compact([
        {
          to: PATHS.PLACES,
          icon: '/images/icons-places.svg',
          title: 'Places',
        },
        {
          to: PATHS.COMMENTS,
          icon: '/images/icons-reviews.svg',
          title: 'Reviews',
        },
        {
          to: PATHS.USERS,
          icon: '/images/icons-user.svg',
          title: 'Users',
        },
        isRoleAuthorized({ roles, authorized: ownerAdminAuthorized }) && {
          to: PATHS.ADMINS,
          icon: '/images/icons-admin.svg',
          title: 'Admin',
        },
        isRoleAuthorized({ roles, authorized: generalAuthorized }) && {
          to: PATHS.REGIONS,
          icon: '/images/icons-region.svg',
          title: 'Regions',
        },
        isRoleAuthorized({ roles, authorized: generalAuthorized }) && {
          to: PATHS.PHOTOS,
          icon: '/images/icons-media.svg',
          title: 'Photos',
        },
        isRoleAuthorized({ roles, authorized: generalAuthorized }) && {
          to: PATHS.REDIRECTIONS,
          icon: '/images/icons-redirection.svg',
          title: 'Redirections',
        },
        isRoleAuthorized({ roles, authorized: generalAuthorized }) && {
          to: PATHS.SEO,
          icon: '/images/icons-seo.svg',
          title: 'SEO',
        },
        isRoleAuthorized({ roles, authorized: generalAuthorized }) && {
          to: PATHS.TRANSLATION,
          icon: '/images/icons-translation.svg',
          title: 'Translation',
        },
        isRoleAuthorized({ roles, authorized: generalAuthorized }) && {
          type: 'subMenu',
          icon: <ToolOutlined />,
          title: 'Tools',
          children: [
            { title: 'Location Photo Counts', to: PATHS.ANALYSTIC_PHOTO },
            { title: 'Wakacje Offer Counts', to: PATHS.WAKACJE_LOG },
          ],
        },
      ]),
    [roles],
  )

  const defaultOpenKeys = useMemo(
    () =>
      menuItems
        .filter((item) => item.type === 'subMenu')
        .filter((item) =>
          item.children?.some((child) => child.to && location.pathname.split('/').some((route) => route === child.to)),
        )
        .map((item) => item.title),
    [menuItems, location.pathname],
  )

  const defaultSelectedKeys = useMemo(
    () =>
      menuItems
        .filter((item) => item.to && location.pathname.split('/').some((route) => route === item.to))
        .map((m) => m.title),
    [location.pathname, menuItems],
  )

  return (
    <Layout.Sider className="sider">
      <Menu theme="dark" mode="inline" defaultSelectedKeys={defaultSelectedKeys} defaultOpenKeys={defaultOpenKeys}>
        {menuItems.map((item) =>
          item?.children?.length ? (
            <Menu.SubMenu
              key={item.title}
              icon={
                typeof item.icon === 'string' ? (
                  <span>
                    <img alt="" src={item.icon} />
                  </span>
                ) : (
                  item.icon
                )
              }
              title={item.title}
            >
              {item.children.map((child) => (
                <MenuItem key={child.title} {...child} />
              ))}
            </Menu.SubMenu>
          ) : (
            <MenuItem key={item.title} {...item} />
          ),
        )}
      </Menu>
    </Layout.Sider>
  )
}
