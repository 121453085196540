import { Button, Card, notification, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { imageService } from '../../api/api-image'
import { permissionsStore } from '../../data/permissions'
import { GetAllPhotosResponse, ListFilesRequestParams } from '../../types/image-api'
import PhotoForm, { handleMessage } from './PhotoForm'
import PhotoGallery from './PhotoGallery'

export type FilterState = {
  filters: {
    tags: string[]
    sortValue: string
    sortDirection: string
    location: string
  }
  setFilters: (filters: FilterState['filters']) => void
}

export const defaultFilterState = {
  tags: [],
  sortValue: '',
  sortDirection: '',
  location: '',
}

type Props = {
  requestParams?: ListFilesRequestParams /** see https://docs.imagekit.io/api-reference/media-api/list-and-search-files */
}

const Photos: React.FC<Props> = ({ requestParams = {} }) => {
  const [selectedPhotos, setSelectedPhotos] = useState<string[]>([])
  const [openedPhoto, setOpenedPhoto] = useState<GetAllPhotosResponse | null>(null)
  const [hasPermissionToDelete] = permissionsStore.getState().hasPermissions(['delete:image'])

  const onTabChange = () => {
    setSelectedPhotos([])
    setOpenedPhoto(null)
  }

  const {
    mutate: deleteSelectedPhotos,
    isLoading: isDeleteLoading,
    isSuccess,
    error: deleteError,
  } = useMutation(() => imageService.deleteImages(selectedPhotos), handleMessage('delete'))

  const handleSelectPhoto = (photo: GetAllPhotosResponse) => {
    setSelectedPhotos((prevState) =>
      prevState.includes(photo.fileId) ? prevState.filter((id) => id !== photo.fileId) : [photo.fileId],
    )
  }

  useEffect(() => {
    if (deleteError) {
      notification.open({
        type: 'error',
        message: 'Something went wrong',
        description: `An error occurred trying to delete photos - ${JSON.stringify(deleteError)}`,
        placement: 'bottomRight',
      })
    }
  }, [deleteError])

  return (
    <>
      <Card
        title="Photos"
        extra={
          selectedPhotos.length > 0 && (
            <div style={{ maxHeight: 24 }}>
              <Button style={{ marginRight: 8 }} type="default" onClick={() => setSelectedPhotos([])}>
                Cancel
              </Button>
              <Button
                type="primary"
                danger
                loading={isDeleteLoading}
                disabled={!hasPermissionToDelete}
                onClick={() =>
                  hasPermissionToDelete &&
                  Modal.confirm({
                    title: 'Are you sure you want to delete the images from the CDN',
                    okText: 'Delete',
                    okType: 'danger',
                    onOk: () => deleteSelectedPhotos(),
                    cancelText: 'Cancel',
                    cancelButtonProps: {
                      style: {
                        backgroundColor: '#0ab068',
                        color: '#fff',
                      },
                    },
                  })
                }
              >
                Delete
              </Button>
            </div>
          )
        }
      >
        <PhotoGallery
          setSelectedPhoto={setOpenedPhoto}
          selectedPhotos={selectedPhotos}
          handleOpenPhoto={handleSelectPhoto}
          shouldRefresh={isSuccess}
          onTabChange={onTabChange}
          requestParams={requestParams}
        />
      </Card>
      <PhotoForm photo={openedPhoto} onClose={() => setOpenedPhoto(null)} />
    </>
  )
}

export default Photos
