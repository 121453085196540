import { useQuery } from '@tanstack/react-query'
import { placeService } from '../api/api-place'
import { PlaceDetail } from '../types/place-api'

export interface AllPlace {
  id: number
  title: string
  guid?: string
  place_type: string
}

export type AllPlaces = AllPlace[]

export const usePlaceDetail = (placeId?: string) => {
  const {
    data: { placeDetail, allPlaces } = {},
    isLoading,
    refetch,
  } = useQuery(['placeDetail', placeId], async () => {
    const placeDetail = (await (await placeService.getPlaceDetail(placeId)).data) as PlaceDetail
    const allPlaces = (await await placeService.getAllPlaces()).data as AllPlace[]
    return { placeDetail, allPlaces }
  })

  return { placeDetail, allPlaces, isLoading, refetch }
}
