import { Button, Card, Form, Input, Checkbox } from 'antd'
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { placeService } from '../../../../api/api-place'
import HTMLEditor from '../../../../components/HTMLEditor'
import { permissionsStore } from '../../../../data/permissions'
import { mutationHandler } from '../../../../utils/mutationHandler'
import { AugmentedCityCategoryData, CategoryKey } from './cityCategoryContents'
import { ListFilesRequestParams } from '../../../../types/image-api'

interface Props {
  data: AugmentedCityCategoryData
  cityId?: number
  categoryKey: CategoryKey
  requestParams?: ListFilesRequestParams
  refetch: () => void
}

const CityCategoryCard = React.forwardRef(({ categoryKey, cityId, data, refetch, requestParams }: Props, ref) => {
  const editorRef = useRef<any>(null)
  const [form] = Form.useForm()
  const isNewData = typeof data.id === 'undefined'
  const [toggleAddMore, setToggleAddMore] = useState<boolean>(!isNewData)
  const [isDirty, setIsDirty] = useState<Boolean>(false)
  const [hasPermissionToUpdate, hasPermissionToCreate, hasPermissionToDelete] = permissionsStore
    .getState()
    .hasPermissions(['update:place', 'create:place', 'delete:place'])

  const onSubmit = () => {
    if (isNewData) {
      hasPermissionToCreate && createCityContentMutation()
    } else {
      hasPermissionToUpdate && updateCityContentMutation()
    }
    setIsDirty(false)
  }

  useImperativeHandle(
    ref,
    () => ({
      saveDataIfDirty: () => {
        isDirty && onSubmit()
      },
    }),
    [isDirty],
  )

  useEffect(() => {
    form.setFieldsValue(data)
  }, [])

  const generatePayload = () => {
    return {
      ...form.getFieldsValue(),
      city_id: cityId,
      content_key: categoryKey,
      content: editorRef.current.getContent(),
    }
  }

  const { mutate: updateCityContentMutation, isLoading: isUpdateCityContentLoading } = useMutation(
    ['updateCityContent', form, data],
    async () => {
      const payload = generatePayload()
      return await placeService.updateCityContent(data.id, payload)
    },
    mutationHandler({
      onSuccessMsg: 'City content updated successfully',
      onSuccessCallback: () => refetch(),
      onErrorMsg: 'An error occurred trying to update city content',
    }),
  )

  const { mutate: createCityContentMutation, isLoading: isCreateCityContentContentLoading } = useMutation(
    ['createCityContent', form, data],
    async () => {
      const payload = generatePayload()
      return await placeService.createCityContent(payload)
    },
    mutationHandler({
      onSuccessMsg: 'City content created successfully',
      onSuccessCallback: () => refetch(),
      onErrorMsg: 'An error occurred trying to create city content',
    }),
  )

  const { mutate: deleteCategoryPageMutation, isLoading: isDeleteCategoryPageLoading } = useMutation(
    ['deleteCityContent', data, form],
    async () => await placeService.deleteCityContent(data.id),
    mutationHandler({
      onSuccessMsg: 'City content deleted successfully',
      onSuccessCallback: () => {
        form.resetFields()
        setToggleAddMore(false)
        refetch()
      },
      onErrorMsg: 'An error occurred trying to delete the city content',
    }),
  )

  if (cityId === 0) {
    return (
      <Card title={data.label}>
        <h1>Available after place's basic detail is ready.</h1>
      </Card>
    )
  }

  return (
    <Card
      title={data.label}
      bodyStyle={{ padding: 0 }}
      extra={
        toggleAddMore ? (
          <>
			<Form style={{ float: 'left', marginRight: 20 }} form={form} onValuesChange={() => setIsDirty(true)}>
				<Form.Item labelAlign="left" label="Premium?" name="premium" htmlFor={categoryKey+'-premium'} valuePropName="checked">
					<Checkbox id={categoryKey+'-premium'} name="premium" />
				</Form.Item>
			</Form>
            <Button
              disabled={!hasPermissionToDelete}
              type="default"
              loading={isDeleteCategoryPageLoading}
              onClick={() => hasPermissionToDelete && deleteCategoryPageMutation()}
            >
              Clear
            </Button>
            <Button
              style={{ marginLeft: 16 }}
              type="default"
              loading={isCreateCityContentContentLoading || isUpdateCityContentLoading}
              onClick={onSubmit}
              disabled={!hasPermissionToCreate || !hasPermissionToUpdate}
            >
              {isNewData ? 'Create' : 'Save'}
            </Button>
          </>
        ) : (
          <Button
            disabled={!hasPermissionToCreate || !hasPermissionToUpdate}
            type="primary"
            onClick={() => (hasPermissionToCreate || hasPermissionToUpdate) && setToggleAddMore(true)}
          >
            Add Content
          </Button>
        )
      }
    >
      {toggleAddMore && (
        <div style={{ padding: 24 }}>
          <Form form={form} style={{ marginTop: 20 }} onValuesChange={() => setIsDirty(true)}>
            Title:
            <Form.Item name="title">
              <Input />
            </Form.Item>
            Content:
            <HTMLEditor
              ref={editorRef}
              html={data.content}
              onChange={() => setIsDirty(true)}
              requestParams={requestParams}
            />
          </Form>
        </div>
      )}
    </Card>
  )
})

export default CityCategoryCard
