import { useQuery } from '@tanstack/react-query'
import { placeService } from '../api/api-place'

export const useAllCountries = () => {
  const {
    data: continentData,
    isLoading,
    error,
  } = useQuery(['getAllCountries'], async () => {
    const { data } = await placeService.getAllCountries()
    return data
  })

  return { continentData, isLoading, error }
}
