import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { authService } from '../../../api/api-auth'
import { PATHS } from '../../../constants/routes'
import { mutationHandler } from '../../../utils/mutationHandler'

export const useCreate2fa = ({ onErrorCallback }: { onErrorCallback: () => void }) => {
  const { MFA } = PATHS
  const navigate = useNavigate()

  return useMutation(
    ['create2fa', onErrorCallback, navigate],
    (payload: { token: string; secret: string }) => authService.create2FA(payload),
    mutationHandler({
      /** refetch the token with the updated appMeta */
      onSuccessCallback: () => {
        navigate(`/${MFA}/challenge`)
      },
      onErrorCallback: () => {
        onErrorCallback()
      },
    }),
  )
}
