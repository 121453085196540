import { AxiosInstance } from 'axios'
import { apiClient } from './apiClient'

class RedirectSerivce {
  public URI: string = '/redirections'
  constructor(private apiClient: AxiosInstance) {}

  public getAll = (offset = 0, limit = 20, query = '') => {
    return this.apiClient.get(`${this.URI}?offset=${offset}&limit=${limit}&query=${query}`)
  }

  public createRedirection = (data: unknown) => {
    return this.apiClient.post(`${this.URI}`, data)
  }

  public updateRedirection = (id: string | number, data: unknown) => {
    return this.apiClient.put(`${this.URI}/${id}`, data)
  }

  public deleteRedirection = (id: string | number) => {
    return this.apiClient.delete(`${this.URI}/${id}`)
  }

  public getRedirectionDetail = (id: string | number) => {
    return this.apiClient.get(`${this.URI}/detail/${id}`)
  }

  public searchRedirections = (query: string) => {
    return this.apiClient.get(`${this.URI}/search/${query}`)
  }
}

export const redirectService = new RedirectSerivce(apiClient)
