import { DeleteOutlined } from '@ant-design/icons'
import { Card, Space, Badge, Tooltip } from 'antd'
import { commentsService } from '../../../api/api-comments'
import moment from 'moment'
import CreateReviewForm from '../../Comments/CreateReviewForm'
import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { permissionsStore } from '../../../data/permissions'
import AuthorizedButton from '../../../components/Auth0/AuthorizedButton'
import CommentsTable from '../../../components/CommentsTable'

const ActionCell: React.FC<{ item: { id: string }; onChange: () => void }> = ({ item, onChange }) => {
  const hasPermissionToDelete = permissionsStore.getState().hasPermission('delete:comment')

  const { mutate: deleteCommentMutation, isLoading } = useMutation(
    ['deleteComment', item],
    async () => await commentsService.deleteComment(item.id),
    { onSuccess: () => onChange() },
  )

  return (
    <Space size="middle">
      <AuthorizedButton
        hasPermission={hasPermissionToDelete}
        loading={isLoading}
        icon={<DeleteOutlined />}
        onClick={() => deleteCommentMutation()}
      >
        Delete
      </AuthorizedButton>
    </Space>
  )
}

interface Props {
  id?: string
  placeId: any
  countryId?: string
}

type BadgeType = "gold" | 'silver' | 'bronze';

const CommentSection: React.FC<Props> = ({ id, placeId }) => {
  const hasPermissionToCreate = permissionsStore.getState().hasPermission('create:comment')
  const [tablePaginationOption, setTablePaginationOption] = useState<{
    total: number
    curPage: number
    pageSize: number
  }>({ total: 0, curPage: 1, pageSize: 10 })
  const [isModalVisible, setModalVisible] = useState(false)
  const [tableCounters, setTableCounters] = useState<{
    gold: number
    silver: number
    bronze: number
    free: number
    unmoderated: number
    transition: number
    total: number
  }>({
	gold: 0,
    silver: 0,
    bronze: 0,
    free: 0,
    unmoderated: 0,
    transition: 0,
	total: 0
  })

  const {
    data: tableData,
    isLoading,
    refetch,
  } = useQuery(['getComments', id, placeId, tablePaginationOption, setTablePaginationOption], async () => {
    const { pageSize, curPage } = tablePaginationOption
    const { data } = await commentsService.getCommentsOfPlace(placeId, pageSize * (curPage - 1), pageSize, '')
    setTablePaginationOption({ curPage, pageSize, total: data.total })
	
    return data
  })

  useEffect(() => {
	let counters = {
		total: 0,
		gold: 0,
		silver: 0,
		bronze: 0,
		free: 0,
		unmoderated: 0,
    	transition: 0
	}
	if( tableData ) {
		tableData.map( (item:any) => {
			counters.total++;

			if( item.type ) {
				counters[item.type as BadgeType]++;
			}
			if( item.free ) {
				counters.free++;
			}

			if( !item.moderated_at ) {
				counters.unmoderated++;
			}

			if( item.transition ) {
				counters.transition++;
			}
		} );
		setTableCounters(counters);
	}
  }, [tableData])

  const columns = [
    {
      title: 'Author',
      dataIndex: 'auth.name',
      key: 'comment_author',
      render: (text: string, item: any) => <div>{item.author ? item.author.name : ''}</div>,
    },
    {
      title: 'Email',
      dataIndex: 'comment_author_email',
      key: 'comment_author_email',
      render: (text: string, item: any) => <div>{item.author ? item.author.email : ''}</div>,
    },
    {
      title: 'Content',
      dataIndex: 'comment_content',
      key: 'comment_content',
    },
    {
      title: 'IP',
      dataIndex: 'comment_author_IP',
      key: 'comment_author_IP',
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text: string, record: any) => {
        const dateObj = new Date(text)
        return moment(dateObj).format('DD/MM/YY')
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (text: string, record: any) => <ActionCell item={record} onChange={() => refetch()} />,
    },
  ]

  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    setTablePaginationOption({ ...tablePaginationOption, curPage: pagination.current })
  }

  if (placeId === 0) {
    return (
      <Card id={id} title="Reviews">
        <h1>Available after place's basic detail is ready.</h1>
      </Card>
    )
  }

  return (
    <Card
      id={id}
      title="Reviews"
      extra={
		<>
			<>
				
				Total: &nbsp;
				<b style={{width: 30, display: 'inline-block'}}>
					{tableCounters.total}
				</b>
				&nbsp;&nbsp;&nbsp;&nbsp;
				
				<Badge color="red" />
				Unmod:&nbsp; 
				<b style={{width: 30, display: 'inline-block'}}>
					{tableCounters.unmoderated}
				</b>
				&nbsp;&nbsp;&nbsp;&nbsp;

				<Badge color="green" />
				Free: &nbsp;
				<b style={{width: 30, display: 'inline-block'}}>
					{tableCounters.free}
				</b>
				&nbsp;&nbsp;&nbsp;&nbsp;
				
				Transition: &nbsp;
				<b style={{width: 30, display: 'inline-block'}}>
					{tableCounters.transition ? 'YES' : 'NO'}
				</b>
				&nbsp;&nbsp;&nbsp;&nbsp;
				
				<Badge color="yellow" />
				Gold: &nbsp;
				<b style={{width: 30, display: 'inline-block'}}>
					{tableCounters.gold}
				</b>
				&nbsp;&nbsp;&nbsp;&nbsp;

				<Badge color="gray" />
				Silver: &nbsp;
				<b style={{width: 30, display: 'inline-block'}}>
					{tableCounters.silver}
				</b>
				&nbsp;&nbsp;&nbsp;&nbsp;

				<Badge color="brown" />
				Bronze: &nbsp;
				<b style={{width: 30, display: 'inline-block'}}>
					{tableCounters.bronze}
				</b>
				&nbsp;&nbsp;&nbsp;&nbsp;
				&nbsp;&nbsp;&nbsp;&nbsp;
			</>
			<AuthorizedButton hasPermission={hasPermissionToCreate} type="primary" onClick={() => setModalVisible(true)}>
				Add Comment
			</AuthorizedButton>
		</>
      }
    >
      <CommentsTable
        refetch={refetch}
        isLoading={isLoading}
        dataSource={tableData ?? []}
        onChange={onChange}
        tablePaginationOption={tablePaginationOption}
      />
      <CreateReviewForm
	    onSuccess={refetch}
        isModalVisible={isModalVisible}
		placeId={placeId}
        onClose={(updated: boolean) => {
          if (updated) {
            refetch()
          }
          setModalVisible(false)
        }}
      />
    </Card>
  )
}

export default CommentSection
