import create from 'zustand'
import { PlaceDetail } from '../types/place-api'

interface Datastore {
  place: PlaceDetail | null | any
  setPlace: (place: PlaceDetail | null) => void
}

export const useStore = create<Datastore>((set, get) => ({
  place: null,
  setPlace: (place) => set({ place }),
}))
