import { CloseOutlined } from '@ant-design/icons'
import { Button, Card, Form, Image, Input, notification, Select } from 'antd'
import { head } from 'lodash'
import React, { useEffect, useRef, useState, useImperativeHandle } from 'react'
import { useMutation } from '@tanstack/react-query'
import { GetAllCities, placeService } from '../../../../api/api-place'
import HTMLEditor from '../../../../components/HTMLEditor'
import { permissionsStore } from '../../../../data/permissions'
import { transformations } from '../../../../imagekit/helpers'
import { PlaceDetail, PlaceModel, PlaceTranslation, PlaceTranslationEmptyObject } from '../../../../types/place-api'
import { generateUrlFromTitle, getLastSlug } from '../../../../utils'
import { CountryCardData } from './types'

type ContentKey = 'activities' | 'nightlife' | 'sights' | 'nature' | 'offthepath' | 'beach'
export type ImageTag = 'hero' | 'natura' | 'plaze' | 'poza-szlakiem' | 'zwiedzanie' | 'zycie-nocne' | 'aktywnie'
export const imageTags: ImageTag[] = [
  'hero',
  'natura',
  'plaze',
  'poza-szlakiem',
  'zwiedzanie',
  'zycie-nocne',
  'aktywnie',
]

const contentKeyMap: Record<ContentKey, ImageTag> = {
  activities: 'aktywnie',
  nightlife: 'zycie-nocne',
  sights: 'zwiedzanie',
  nature: 'natura',
  offthepath: 'poza-szlakiem',
  beach: 'plaze',
}

interface Props {
  data: CountryCardData
  hideClear?: Boolean
  countryId: number
  parentPlace: PlaceDetail
  cities: GetAllCities[]
  refetch: () => void
  lang: string
}

const CountryCategoryCard = React.forwardRef(
  ({ parentPlace, countryId, data, cities, hideClear, refetch, lang  }: Props, ref) => {
    const categoryContentEditorRef = useRef<any>(null)
    const [form] = Form.useForm()
    const imageKey = contentKeyMap[data.category.uri as ContentKey]
    const image = head(parentPlace?.images?.filter?.((image) => image.tags.includes(imageKey)))
    const [childPlaces, setChildPlaces] = useState(data.child_places)
    //const categoryPageId = head(childPlaces)?.category_page_id ?? 0
    const categoryPageId = data?.id ?? 0
    const [toggleAddMore, setToggleAddMore] = useState<boolean>(!!data?.category_page?.content)
    const [isDirty, setIsDirty] = useState<Boolean>(false)
    const [hasPermissionToUpdate, hasPermissionToCreate] = permissionsStore
      .getState()
      .hasPermissions(['update:place', 'create:place'])

    const onSubmit = () => {
      if (data?.id) {
        hasPermissionToUpdate && updatePlaceDetailMutate()
      } else {
        hasPermissionToCreate && createCategoryPageMutate()
      }
      setIsDirty(false)
    }

    useImperativeHandle(
      ref,
      () => ({
        saveDataIfDirty: () => {
          isDirty && onSubmit()
        },
        generatePayload: () => {
          return generatePayload()
        },
      }),
      [isDirty, lang],
    )

    useEffect(() => {
      form.setFieldsValue({
        title: data?.category_page?.title,
        guid: data?.category_page?.guid,
      })
    }, []) //form, data

    const generatePayload = () => ({
      ...form.getFieldsValue(),
	  lang: lang,
      category_page_id: categoryPageId,
      content: categoryContentEditorRef?.current?.getContent(),
      place_parent_id: countryId,
      category_id: data.category.id,
      child_places: childPlaces.map((place) => {
        const { id, ...rest } = place
        return rest
      }),
    })

    const { mutate: deleteCategoryPageMutation, isLoading: isDeleteCategoryPageLoading } = useMutation(
      ['deleteCategoryPage', data],
      async () => {
        if (data?.category_page?.id) {
          return await placeService.deleteCategoryPage(data.category_page.id)
        }
      },
      {
        onSuccess: (data: unknown) => {
          notification.success({ message: 'Success', description: 'Category page deleted successfully' })
          refetch()
        },
        onError: (err: unknown) => {
          notification.open({
            type: 'error',
            message: 'Something went wrong',
            description: `An error occurred trying to delete the category page`,
            placement: 'bottomRight',
          })
        },
      },
    )

    const { mutate: updatePlaceDetailMutate, isLoading: isUpdateLoading } = useMutation(
      ['updatePlaceDetail', data, form, childPlaces, categoryPageId],
      async () => {
        const payload = generatePayload()
        return await placeService.updatePlaceDetail(data.category_page.id, payload)
      },
      {
        onSuccess: (data: unknown) => {
          notification.success({ message: 'Success', description: 'Category data updated successfully' })
          refetch()
        },
        onError: (err: unknown) => {
          notification.open({
            type: 'error',
            message: 'Something went wrong',
            description: `An error occurred trying to update place detail`,
            placement: 'bottomRight',
          })
        },
      },
    )

    const { mutate: createCategoryPageMutate, isLoading: isCreateLoading } = useMutation(
      ['createCategoryPage'],
      async () => {
        const payload = generatePayload()
        return await placeService.createCategoryPage(payload)
      },
      {
        onSuccess: (data: unknown) => {
          notification.success({ message: 'Success', description: 'Category page created successfully' })
          refetch()
        },
        onError: (err: unknown) => {
          notification.open({
            type: 'error',
            message: 'Something went wrong',
            description: `An error occurred trying to create category page`,
            placement: 'bottomRight',
          })
        },
      },
    )

    const handleNewCategoryPlaceChange = (value: number, index: number) => {
      let places = [...childPlaces]
      let item = { ...places[index] }
      item.sub_place_id = value
      places[index] = item
      setChildPlaces(places)
    }

    const addRow = () => setChildPlaces([...childPlaces, { id: 0, sub_place_id: 0, category_page_id: categoryPageId }])

    const removeCity = (index: number) =>
      setChildPlaces((prevChildPlaces) => prevChildPlaces.filter((_, i) => i !== index))


    const imageSearchRequestParams = parentPlace?.guid
      ? { searchQuery: `name: ${getLastSlug(parentPlace.guid)}` }
      : undefined


	  

  const [translatedContent, setTranslatedContent] = useState<PlaceTranslation | undefined>(data.category_page?.translations ? data.category_page.translations[0] : PlaceTranslationEmptyObject())
  useEffect(() => {
    if (data?.category_page) {
		let translated;
		if(data?.category_page?.translations) {
			translated = data?.category_page.translations.find(element => element.locale === lang);
		}
		if(!translated) {
			translated = PlaceTranslationEmptyObject()
		}
      	setTranslatedContent( translated )
		form.setFieldsValue( {
			...data?.category_page,
			...translated,
		})
    }
  }, [lang, data])

    if (countryId == 0) {
      return (
        <Card title={data.label}>
          <h1>Available after place's basic detail is ready.</h1>
        </Card>
      )
    }

    return (
      <Card
        title={data.label}
        bodyStyle={{ padding: 0 }}
        extra={
          hideClear ? null : toggleAddMore ? (
            <>
              <Button loading={isDeleteCategoryPageLoading} type="default" onClick={() => deleteCategoryPageMutation()}>
                Clear
              </Button>
            </>
          ) : (
            <Button type="primary" onClick={() => setToggleAddMore(true)}>
              Add Content
            </Button>
          )
        }
      >
        {toggleAddMore ? (
          <div style={{ padding: 24 }}>
            <Form
              form={form}
              style={{ marginTop: 20 }}
              onValuesChange={() => setIsDirty(true)}
              onChange={() => {
                const title = form.getFieldValue('title')
                if (title) {
                  form.setFieldsValue({ guid: `${parentPlace.guid}${generateUrlFromTitle(title)}/` })
                }
              }}
            >
              Title:
              <Form.Item name="title" rules={[{ required: true, message: 'required!' }]}>
                <Input />
              </Form.Item>
              Learn More Link:
              <Form.Item name="guid">
                <Input disabled />
              </Form.Item>
              Content:
              <HTMLEditor
                ref={categoryContentEditorRef}
                html={translatedContent?.content}
                requestParams={imageSearchRequestParams}
                onChange={() => setIsDirty(true)}
              />
              <br />
              Preview:
              <div className="imagePreview">
                {image?.url && (
                  <Image
                    src={image?.url ? `${image.url}?${transformations.edit_modal}` : ''}
                    preview={{ src: image.url }}
                  />
                )}
              </div>
              <br />
              Places:
              {childPlaces?.map((child, index) => (
                <Form.Item key={index}>
                  <div className="cityRow">
                    <span>{index + 1}.</span>
					{ lang === 'pl' && (<>
						<Select value={child.sub_place_id} onChange={(val) => handleNewCategoryPlaceChange(val, index)}>
						{cities.map((city, index) => (
							<Select.Option value={city.id} key={index}>
							{city.title}
							</Select.Option>
						))}
						</Select>
						<Button className="delBtnCity" onClick={() => removeCity(index)}>
						<CloseOutlined />
						</Button>
					</>) }
					{ lang !== 'pl' && (<>{cities.find( (city) => city.id === child.sub_place_id )?.title}</>) }
                  </div>
                </Form.Item>
              ))}			  
				{ lang === 'pl' && (<>
					<Form.Item>
						<Button
                  disabled={!hasPermissionToUpdate || !hasPermissionToCreate}
                  color="primary"
                  onClick={() => (hasPermissionToUpdate || hasPermissionToCreate) && addRow()}
                >
                  Add Row
                </Button>
					</Form.Item>
				</>)}
              <Form.Item>
                <Button
                  loading={isUpdateLoading || isCreateLoading}
                  type="primary"
                  htmlType="button"
                  onClick={() => (hasPermissionToUpdate || hasPermissionToCreate) && onSubmit()}
                  disabled={!hasPermissionToUpdate || !hasPermissionToCreate}
                >
                  {data.label ? `Save ${data.label}` : 'Save'}
                </Button>
              </Form.Item>
            </Form>
          </div>
        ) : null}
      </Card>
    )
  },
)

export default CountryCategoryCard
