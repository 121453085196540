import { useQuery } from '@tanstack/react-query'
import { placeService } from '../api/api-place'

export const useContinents = () => {
  const { data: continents, isLoading: continentsLoading } = useQuery(['getAllContinents'], async () => {
    const { data } = await placeService.getAllContinents()
    return data
  })
  return { continents, continentsLoading }
}
