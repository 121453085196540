import { Form, Input, Select } from 'antd'
import PhotoPicker from '../PhotoPicker'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import GoogleSearchPreviewCard from './GoogleSearchPreviewCard'
import { PlaceDetail, PlaceSeo, PlaceSeoTranslation, PlaceSeoTranslationEmptyObject } from '../../types/place-api'
import { useImageDetail } from '../../hooks/useImageDetail'

interface Props {
  placeDetail: PlaceDetail
  lang: string
}

const GoogleCard = React.forwardRef(({ placeDetail, lang }: Props, ref) => {
  const [form] = Form.useForm()
  const [imageId, setImageId] = useState<string | undefined>(placeDetail?.thumbnail?.id)
  const { image } = useImageDetail(imageId)

  useImperativeHandle(
    ref,
    () => ({
      getSeoDetail: () => {
		return { 
			seo: {
				...form.getFieldsValue(),
				lang: lang
			},
			thumbnail: image,
		}
      },
    }),
    [image, form, lang],
  )


  const [translatedContent, setTranslatedContent] = useState<PlaceSeoTranslation | undefined>(placeDetail?.seo?.translations ? placeDetail?.seo.translations[0] : PlaceSeoTranslationEmptyObject())
  useEffect(() => {
    if (placeDetail) {
		let translated;
		if(placeDetail?.seo?.translations) {
			translated = placeDetail?.seo?.translations.find(element => element.locale === lang);
		}
		if(!translated) {
			translated = PlaceSeoTranslationEmptyObject()
		}
      	setTranslatedContent( translated )
		form.setFieldsValue( {
			...placeDetail?.seo,
			...translated,
		})
    }
  }, [lang, placeDetail])


  return (
    <>
      <Form form={form}>
        SEO Title:
        <Form.Item name="title" rules={[{ required: true, message: 'required!' }]}>
          <Input 
            defaultValue={translatedContent?.title}
		  />
        </Form.Item>
        Meta Description:
        <Form.Item name="description" rules={[{ required: true, message: 'required!' }]}>
          <Input.TextArea 
            defaultValue={translatedContent?.description}
		  	autoSize={true} 
		  />
        </Form.Item>
        Allow search engines to show this Page in search results? (not working yet?)
        <Form.Item name="index" rules={[{ required: true, message: 'required!' }]}>
          <Select style={{ width: 120 }}>
            <Select.Option value="index">Yes</Select.Option>
            <Select.Option value="noindex">No</Select.Option>
          </Select>
        </Form.Item>
        Should search engines follow links on this Page (not working yet?)
        <Form.Item name="follow" rules={[{ required: true, message: 'required!' }]}>
          <Select style={{ width: 120 }}>
            <Select.Option value="follow">Yes</Select.Option>
            <Select.Option value="nofollow">No</Select.Option>
          </Select>
        </Form.Item>
      </Form>

      <PhotoPicker placeDetail={placeDetail} image={image} onChangePhoto={(imageId?: string) => setImageId(imageId)} />
      <br />
      <GoogleSearchPreviewCard placeDetail={placeDetail} image={image} />
    </>
  )
})

export default GoogleCard
