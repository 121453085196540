import { Button, Card, Col, notification, Row } from 'antd'
import React, { useRef } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { placeService } from '../../../../api/api-place'
import config from '../../../../api/config'
import SEOCard from '../../../../components/SEOCard'
import { StickyMenu } from '../../../../components/StickyMenu'
import { permissionsStore } from '../../../../data/permissions'
import { useStickyHeader } from '../../../../hooks/useStickyHeader'
import CountryCategoryCard from '../CountryForm/CountryCategoryCard'
import AuthorizedButton from '../../../../components/Auth0/AuthorizedButton'

interface Props {
  lang: string
  placeDetail: any
  refetch: () => void
}

const menuLinks = [
  { href: '#main-content', title: 'Category Contents' },
  { href: '#seo-section', title: 'SEO' },
  { href: '#translate-content', title: 'Translations' },
]

const ChildPlace: React.FC<Props> = ({ lang, placeDetail, refetch }) => {
  useStickyHeader()
  const categoryCardRef = useRef<any>()
  const seoCardRef = useRef<any>()
  const hasPermissionToUpdate = permissionsStore.getState().hasPermission('update:place')

  const { data: cities } = useQuery(['getAllCities', placeDetail], async () => {
    if (typeof placeDetail?.category_page?.place_id !== 'undefined') {
      const cities = await (await placeService.getAllCities(placeDetail.category_page.place_id)).data
      return cities
    }
  })

  const { mutate: updatePlaceMutation, isLoading: isUpdatePlaceLoading } = useMutation(
    ['updatePlaceDetail', placeDetail],
    async () => {
      const data = categoryCardRef.current.generatePayload()
      const { guid, ...rest } = data
      await (
        await placeService.updatePlaceDetail(placeDetail.id, rest)
      ).data
      return await placeService.saveSEOData(placeDetail.id, seoCardRef.current.getSeoDetail().googleValues)
    },
    {
      onSuccess: (data: unknown) => {
        notification.success({ message: 'Success', description: 'Category data updated successfully' })
        refetch()
      },
      onError: (err: unknown) => {
        notification.open({
          type: 'error',
          message: 'Something went wrong',
          description: `An error occurred trying to update place detail`,
          placement: 'bottomRight',
        })
      },
    },
  )

  if (!placeDetail?.category_page) {
    return null
  }

  return (
    <>
      <Row gutter={16}>
        <Col span={20}>
          <Card
            id="main-content"
            title={placeDetail.category_page.category_page.title}
            bodyStyle={{ margin: 0, padding: 0, backgroundColor: '#f0f2f5' }}
            extra={
              <>
                <Button
                  onClick={() =>
                    window.open(`${config.fontend}${placeDetail.category_page.category_page.guid}`, '_blank')
                  }
                >
                  Visit Page
                </Button>{' '}
                <AuthorizedButton
                  hasPermission={hasPermissionToUpdate}
                  loading={isUpdatePlaceLoading}
                  onClick={() => updatePlaceMutation()}
                >
                  Update
                </AuthorizedButton>
              </>
            }
          >
            {placeDetail.category_page && cities && (
              <CountryCategoryCard
                data={placeDetail.category_page}
                parentPlace={placeDetail.parent_place}
                countryId={placeDetail.category_page.place_id}
                cities={cities}
                refetch={refetch}
                hideClear={true}
                ref={categoryCardRef}
                lang={lang}
              />
            )}
            <br />
            <SEOCard lang={lang} placeDetail={placeDetail} id="seo-section" ref={seoCardRef} />
          </Card>
        </Col>
        <Col span={4}>
          <StickyMenu menuLinks={menuLinks} />
        </Col>
      </Row>
    </>
  )
}

export default ChildPlace
