import { notification, Card } from 'antd'
import React, { useEffect, useState } from 'react'
import { useToggle } from 'react-use'
import AuthorizedButton from '../../../components/Auth0/AuthorizedButton'
import GalleryDialog from '../../../components/GalleryDialog/GalleryDialog'
import { permissionsStore } from '../../../data/permissions'
import { PlaceDetail } from '../../../types/place-api'
import { CDNFolders } from '../../../imagekit/helpers'
import GalleryItem from './GalleryItem'
import { imageService } from '../../../api/api-image'
import { placeService } from '../../../api/api-place'
import { ImageModel } from '../../../types/image-api'

interface Props {
  id?: string
  placeDetail: PlaceDetail
  refetch: () => void
}
const Gallery: React.FC<Props> = ({ id, placeDetail, refetch }) => {
	const [isDialogOpen, toggle] = useToggle(false)
	const hasPermissionToCreate = permissionsStore.getState().hasPermission('create:image')
	
	const [gallery, setGallery] = useState<ImageModel[]>([]);
	
	useEffect(() => {
		setGallery( [ ...placeDetail?.images ] );
	}, [placeDetail])


	const linkImages = async (selectedPhotos: string[]) => {
		for(let i in selectedPhotos) {
			const selectedPhoto = selectedPhotos[i];
			
			const { data } = await imageService.linkImage({
				imageKitId: selectedPhoto,
				placeId: placeDetail?.id
			});
			notification.open({ 
				type: data.success ? 'success' : 'error', 
				message: data?.message ?? (data.success ? 'Success!' : 'Failure!'), 				
				// description: 'Photo!' 
			})
			updateAndRefetch();
		}
	}

	const updateAndRefetch = async () => {
		const { data } = await placeService.getGallery(placeDetail?.id);
		setGallery( data );
		refetch()
	}

  return (
    <div id={id}>
      <Card
        title="Gallery"
        extra={
          <AuthorizedButton hasPermission={hasPermissionToCreate} type="primary" onClick={() => toggle()}>
            Upload more
          </AuthorizedButton>
        }
      >
        {gallery.map((image) => (
          <GalleryItem key={image.id} image={image} refetch={updateAndRefetch} />
        ))}
      </Card>
      <GalleryDialog
        open={isDialogOpen}
        title={`Upload more - ${placeDetail.guid}`}
        tab="2"
        onSelect={(selectedPhotos:string[]) => {console.log( 'onSelect', selectedPhotos); linkImages(selectedPhotos); toggle()} }
        onClose={ () => {console.log( 'onClose'); toggle(); updateAndRefetch(); } }
        onUploadSuccess={() => {console.log( 'onUploadSuccess'); toggle(); updateAndRefetch(); } }
		predefinedPlaceId={placeDetail?.id.toString()}
		fixedFolder={`${CDNFolders.IMAGES}${placeDetail?.guid}`}
        cdnFolder={CDNFolders.IMAGES}
        requestParams={placeDetail?.guid ? { path: `${CDNFolders.IMAGES}${placeDetail.guid}` } : undefined}
      />
    </div>
  )
}

export default Gallery
