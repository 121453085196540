import { notification } from 'antd'
import { Editor } from '@tinymce/tinymce-react'
import React, { useImperativeHandle, useRef, useState } from 'react'
import { imageService } from '../../api/api-image'
import { permissionsStore } from '../../data/permissions'
import { transformations } from '../../imagekit/helpers'
import { ListFilesRequestParams } from '../../types/image-api'
import GalleryDialog from '../GalleryDialog/GalleryDialog'
import { pasteFromOffice } from './PasteHelper'
import { ImageModel } from '../../types/image-api'
import { AxiosError } from 'axios'

interface Props {
  html?: string
  requestParams?: ListFilesRequestParams
  onChange?: () => void
}

const HTMLEditor = React.forwardRef(({ html, requestParams, onChange }: Props, ref) => {
  const [visibleGalleryDialog, setVisibleGallery] = useState(false)
  const editorRef = useRef<any>(null)
  const hasPermissionToUpdate = permissionsStore.getState().hasPermission('update:image')

  useImperativeHandle(
    ref,
    () => ({
      getContent: () => {
        if (editorRef.current) {
          return editorRef.current.getContent()
        } else {
          return ''
        }
      },
    }),
    [],
  )

  const onSelectPhoto = async (imageIds: string[]) => {

	let imageDetail;
	try {
		const apiReposne = await imageService.getImageDetail(imageIds[0])
		console.log('apiReposne', apiReposne);
		imageDetail = apiReposne.data as ImageModel;
	} catch( e:unknown ) {
		let error = e instanceof AxiosError && e?.response?.status === 404 ? 'Image not found in the Database. Maybe it was uploaded from a dev version of the website. Please delete it from ImageKit and reupload it.' : 'Unknown error from ImageKit';
		
		notification.open({
			type: 'error',
			message: 'Something went wrong',
			description: error,
			placement: 'bottomRight',
		})
		return;
	}

    setVisibleGallery(false)
    const imgHTML = `
      <figure id="attachment_71597" class="wp-caption alignleft hero-image">
        <img 
          class="size-medium wp-image-71597" src="${imageDetail.url}?${transformations.hero}" 
          alt="${imageDetail.alt ?? ''}"}" 
          width="500" 
          height="326" 
          data-mce-src="${imageDetail.url}?${transformations.hero}" 
          data-mce-selected="1" 
        />
        ${imageDetail.description && `<figcaption class="caption">${imageDetail.description}</figcaption>`}
      </figure>`

    editorRef.current.insertContent(imgHTML)
  }

  //
  //E-Mail of the account that owns the key: admin@zielonamapa.pl
  //

  return (
    <>
      <script src="https://cdn.tiny.cloud/1/no-api-key/tinymce/5.10.5-131/tinymce.min.js"></script>
      {/** @ts-ignore */}
      <Editor
        apiKey="db9iwum1foahbq8ybmo7a9sfbz9ajbvvr2a1smkhcd28a70v"
        onInit={(evt, editor) => {
          editorRef.current = editor
        }}
        initialValue={html}
        onEditorChange={onChange}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            // 'print',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'code',
            'help',
            'wordcount',
            'paste',
            // 'powerpaste',
          ],
			link_class_list: [
				{ title: 'Plaintext Link', value: '' },
				{ title: 'Gray Button', value: 'gray-button' },
			],
			link_rel_list: [
				{title: 'None', value: ''},
				{title: 'follow', value: 'follow'},
				{title: 'nofollow', value: 'nofollow'},
				{title: 'external', value: 'external'}
			],
          setup: function (editor) {
            editor.ui.registry.addButton('imgInsertButton', {
              text: 'Insert Image',
              enabled: hasPermissionToUpdate,
              onAction: function () {
                setVisibleGallery(true)
              },
            })
          },
          toolbar: [
            'undo redo | link imgInsertButton | bold italic underline strikethrough | fontfamily fontsize blocks',
            'alignleft aligncenter alignright alignjustify |  outdent indent |  numlist bullist | removeformat codesample',
          ],
          content_style: 'body { font-family:OpenSans,sans-serif; font-size:14px } figure { margin: 0; }',
          content_css: '/styles/tinymce.css',
          paste_preprocess: function (plugin: any, args: any) {
            args.content = pasteFromOffice(args.content, '')
          },
          powerpaste_googledocs_import: 'clean',
          powerpaste_word_import: 'clean',
          powerpaste_html_import: 'clean',
        }}
      />

      <GalleryDialog
        open={visibleGalleryDialog}
        onSelect={onSelectPhoto}
        requestParams={requestParams}
        onClose={() => {
          setVisibleGallery(false)
        }}
      />
    </>
  )
})

export default HTMLEditor
