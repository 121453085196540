import { useEffect } from 'react'


const makeCardHeaderStickOnScroll = (ev: Event) => {
    const header = document.querySelector('#main-content .ant-card-head') as HTMLElement | null
    const tabs = document.querySelector('#lang-tabs') as HTMLElement | null
    const siteHeader = document.querySelector('.ant-layout-header') as HTMLElement
    if (header) {
		const card = header.closest('.ant-card')
		if (window.scrollY > 24) {
			if (!header.classList.contains('sticky')) {
				let topOffset = siteHeader.offsetHeight;
				if( tabs ) {
					tabs.style.zIndex = '10'
					tabs.style.background = 'white'
					tabs.style.position = 'fixed'
					tabs.style.top = topOffset + 'px'
					topOffset += tabs?.offsetHeight - 16;
				}
				header.classList.add('sticky')
				header.style.zIndex = '10'
				header.style.background = 'white'
				header.style.position = 'fixed'
				header.style.top = topOffset + 'px'

			}
			if( tabs ) {
				tabs.style.left = card?.getBoundingClientRect()?.left + 'px'
				tabs.style.width = card?.clientWidth + 'px'
			}
			header.style.left = card?.getBoundingClientRect()?.left + 'px'
			header.style.width = card?.clientWidth + 'px'

		} else if (window.scrollY <= 24 && header.classList.contains('sticky')) {
			header.classList.remove('sticky')
			header.style.removeProperty('z-index')
			header.style.removeProperty('background')
			header.style.removeProperty('position')
			header.style.removeProperty('top')
			header.style.removeProperty('left')
			header.style.removeProperty('width')
			
			if( tabs ) {
				tabs.style.removeProperty('z-index')
				tabs.style.removeProperty('position')
				tabs.style.removeProperty('top')
				tabs.style.removeProperty('left')
				tabs.style.removeProperty('width')

			}
		}
		header.style.left = card?.getBoundingClientRect()?.left + 'px'
		header.style.width = card?.clientWidth + 'px'
    }
}

export const useStickyHeader = () => {
  useEffect(() => {
    const events = ['scroll', 'resize']
    events.forEach((evt) => {
      window.addEventListener(evt, makeCardHeaderStickOnScroll)
    })

    return () => {
      events.forEach((evt) => {
        window.removeEventListener(evt, makeCardHeaderStickOnScroll)
      })
    }
  }, [])
}
