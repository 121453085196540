import { Table } from 'antd'
import moment from 'moment'
import { useConfig } from '../hooks/useConfig'

export interface CommentsTableParams {
  isLoading: boolean
  refetch: Function
  dataSource: any //todo
}

const PaymentsTable = ({ isLoading, refetch, dataSource,  }: CommentsTableParams) => {
	const { data: config, isLoading: isConfigLoading, refetch: refecthConfig } = useConfig()

	const columns = [
		{
			title: 'Date',
			key: 'date',
			render: (text: string, item: any) => (
				<div>
					{moment(item?.created_at).format('DD.MM.YYYY HH:mm')}
				</div>
			),
		},
		{
			title: 'Amount',
			key: 'amount',
			render: (text: string, item: any) => (
				<div>
					{item?.price} {item?.currency}
				</div>
			),
		},
		{
			title: 'Package',
			key: 'package',
			render: (text: string, item: any) => (
				<div>
					{ item?.package ? config?.userFields?.premium_plan[item?.package] : '?' }
				</div>
			),
		},
		{
			title: 'Meta',
			key: 'meta',
			render: (text: string, item: any) => (
				<div>
					{JSON.stringify(item?.meta) }
				</div>
			),
		},
	]

	return (
		<Table
			loading={isLoading}
			rowKey="id"
			columns={columns}
			dataSource={dataSource}
        	pagination={false} 
		/>
	)
}

export default PaymentsTable
