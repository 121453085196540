import { DeleteFilled, EditFilled } from '@ant-design/icons'
import { Button, Form, Input, notification } from 'antd'
import { placeService, ScrapeHotelPayload } from '../../../api/api-place'
import React, { useEffect, useState, useImperativeHandle } from 'react'
import { isValidURL } from '../../../utils'
import { PlaceDetail, WhereToStay, WhereToStayTranslation, WhereToStayTranslationEmptyObject } from '../../../types/place-api'
import { UseMutateFunction, useMutation } from '@tanstack/react-query'
import { transformations } from '../../../imagekit/helpers'
import { ImageModel } from '../../../types/image-api'
import TextArea from 'antd/lib/input/TextArea'
import { AxiosResponse } from 'axios'
import { permissionsStore } from '../../../data/permissions'

interface Props {
  data?: WhereToStay
  placeDetail?: PlaceDetail
  onChooseImage: (values: any) => void
  onDeleteImage?: () => void
  onDelete: () => void
  onCreate: UseMutateFunction<AxiosResponse<any, any>, unknown, WhereToStay, unknown>
  onSave: UseMutateFunction<any, unknown, any, unknown>
  lang: string
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
}

const HotelItem = React.forwardRef(
  ({ data, placeDetail, onChooseImage, onDeleteImage, onSave, onCreate, onDelete, lang }: Props, ref) => {
    const isDataNew = data?.id === 0
    const [form] = Form.useForm<WhereToStay | undefined>()
    const [photo, setPhoto] = useState<ImageModel | undefined>()
    const [isDirty, setIsDirty] = useState<Boolean>(false)

    const [hasPermissionToUpdate, hasPermissionToCreate, hasPermissionToDelete] = permissionsStore
      .getState()
      .hasPermissions(['update:place', 'create:place', 'delete:place'])

    const onSubmit = () => {
      if (isDataNew) {
        onCreate({ ...data, ...form.getFieldsValue(), photo_id: photo?.id ?? '' })
      } else {
        onSave({ ...data, ...form.getFieldsValue(), lang: lang })
      }
      setIsDirty(false)
    }

    useImperativeHandle(
      ref,
      () => ({
        saveDataIfDirty: () => {
          isDirty && onSubmit()
        },
      }),
      [isDirty, data, form, photo],
    )

    useEffect(() => {
      form.setFieldsValue(data)
    }, [])

    const { mutate: scrapeHotelData, isLoading: isScrapeHotelDataLoading } = useMutation(
      ['scrapeHotelDetailFromBookingCom', form, data],
      async () => {
        const link = form.getFieldValue('link')

        if (!isValidURL(link)) {
          alert('invalid url!')
          throw new Error('invalid url!')
        }

        if (!placeDetail?.guid || typeof placeDetail?.id === 'undefined') {
          alert('invalid payload')
          throw new Error('invalid payload')
        }

        const payload: ScrapeHotelPayload = {
          hotel_link: link,
          cdn_folder_path: placeDetail.guid,
          place_id: placeDetail.id,
        }

        const data = await (await placeService.scrapeHotelDetailFromBookingCom(payload))?.data
        const { name, photo, description, aggregateRating, stars } = data

        const currentFormData = form.getFieldsValue()

        const newHotelDetail = {
          ...data,
          stars,
          title: currentFormData?.title ? currentFormData.title : name,
          text: currentFormData?.text ? currentFormData.text : description,
          opinions: aggregateRating ? aggregateRating.reviewCount : 0,
          rating: aggregateRating ? aggregateRating.ratingValue : 0,
        } as any

        form.setFieldsValue(newHotelDetail)
        setPhoto(photo)
      },
      {
        onSuccess: (data: unknown) => {
          notification.success({ message: 'Success', description: 'Hotel scrape complete' })
        },
      },
    )

	
	const [translatedContent, setTranslatedContent] = useState<WhereToStayTranslation | undefined>(data?.translations ? data.translations[0] : WhereToStayTranslationEmptyObject())
	useEffect(() => {
		let translated;
		if(data?.translations) {
			translated = data?.translations.find(element => element.locale === lang);
		}
		if(!translated) {
			translated = WhereToStayTranslationEmptyObject()
		}
		setTranslatedContent( translated )

		form.setFieldsValue( {
			...data,
			...translated,
		})
	}, [lang, data])


    return (
      <div className="content">
        <Form {...layout} onValuesChange={() => setIsDirty(true)} form={form}>
          <Form.Item label="Name" name="title">
			      { lang ==='pl' ? <Input /> : <>{data?.title}</>}
          </Form.Item>
		  
			{ lang ==='pl' && (<>
				<Form.Item label="Link" name="link">
					<Input.Search
					enterButton={
						<Button
            disabled={!hasPermissionToUpdate}
						style={{ border: 'none', width: '100%', height: '100%', backgroundColor: 'transparent' }}
						loading={isScrapeHotelDataLoading}
						>
						Scrape for update
						</Button>
					}
					onSearch={() => scrapeHotelData()}
					/> 
				</Form.Item>
			</>)}

          
          <Form.Item label="Preview">
            <div className="imagePreview">
              <>
                <img
                  src={
                    data?.photo?.url
                      ? `${data?.photo.url}?${transformations.edit_modal}`
                      : photo?.url
                      ? `${photo.url}?${transformations.edit_modal}`
                      : ''
                  }
                />
				{ lang ==='pl' && (
                <div className="actionBar">
                  <span>
                    <EditFilled
                      disabled={!hasPermissionToUpdate}
                      onClick={() => hasPermissionToUpdate && onChooseImage(form.getFieldsValue())}
                      style={{ color: '#fff' }}
                    />
                  </span>
                  <span>
                    <DeleteFilled
                      disabled={!hasPermissionToDelete}
                      style={{ color: '#fff' }}
                      onClick={() => hasPermissionToDelete && onDeleteImage && onDeleteImage()}
                    />
                  </span>
                </div>
				)}
              </>
            </div>
          </Form.Item>

          <Form.Item label="Description" name="text">
            <TextArea 
				autoSize={true} 
				defaultValue={translatedContent?.text}
			/>
          </Form.Item>

          <Form.Item label="Stars" name="stars">
			{ lang ==='pl' ? <Input disabled /> : <>{data?.stars}</>}
          </Form.Item>

          <Form.Item label="Number of reviews" name="opinions">
			{ lang ==='pl' ? <Input disabled /> : <>{data?.opinions}</>}
          </Form.Item>

          <Form.Item label="Rating" name="rating">
			{ lang ==='pl' ? <Input disabled /> : <>{data?.rating}</>}
          </Form.Item>


			{ lang ==='pl' && (
				<Form.Item wrapperCol={{ offset: 4, span: 20 }}>
					<Button 
          disabled={!hasPermissionToUpdate || !hasPermissionToCreate}
          type="primary" 
          style={{ marginRight: 20 }} 
          onClick={() => (hasPermissionToUpdate || hasPermissionToCreate) && onSubmit()}
          >
					{isDataNew ? 'Create' : 'Save'}
					</Button>
            <Button
              disabled={!hasPermissionToDelete}
              type="default"
              onClick={() => hasPermissionToDelete && onDelete && onDelete()}
            >
              Delete Row
            </Button>
				</Form.Item>
			)}
        </Form>
      </div>
    )
  },
)

export default HotelItem
