import { useAuth0 } from '@auth0/auth0-react'
import { Result } from 'antd'
import React, { useEffect } from 'react'

const FourZeroThree: React.FC = () => {
  const { logout, isLoading } = useAuth0()

  useEffect(() => {
    if (!isLoading) {
      logout()
    }
  }, [logout, isLoading])

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Result status="403" title="403" subTitle="Sorry, you are not authorized to access this page." />
    </div>
  )
}

export default FourZeroThree
