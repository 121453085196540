import { Link, useResolvedPath } from 'react-router-dom'
import type { LinkProps } from 'react-router-dom'

export const CustomLink: React.FC<LinkProps> = ({ to, children, ...props }) => {
  let resolved = useResolvedPath(to)
  // let match = useMatch({ path: resolved.pathname, end: true })

  return (
    <div>
      <Link to={to} {...props}>
        {children}
      </Link>
    </div>
  )
}
