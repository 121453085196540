import { Table } from 'antd'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { placeService } from '../../api/api-place'
import { PATHS } from '../../constants/routes'

const WakacjeLog = () => {
  const columns: any = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'City',
      dataIndex: 'title',
      key: 'title',
      render: (text: string, item: any) => <a href={`https://travelbay.pl${item.guid}`}>{text}</a>,
    },
    {
      title: 'Link',
      dataIndex: 'title',
      key: 'link',
      render: (text: string, item: any) => (
        <Link to={`/${PATHS.DASHBOARD}/${PATHS.PLACES}/${item.id}`}>Link to place</Link>
      ),
    },
    {
      title: 'Offers',
      dataIndex: 'affiliates_count',
      key: 'affiliates_count',
      defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => a.photos - b.photos,
    },
  ]

  const { data: tableData, isLoading } = useQuery(['getWakacjeStats'], async () => {
    const { data } = await placeService.getWakacjeStats()
    return data
  })

  return (
    <>
      <Table
        rowKey="id"
        loading={isLoading}
        className="table-striped-rows"
        columns={columns}
        dataSource={tableData}
        pagination={false}
      />
    </>
  )
}

export default WakacjeLog
