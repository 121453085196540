import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined'
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined'
import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import MailOutlined from '@ant-design/icons/MailOutlined'
import { Badge, Descriptions, Button, Card, Modal, notification, Space, Table, Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { userService } from '../../../api/api-user'
import { default as apiConfig} from '../../../api/config'
import { permissionsStore } from '../../../data/permissions'
import AuthorizedButton from '../../../components/Auth0/AuthorizedButton'
import { adminService } from '../../../api/api-admin'
import { PATHS } from '../../../constants/routes'
import PaymentsTable from '../../../components/PaymentsTable'
import CommentsTable from '../../../components/CommentsTable'
import { commentsService } from '../../../api/api-comments'
import moment from 'moment'
import { useConfig } from '../../../hooks/useConfig'
import AjaxSelect from '../../../components/AjaxSelect'
import { AjaxSelectOption } from '../../../components/AjaxSelect'

const ActionCell: React.FC<{ onChange: () => void; item: any }> = ({ item, onChange }) => {
  const [userInfo, setUserInfo] = useState(item)
  const [hasPermissionToUpdate] = permissionsStore
    .getState()
    .hasPermissions(['update:user'])

	useEffect(() => {
		setUserInfo(item);
	}, [item])

  const { mutate: resetPasswordEmailMutation, isLoading: resetPasswordEmailLoading } = useMutation(
    ['resetPasswordEmail', userInfo],
    async () => await adminService.resetPasswordEmail(userInfo.email, 'zielonamapa-website'),
    {
      onSuccess: (res) => {
        notification.open({
          type: 'success',
          message: 'Success',
          description: `Reset password email sent`,
        })
        onChange()
      },
    },
  )

  const { mutate: verifyUserEmailMutation, isLoading: isVerifyUserEmailLoading } = useMutation(
    ['verifyUserEmail', userInfo],
    async () => await userService.verifyUser({ id: userInfo.id }),
    {
      onSuccess: (res) => {
        setUserInfo(res.data)
        notification.open({
          type: 'success',
          message: 'Success',
          description: `${userInfo.email} is verified.`,
        })
      },
    },
  )
  
  const { mutate: sendPasswordResetLinkMutation, isLoading: isResetPasswordLoading } = useMutation(
    ['sendPasswordReset', userInfo, onChange],
    async () => await adminService.resetPasswordEmail(userInfo.email, 'zielonamapa-website'),
    {
      onSuccess: (res) => {
        onChange()
        notification.open({
          type: 'success',
          message: 'Message',
          description: `The link to reset password is sent to ${userInfo.email}.`,
        })
      },
    },
  )

  const { mutate: removePremiumMutation, isLoading: isRemovePremiumLoading } = useMutation(
    ['sendPasswordReset', userInfo, onChange],
    async () => await userService.removePremium({ id: userInfo.id }),
    {
      onSuccess: (res) => {
        onChange()
        notification.open({
          type: 'success',
          message: 'Message',
          description: `This user is no longer Premium.`,
        })
      },
    },
  )

  const { mutate: addPremiumMutation, isLoading: isAddPremiumLoading } = useMutation(
    ['sendPasswordReset', userInfo, onChange],
    async (type:string) => await userService.addPremium({ id: userInfo.id, type: type }),
    {
      onSuccess: (res) => {
        onChange()
        notification.open({
          type: 'success',
          message: 'Message',
          description: `This user is now Premium.`,
        })
      },
    },
  )

  return (
	
	<Descriptions bordered>
		<Descriptions.Item label="Password">
			<AuthorizedButton
				hasPermission={hasPermissionToUpdate}
				loading={isResetPasswordLoading}
				icon={<MailOutlined />}
				onClick={() => sendPasswordResetLinkMutation()}
			>
				Send Reset Password Email
			</AuthorizedButton>
		</Descriptions.Item>
		<Descriptions.Item label="Premium">
			{userInfo.premium ? (
				<>
					<AuthorizedButton
						icon={<CloseCircleOutlined />}
						hasPermission={hasPermissionToUpdate}
						loading={isRemovePremiumLoading}
						onClick={() => removePremiumMutation() }
					>
						Remove Premium
					</AuthorizedButton>
					<span>&nbsp;</span>
				</>
			) : ''}
			<AuthorizedButton
				icon={<CheckCircleOutlined />}
				hasPermission={hasPermissionToUpdate}
				loading={isAddPremiumLoading}
				onClick={() => addPremiumMutation('monthly') }
			>
				Add 1 Month Premium
			</AuthorizedButton>
			<span>&nbsp;</span>
			<AuthorizedButton
				icon={<CheckCircleOutlined />}
				hasPermission={hasPermissionToUpdate}
				loading={isAddPremiumLoading}
				onClick={() => addPremiumMutation('yearly') }
			>
				Add 1 Year Premium
			</AuthorizedButton>
			
			<span>&nbsp;</span>
			<AuthorizedButton
				icon={<CheckCircleOutlined />}
				hasPermission={hasPermissionToUpdate}
				loading={isAddPremiumLoading}
				onClick={() => addPremiumMutation('permanent') }
			>
				Add Permanent Premium
			</AuthorizedButton>
		</Descriptions.Item>
		{(!userInfo.email_verified_at || userInfo.status !== 'verified') ? (
			<Descriptions.Item label="Verification">
				{!userInfo.email_verified_at && (
					<AuthorizedButton
					hasPermission={hasPermissionToUpdate}
					icon={<MailOutlined />}
					loading={resetPasswordEmailLoading}
					onClick={() => resetPasswordEmailMutation()}
					>
					Send Reset Password Email
					</AuthorizedButton>
				)}
				{userInfo.status !== 'verified' && (
					<AuthorizedButton
					loading={isVerifyUserEmailLoading}
					hasPermission={hasPermissionToUpdate}
					onClick={() => verifyUserEmailMutation()}
					>
					Verify Email
					</AuthorizedButton>
				)}
			</Descriptions.Item>
		) : ''}
	</Descriptions>
  )
}

const DangerCell: React.FC<{ onChange: () => void; item: any }> = ({ item, onChange }) => {

	
  const [hasPermissionToReadAdmins] = permissionsStore
    .getState()
    .hasPermissions(['read:admin'])

  const {
    data: adminsListRaw,
  } = useQuery(['getAllAdmins'], async () => {
	if(!hasPermissionToReadAdmins) {
		return [];
	}
    const { data } = await adminService.getAllAdmins()
    return data
  })

  const [adminsList, setAdminsList] = useState<AjaxSelectOption[]>([{
	key: '',
	value: 'None'
  }])
  useEffect( () => {
	const list:AjaxSelectOption[] = [{
		key: '',
		value: 'None'
	}];
	if( adminsListRaw && adminsListRaw.length ) {
		adminsListRaw.map( (admin) => { 
			if( admin?.app_metadata?.database_admin_id ) {
				list.push({
					key: admin?.app_metadata?.database_admin_id.toString(),
					value: admin.name + ' ('+admin.email+')'
				}) 
			}
		} )
	}
	setAdminsList(list);
  }, [adminsListRaw] )
  

  const [userInfo, setUserInfo] = useState(item)
  const navigate = useNavigate()
  const [hasPermissionToDelete] = permissionsStore
    .getState()
    .hasPermissions(['delete:user'])

	useEffect(() => {
		setUserInfo(item);
	}, [item])

  const { mutate: deleteUserMutation, isLoading: isDeleteUserLoading } = useMutation(
    ['deleteUser', userInfo],
    async () => await userService.deleteUser(userInfo.id),
    {
      onSuccess: (res) => {
        notification.open({
          type: 'success',
          message: 'Success',
          description: `User deleted`,
        })
        onChange()
        navigate(`/${PATHS.DASHBOARD}/${PATHS.USERS}`)
      },
    },
  )

  const { mutate: setAdminMutation, isLoading: isSetAdminLoading } = useMutation(
    ['deleteUser', userInfo],
    async (payload:{id: any; field: any; field_value: any}) => await userService.toggleUser(payload),
    {
      onSuccess: (res) => {
        onChange()
        notification.open({
          type: 'success',
          message: 'Success',
          description: `User Account Linked Successfully`,
        })
      },
    },
  )

  return (
	
	<Descriptions bordered>
		<Descriptions.Item label="Delete">
			<AuthorizedButton
				hasPermission={hasPermissionToDelete}
				loading={isDeleteUserLoading}
				icon={<DeleteOutlined />}
				onClick={() =>
				hasPermissionToDelete &&
				Modal.confirm({
					centered: true,
					title: 'Are you sure you want to delete this user along with its Reviews, Visited & Wishlist?',
					okText: 'Delete',
					okType: 'default',
					cancelText: 'No, do NOT delete',
					okButtonProps: {
						style: {
							backgroundColor: '#fff',
						},
					},
					cancelButtonProps: {
						style: {
							backgroundColor: '#0ab068',
							color: '#fff',
						},
					},
					onOk() {
						deleteUserMutation()
					},
				})
				}
			>
				Delete User Permanently
			</AuthorizedButton>
		</Descriptions.Item>
		<Descriptions.Item label="Linked Admin account">
			{hasPermissionToReadAdmins ? (
				<AjaxSelect 
					callback={setAdminMutation} 
					options={adminsList}
					item={item} 
					field={'admin_id'}
				/>
			) : (
				<>
					{item?.admin ? item?.admin?.name + ' ('+item.admin.email+')' : 'None'}
				</>
			)}
		</Descriptions.Item>
	</Descriptions>
  )
}



export type UserNoteFormModel = {
  note: string
}

const UserForm: React.FC = () => {
  const params = useParams()
  const userId = params.userId
  const { data: config, isLoading: isConfigLoading, refetch: refecthConfig } = useConfig()
  
  const {
    data: userData = [],
    isLoading,
    refetch,
  } = useQuery(
    ['getUserDetailById', userId],
    async () => {
      if (userId && userId !== 'create') {
        const { data } = await userService.getUserDetailById(userId)
        return data ? [data] : []
      }
    },
    { staleTime: 60 * 1000 * 5 },
  )

  const [currentQuery, setCurrentQuery] = useState('')
  const [tablePaginationOption, setTablePaginationOption] = useState<{
    total: number
    curPage: number
    pageSize: number
  }>({ total: 0, curPage: 1, pageSize: 10 })

  const { data: reviewsTableData } = useQuery(['allComments', currentQuery, tablePaginationOption], async () => {
    let query = currentQuery
    const { pageSize, curPage } = tablePaginationOption

    const { data } = await commentsService.getAll(pageSize * (curPage - 1), pageSize, query, userId)
    setTablePaginationOption({ curPage, pageSize, total: data.total })
    return data.data
  })

  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    setTablePaginationOption({ ...tablePaginationOption, curPage: pagination.current })
  }


	const isPaymentsLoading = false;
	const [payments, setPayments] = useState([])
  	useEffect(() => {
		setPayments(userData.length ? userData[0].payments : []);
	}, [userData])

	
  	const [notesForm] = Form.useForm<UserNoteFormModel>()
	const notesColumns = [
		{
			title: 'No.',
			key: 'index',
			render: (text:string, record: any, index:number) => (index+1),
		},
		{
			title: 'Note',
			dataIndex: 'note',
			key: 'note',
			render: (text: string, item: any) => (
				<div style={{ whiteSpace: 'pre'}}>
					{item.note}
				</div>
			),
		},
		{
			title: 'Creator',
			key: 'admin_name',
			render: (text: string, item: any) => (
				<div>
					{item?.admin?.name}
				</div>
			),
		},
		{
			title: 'Date',
			key: 'created_at',
			render: (text: string, item: any) => (
				<div>
				{moment(new Date(item.created_at)).format('DD.MM.YYYY HH:mm')}
				</div>
			),
		},
	]
	const addNote = async () => {
		if( userId ) {
			const payload = {
				userId: userId,
				note: notesForm.getFieldsValue().note,
			}
			const { data } = await userService.addNote(payload);
			notification.open({ type: 'success', message: 'Success', description: 'Note added!' })
			notesForm.setFieldsValue( {
				note: ''
			} );
			refetch();
		}
	}

  if (userData.length) {
    return (
		<>
			<Card
				title={<h2 style={{ fontWeight: 'bold' }}>{userData[0].name}</h2>}
				extra={
				<Button 
					type="primary"
					onClick={() => window.open(`${apiConfig.fontend}uzytkownik/${userData[0].guid}`, '_blank')}
				>
					Visit Profile
				</Button>
				}
				bodyStyle={{ padding: 0 }}
			>
				<Descriptions bordered>
					<Descriptions.Item label="Joined">
						{moment(userData[0].created_at).format('DD.MM.YYYY HH:mm')} ({moment().diff( moment(userData[0].created_at), 'days' )} days ago)
					</Descriptions.Item>
					<Descriptions.Item label="E-Mail">
						{userData[0].email}
					</Descriptions.Item>
					<Descriptions.Item label="Premium">
						{userData[0].premium ? 'YES ' : 'NO '}
						{ userData[0].premium ? (
							<>
								{userData[0].premium ? (<>
									(expires { userData[0].premium_expires_at ? moment(userData[0].premium_expires_at).format('DD.MM.YYYY') + ' / in '+moment(userData[0].premium_expires_at).diff( moment(), 'days' )+' days' : 'NEVER'})
								</>
								) : ''}
								<br/>
								{userData[0].premium_plan ? config?.userFields?.premium_plan[userData[0].premium_plan] : 'Unlimited Plan'}
							</>
						) : ''}
					</Descriptions.Item>
					<Descriptions.Item label="Gender">
						{ userData[0].gender ? config?.userFields?.gender[userData[0].gender] : '-' }
					</Descriptions.Item>
					<Descriptions.Item label="Travel type">
						{ userData[0].travel_option ? config?.userFields?.travel_option[userData[0].travel_option] : '-' }
					</Descriptions.Item>
					<Descriptions.Item label="Interests">
						{ userData[0].categories && Array.isArray(userData[0].categories) ? userData[0].categories.map( (cat:string) => <>{config?.userFields?.categories[cat]}<br/></> ) : '-' }
					</Descriptions.Item>
					<Descriptions.Item label="Facebook">
						{ userData[0].facebook }
					</Descriptions.Item>
					<Descriptions.Item label="Twitter">
						{ userData[0].twitter }
					</Descriptions.Item>
					<Descriptions.Item label="Instagram">
						{ userData[0].instagram }
					</Descriptions.Item>
					<Descriptions.Item label="YouTube">
						{ userData[0].youtube }
					</Descriptions.Item>
					<Descriptions.Item label="Web">
						{ userData[0].website }
					</Descriptions.Item>
					<Descriptions.Item label="Privacy">
						{ userData[0].privacy_option ? config?.userFields?.privacy_option[userData[0].privacy_option] : '-' }
					</Descriptions.Item>
					<Descriptions.Item label="Wishlisted">
						{userData[0].wishlist.length}
					</Descriptions.Item>
					<Descriptions.Item label="Visited">
						{userData[0].visited.length}
					</Descriptions.Item>
					<Descriptions.Item label="Reviews">
						{userData[0].comments.length}
					</Descriptions.Item>
				</Descriptions>
			</Card>
			<Card title={<h2 style={{ fontWeight: 'bold' }}>Actions</h2>} bodyStyle={{ padding: 0 }}>
				<ActionCell item={userData[0]} onChange={() => refetch()} />
			</Card>
			<Card title={<h2 style={{ fontWeight: 'bold' }}>Reviews</h2>} bodyStyle={{ padding: 0 }}>
				<CommentsTable
				refetch={refetch}
				isLoading={isLoading}
				dataSource={reviewsTableData ?? []}
				onChange={onChange}
				tablePaginationOption={tablePaginationOption}
				/>
			</Card>
			<Card title={<h2 style={{ fontWeight: 'bold' }}>Payments</h2>} bodyStyle={{ padding: 0 }}>
				<PaymentsTable
				refetch={refetch}
				isLoading={isPaymentsLoading}
				dataSource={payments ?? []}
				/>
			</Card>
			<Card title={<h2 style={{ fontWeight: 'bold' }}>Notes</h2>} bodyStyle={{ padding: 0 }}>
				<Table
        			pagination={false} 
					rowKey="id"
					columns={notesColumns}
					dataSource={userData[0].notes}
				/>


				<div style={{ padding: '0px 16px 16px 16px' }}>
					<Form form={notesForm} style={{ marginTop: 20 }}>
						<Form.Item name="note">
							<Input.TextArea
								placeholder="Enter an internal note, accessible only by the team"
							/>
						</Form.Item>
						<Button type="primary" onClick={addNote} >
							Add Note
						</Button>
					</Form>
				</div>
			</Card>
			<Card title={<h2 style={{ fontWeight: 'bold', color: 'red' }}>Danger Zone</h2>} bodyStyle={{ padding: 0 }}>
				<DangerCell item={userData[0]} onChange={() => refetch()} />
			</Card>
		</>
    )
  }
  return null
}

export default UserForm
