import React, { useEffect, useState } from 'react'
import { CustomLink } from '../../components/CustomLink'

import { Badge, Button, Input, Modal, Space, Table, Tooltip } from 'antd'
import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import { placeService } from '../../api/api-place'
import { PlaceTranslation } from '../../types/place-api'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useConfig } from '../../hooks/useConfig'
import { QuestionCircleOutlined, CheckCircleOutlined, EyeInvisibleOutlined, EyeOutlined, HourglassOutlined } from '@ant-design/icons';

import { permissionsStore } from '../../data/permissions'
import AuthorizedButton from '../../components/Auth0/AuthorizedButton'


type ActionCellProps = {
  item: { id: string }
  refetch: () => void
}

const modalConfirmProps = {
  centered: true,
  title: 'Are you sure you want to delete this place?',
  okText: 'Delete',
  okType: 'default',
  cancelText: 'No, do not delete',
  okButtonProps: {
    style: {
      backgroundColor: '#fff',
    },
  },
  cancelButtonProps: {
    style: {
      backgroundColor: '#0ab068',
      color: '#fff',
    },
  },
} as const

const ActionCell: React.FC<ActionCellProps> = ({ item, refetch }) => {
  const hasPermission = permissionsStore.getState().hasPermission('delete:place')

  const { mutate: deletePlaceMutation, isLoading } = useMutation(
    ['deletePlace', item.id],
    async () => await placeService.deletePlace(item.id),
    { onSuccess: (res) => refetch() },
  )

  return (
    <Space size="middle">
      <AuthorizedButton
        hasPermission={hasPermission}
        loading={isLoading}
        icon={<DeleteOutlined />}
        onClick={() =>
          Modal.confirm({
            ...modalConfirmProps,
            onOk: () => deletePlaceMutation(),
          })
        }
      >
        Delete
      </AuthorizedButton>
    </Space>
  )
}

type PaginationOptions = {
  total: number
  curPage: number
  pageSize: number
}

export const AllPlacesTable: React.FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [query, setQuery] = useState('')
  const hasPermissionToCreate = permissionsStore.getState().hasPermission('create:place')

  const [tablePaginationOption, setTablePaginationOption] = useState<PaginationOptions>({
    total: 0,
    curPage: 1,
    pageSize: 10,
  })

  const {
    data: tableData,
    isLoading,
    refetch,
  } = useQuery(['tableData', tablePaginationOption], async () => {
    const { data } = await placeService.getFiltered(
      tablePaginationOption.pageSize * (tablePaginationOption.curPage - 1),
      tablePaginationOption.pageSize,
      query,
    )
    setTablePaginationOption((prevState) => ({ ...prevState, total: data.total }))
    return data?.data
  })

  const defaultColumns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'consultant_name',
      width: '12%',
      render: (text: string, item: any) => <CustomLink to={`${pathname}/${item.id}`}>{text}</CustomLink>,
    },
    {
      title: 'Search Label',
      dataIndex: 'search_label',
      key: 'search_label',
      width: '15%',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: any) => <ActionCell item={record} refetch={refetch} />,
    },
    {
      title: 'Reviews',
      key: 'comments',
      width: '100%',
      render: (text: string, record: any) => {
		return <>
			Total: &nbsp;
			<b style={{width: 30, display: 'inline-block'}}>
				{record.comments_count}
			</b>
			&nbsp;&nbsp;&nbsp;&nbsp;
			
			<Badge color="red" />
			Unmod:&nbsp; 
			<b style={{width: 30, display: 'inline-block'}}>
				{record.comments_unmoderated_count}
			</b>
			&nbsp;&nbsp;&nbsp;&nbsp;

			<Badge color="green" />
			Free: &nbsp;
			<b style={{width: 30, display: 'inline-block'}}>
				{record.comments_free_count}
			</b>
			&nbsp;&nbsp;&nbsp;&nbsp;
			
			Transition: &nbsp;
			<b style={{width: 30, display: 'inline-block'}}>
				{record.comments_transition_count ? 'YES' : 'NO'}
			</b>
			&nbsp;&nbsp;&nbsp;&nbsp;
			
			<Badge color="yellow" />
			Gold: &nbsp;
			<b style={{width: 30, display: 'inline-block'}}>
				{record.comments_gold_count}
			</b>
			&nbsp;&nbsp;&nbsp;&nbsp;

			<Badge color="gray" />
			Silver: &nbsp;
			<b style={{width: 30, display: 'inline-block'}}>
				{record.comments_silver_count}
			</b>
			&nbsp;&nbsp;&nbsp;&nbsp;

			<Badge color="brown" />
			Bronze: &nbsp;
			<b style={{width: 30, display: 'inline-block'}}>
				{record.comments_bronze_count}
			</b>
		</>
	  },
    },
  ];
  const [columns, setColumns] = useState(defaultColumns);

  const { data: configData, isLoading: isConfigLoading, refetch: refecthConfig } = useConfig()
  useEffect(() => {

	let cols = [ ...defaultColumns ];
	configData?.langs.map( (lang) => {
		if( !lang.showInAdmin ) {
			return <></>;
		}
		cols.push({
			title: lang.locale.toUpperCase(),
			key: lang.locale,
      		width: '20%',
			render: (text: string, item: any) => {
				const translation = item?.translations.find( (e:PlaceTranslation) => e.locale === lang.locale );
				const seoTranslation = item?.seo?.translations.find( (e:PlaceTranslation) => e.locale === lang.locale );
				const ts = translation?.translation_status ?? 'untranslated';
				const s = translation?.status ?? 'draft';
				let badge = <QuestionCircleOutlined style={{ color: 'gray' }} />;
				if( ts === 'ready' ) {
					badge = <HourglassOutlined style={{ color: 'gold' }} />;
				} else if( ts === 'translated' ) {
					badge = <CheckCircleOutlined style={{ color: 'blue' }} />;
				} else if( ts === 'live' ) {
					badge = <CheckCircleOutlined style={{ color: 'green' }} />;
				} else if( ts === 'changes' ) {
					badge = <HourglassOutlined style={{ color: 'orange' }} />;
				}
				const badgeTitle = configData.translationStatuses.find( e => e.key === ts )?.name;

				let visibilityTooltipText = 'Hidden';
				let visibilityIcon = <EyeInvisibleOutlined style={{ color: 'gray' }} />;
				if( s!=='draft' ) {
					visibilityTooltipText = seoTranslation?.index === 'index' ? 'Visible' : 'Visible, but no-index';
					visibilityIcon = seoTranslation?.index === 'index' ? <EyeOutlined style={{ color: 'green' }} /> : <EyeOutlined style={{ color: 'orange' }} />;
				}

				return <>
					<Tooltip title={badgeTitle} >
						{badge}
					</Tooltip>
					<br/>
					<Tooltip title={visibilityTooltipText} >
						{visibilityIcon}
					</Tooltip>
				</>
			},
		})
	} )

	setColumns(cols);

  }, [configData])

  return (
    <>
      <div className="table-header">
        <Input.Search
          style={{ width: '40%' }}
          onPressEnter={() => refetch()}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search for a place"
        />
        <div>
          <AuthorizedButton hasPermission={hasPermissionToCreate} onClick={() => navigate(`${pathname}/country/new`)}>
            Add New Country
          </AuthorizedButton>

          <AuthorizedButton
            hasPermission={hasPermissionToCreate}
            onClick={() => navigate(`${pathname}/city/new`)}
            style={{ marginLeft: 16 }}
            type="primary"
          >
            Add New City
          </AuthorizedButton>
        </div>
      </div>
      <Table
        rowKey="guid"
        loading={isLoading}
        columns={columns}
        dataSource={tableData}
        onChange={(pagination) =>
          setTablePaginationOption({ ...tablePaginationOption, curPage: pagination.current ?? 0 })
        }
        pagination={{
          defaultPageSize: tablePaginationOption.pageSize,
          showSizeChanger: false,
          total: tablePaginationOption.total,
        }}
      />
    </>
  )
}
