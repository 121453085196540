import { InfoCircleOutlined, MailOutlined } from '@ant-design/icons'
import { Button, Card, Col, Form, Input, Row, Select, Tag, TagProps, Tooltip, Typography, notification } from 'antd'
import { chain, first, head, map, startCase } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { UpdateAdminForm } from '../../../api/api-admin'
import config from '../../../api/config'
import AuthorizedButton from '../../../components/Auth0/AuthorizedButton'
import FullPageLoading from '../../../components/Auth0/FullPageLoading'
import { Auth0RoleMap, Auth0Roles } from '../../../constants/auth0'
import { permissionsStore } from '../../../data/permissions'
import { useDeleteAdmin } from '../../../hooks/admin/mutations/useDeleteAdmin'
import { useResetAdminPassword } from '../../../hooks/admin/mutations/useResetPassword'
import { useUpdateOrCreateAdmin } from '../../../hooks/admin/mutations/useUpdateOrCreateAdmin'
import { useAdminById } from '../../../hooks/admin/queries/useAdminById'
import { useTokenClaims } from '../../../hooks/useTokenClaims'
import { generateStrongPassword } from '../../../utils/auth/generateStrongPassword'
import { FaRegClipboard } from 'react-icons/fa'
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard'
import { transformations } from '../../../imagekit/helpers'
import { DeleteFilled, EditFilled } from '@ant-design/icons'
import { imageService } from '../../../api/api-image'
import { ImageModel } from '../../../types/image-api'
import { AxiosError } from 'axios'
import { CDNFolders } from '../../../imagekit/helpers'
import GalleryDialog from '../../../components/GalleryDialog/GalleryDialog'

type ScopeMapKey = Exclude<TagProps['color'], undefined>

const scopeMap: Partial<Record<string, ScopeMapKey>> = {
  create: 'blue',
  read: 'green',
  update: 'orange',
  delete: 'pink',
}

const temporaryPassword = generateStrongPassword()

const AdminForm: React.FC = () => {
  const params = useParams()
  const adminId = params.adminId || '0'
  const isCreate = adminId === '0'
  const [form] = Form.useForm<UpdateAdminForm>()
  const { data: adminDetail, isLoading: isAdminDetailLoading, refetch } = useAdminById(adminId)
  const { claims } = useTokenClaims()
  const { copyToClipboard } = useCopyToClipboard()
  
  useEffect( () => {
	if( adminDetail ) {
		const formData = { ...adminDetail }
		formData.position = formData.admin.position
		form.setFieldsValue(formData)

		setThumbnailId(formData.admin?.thumbnail_photo_id)
		setThumbnail(formData.admin?.thumbnail)
	}
  }, [adminDetail] )

  const auth0DomainId = first(config.auth0.domain?.split('.'))
  const groupedScopes = chain(adminDetail?.permissions)
    .groupBy((scope) => scope.permission_name.split(':')[1])
    .value()

  const [hasPermissionToUpdate, hasPermissionToCreate, hasPermissionToDelete] = permissionsStore
    .getState()
    .hasPermissions(['update:admin', 'create:admin', 'delete:admin'])

  const hasPermissionToUpdateOrCreate = hasPermissionToUpdate || hasPermissionToCreate
  const hasPermissionToResetPassword =
    claims?.email === adminDetail?.email || !!claims?.roles?.includes(Auth0Roles.OWNER)

  const updateOrCreateAdminMutation = useUpdateOrCreateAdmin(adminDetail, refetch)
  const resetAdminPasswordMutation = useResetAdminPassword(adminDetail)
  const deleteAdminMutation = useDeleteAdmin(adminId, refetch)
  const roleOptions = Object.values(Auth0Roles).map((role) => ({ label: role, value: Auth0RoleMap[role] }))


  	const [thumbnailId, setThumbnailId] = useState<string|null>(null)
  	const [thumbnail, setThumbnail] = useState<ImageModel|null>(null)
	const [isGalleryOpen, setIsGalleryOpen] = useState(false)
	const onChooseImage = () => {
		setIsGalleryOpen(true)
	}
	const onSelectImage = async (photoIds: string[]) => {
		const id = first(photoIds) ?? ''
		
		let photo;
		try {
			const apiReposne = await imageService.getImageDetail(id)
			console.log('apiReposne', apiReposne);
			photo = apiReposne.data as ImageModel;
		} catch( e:unknown ) {
			let error = e instanceof AxiosError && e?.response?.status === 404 ? 'Image not found in the Database. Maybe it was uploaded from a dev version of the website. Please delete it from ImageKit and reupload it.' : 'Unknown error from ImageKit';
			
			notification.open({
				type: 'error',
				message: 'Something went wrong',
				description: error,
				placement: 'bottomRight',
			})
			return;
		}

		setThumbnailId(photo.id)
		setThumbnail(photo)

		setIsGalleryOpen(false)
	}

	const onDeleteImage = () => {
		setThumbnailId(null)
		setThumbnail(null)
	}


  if (isAdminDetailLoading && !isCreate) {
    return <FullPageLoading fixed={false} />
  }

  return (
    <Card
      title={
        !isCreate && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{adminDetail?.name} —</span>
            <Col style={{ marginLeft: 8 }}>
              <Row gutter={[16, 16]}>
                {adminDetail?.roles.map((role) => (
                  <Col key={role.name}>
                    <Tooltip overlay={role.description}>
                      <Tag>{role.name}</Tag>
                    </Tooltip>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col>
              <img
                src={adminDetail?.picture}
                alt=""
                width={32}
                height={32}
                style={{ borderRadius: '50%', marginLeft: 8 }}
              />
            </Col>
          </div>
        )
      }
      extra={
        <div style={{ display: 'flex' }}>
          {!isCreate && (
            <AuthorizedButton
              hasPermission={hasPermissionToDelete}
              onClick={() => deleteAdminMutation.mutate()}
              danger
              type="primary"
              loading={deleteAdminMutation.isLoading}
            >
              Delete User
            </AuthorizedButton>
          )}
          <AuthorizedButton
            style={{ marginLeft: 16 }}
            type="primary"
            loading={updateOrCreateAdminMutation.isLoading}
            onClick={() => form.submit()}
            hasPermission={hasPermissionToUpdateOrCreate}
          >
            Save
          </AuthorizedButton>
        </div>
      }
    >
      <Row>
        <Col span={12}>
          <Row>
            <Typography.Title level={4}>Details</Typography.Title>
          </Row>
          <Row>
            <Col span={16}>
              <Form
                form={form}
                style={{ marginTop: 20 }}
                onFinish={(formValues) => {
					const payload = { 
						...form.getFieldsValue(), 
						thumbnail, 
						thumbnail_photo_id: thumbnailId,
						adminId: isCreate ? '0' : adminId
					};
					console.log( 'payload', payload);
					hasPermissionToUpdateOrCreate &&
					updateOrCreateAdminMutation.mutate(payload)
				}
                }
                disabled={!hasPermissionToUpdateOrCreate}
              >
                Admin Name:
                <Form.Item name="name">
                  <Input />
                </Form.Item>
                Email:
                <Form.Item name="email">
                  <Input />
                </Form.Item>
                Temporary Password
                <Form.Item name="password" initialValue={temporaryPassword}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Input disabled defaultValue={temporaryPassword} value={temporaryPassword} />
                    <FaRegClipboard
                      style={{ marginLeft: 16, cursor: 'pointer' }}
                      onClick={() => copyToClipboard(temporaryPassword)}
                    />
                  </div>
                </Form.Item>
                Role:
                <Form.Item name="role">
                  <Select options={roleOptions} />
                </Form.Item>
              	{!isCreate && (
					<>
						Position:
						<Form.Item name="position">
						<Input />
						</Form.Item>
						<Form.Item>
							Preview:<br/>
							<div className="imagePreview">
							<>
								{ thumbnailId ? (<>
									<img
									alt="Local guide"
									src={
										thumbnail?.url
										? `${thumbnail?.url}?${transformations.small_thumbnail}`
										: ''
									}
									/>
								</>) : (
									<div className="dummy">
										No photo yet
									</div>
								) }
								<div className="actionBar">
									<span>
										<EditFilled
										disabled={!hasPermissionToUpdate}
										onClick={() => hasPermissionToUpdate && onChooseImage()}
										style={{ color: '#fff' }}
										/>
									</span>
									<span>
										<DeleteFilled
										disabled={!hasPermissionToDelete}
										style={{ color: '#fff' }}
										onClick={() => hasPermissionToDelete && onDeleteImage()}
										/>
									</span>
								</div>
							</>
							</div>
						</Form.Item>
						{ isGalleryOpen && (
							<GalleryDialog
								predefinedTags={['local-guides']}
								fixedFolder={CDNFolders.LOCAL_GUIDES}
								cdnFolder={CDNFolders.LOCAL_GUIDES}
								open={true}
								onSelect={onSelectImage}
								onClose={() => setIsGalleryOpen(false)}
								requestParams={{ searchQuery: `tags IN["local-guides"]` }}
							/>
						) }
					</>
					
				)}
              </Form>
              {!isCreate && (
                <Col>
                  Send Reset Password Email:
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <AuthorizedButton
                      hasPermission={hasPermissionToResetPassword}
                      icon={<MailOutlined />}
                      loading={resetAdminPasswordMutation.isLoading}
                      onClick={() => resetAdminPasswordMutation.mutate()}
                    >
                      Send
                    </AuthorizedButton>
                  </div>
                </Col>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <Typography.Title level={4}>Permissions</Typography.Title>
              <Tooltip title="These are the authorized scopes configured in Auth0 for actions users can make on API resources">
                <InfoCircleOutlined style={{ marginLeft: 8 }} />
              </Tooltip>
              {hasPermissionToUpdate && (
                <Button
                  style={{ marginLeft: 24 }}
                  size="small"
                  target="_blank"
                  href={`https://manage.auth0.com/dashboard/us/${auth0DomainId}/users`}
                >
                  Edit
                </Button>
              )}
            </div>
          </Row>
          <Row>
            {map(groupedScopes, (scopes, key) => (
              <Col span={24} key={key} style={{ borderBottom: '1px solid #efefef', marginBottom: 16 }}>
                <Typography.Title style={{ color: '#888' }} level={5}>
                  {startCase(key)}
                </Typography.Title>
                <Row gutter={[16, 16]}>
                  {map(scopes, ({ permission_name }) => {
                    const action = head(permission_name.split(':'))
                    const color = action ? scopeMap[action] : undefined
                    return (
                      <Col key={permission_name} style={{ marginBottom: 16 }}>
                        <Tag color={color}>{permission_name}</Tag>
                      </Col>
                    )
                  })}
                </Row>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

export default AdminForm
