import { AxiosInstance } from 'axios'
import { apiClient } from './apiClient'

class ConfigService {
	public URI: string = '/config'
	constructor(private apiClient: AxiosInstance) {}

	public getConfig = () => {
	  return this.apiClient.get(`${this.URI}/`)
	}
}

export const configService = new ConfigService(apiClient)
