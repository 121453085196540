import { AxiosInstance } from 'axios'
import { Auth0Roles, Auth0Scope } from '../constants/auth0'
import { apiClient } from './apiClient'
import { ImageModel } from '../types/image-api'

export interface Permission {
  permission_name: Auth0Scope
  description: string
  resource_server_name: string
  resource_server_identifier: string
  sources: Source[]
}
export interface Source {
  source_id: string
  source_name: string
  source_type: string
}

export interface Role {
  id: string
  name: Auth0Roles
  description: string
}

export type AdminWithRolesAndPermissions = Admin & { 
	roles: Role[]; 
	permissions: Permission[] 
}

export interface AdminDatabase {
	id: number
	name: string
	email: string
	role: string,
	google2fa_enabled: string
	created_at:string
	updated_at: string
	thumbnail_photo_id:string|null,
	position: string
	thumbnail:ImageModel|null
}

export interface Admin {
  app_metadata: any
  created_at: string
  email: string
  email_verified: boolean
  identities: Identity[]
  name: string
  nickname: string
  picture: string
  updated_at: string
  user_id: string
  last_login: string
  last_ip: string
  logins_count: number
  thumbnail_photo_id: string|null
  thumbnail: ImageModel|null
  admin: AdminDatabase
  
  position?: string
}

export interface Identity {
  connection: string
  provider: string
  user_id: string
  isSocial: boolean
}

export type UpdateAdminForm = { 
	name: string; 
	email: string; 
	role: string 
	position: string
	thumbnail_photo_id: string|null
  	thumbnail: ImageModel|null
}

type Auth0DbConnection = 'zielonamapa-website' | 'zielonamapa-admin-panel'

class AdminService {
  public URI: string = '/admin'
  constructor(private apiClient: AxiosInstance) {}

  public deleteAdmin = (adminId: string) => {
    return this.apiClient.delete(`${this.URI}/${adminId}`)
  }

  public getAdminDetail = (adminId: string) => {
    return this.apiClient.get<Admin>(`${this.URI}/${adminId}`)
  }

  public updateAdminDetail = (adminId?: string, data?: UpdateAdminForm) => {
    /** update role uses a different endpoint */
    const { role, ...rest } = data || {}
    return this.apiClient.put(`${this.URI}/${adminId}`, rest)
  }

  public createNewAdmin = (data: UpdateAdminForm) => {
    return this.apiClient.post(`${this.URI}/register`, data)
  }

  public updateAdminRole = (id?: string, payload?: { role: string; existingRoles: string[] }) => {
    return this.apiClient.post(`${this.URI}/update_role/${id}`, payload)
  }

  public resetPasswordEmail = (email: string, connection: Auth0DbConnection) => {
    return this.apiClient.post(`${this.URI}/reset_password_email/`, { email, connection })
  }

  public getAdminPermissions = (id: string) => {
    return this.apiClient.get<Permission[]>(`${this.URI}/permissions/${id}`)
  }

  public getAdminRoles = (id: string) => {
    return this.apiClient.get<Role[]>(`${this.URI}/roles/${id}`)
  }

  public getAllAdmins = () => {
    return this.apiClient.get<Admin[]>(`${this.URI}/`)
  }

  public sendPasswordResetLink = (email: string) => {
    return this.apiClient.post(`${this.URI}/users/send_reset_password`, { email })
  }
}

export const adminService = new AdminService(apiClient)
