const defaultSort: Sort = {
  label: 'Sort',
  direction: 'ASC',
  values: [
    { label: 'Name', value: 'NAME' },
    { label: 'Created at', value: 'CREATED' },
    { label: 'Updated at', value: 'UPDATED' },
  ],
}

export const defaultTags: Tags = {
  label: 'Tags',
  values: [
    { label: 'Hero image', value: 'hero' },
    { label: 'Nature', value: 'natura' },
    { label: 'Beach', value: 'plaze' },
    { label: 'Off the track', value: 'poza-szlakiem' },
    { label: 'Sights', value: 'zwiedzanie' },
    { label: 'Nightlife', value: 'zycie-nocne' },
    { label: 'Activities', value: 'aktywnie' },
    { label: 'Local Guides', value: 'local-guides' },
  ],
}

const defaultLocation: Location = {
  label: 'Place',
  values: [
    { label: 'Europa', value: 'europa' },
    { label: 'Ameryka Południowa', value: 'ameryka-poludniowa' },
    { label: 'Ameryka Północna', value: 'ameryka-polnocna' },
    { label: 'Afryka', value: 'afryka' },
    { label: 'Azja', value: 'azja' },
  ],
  countries: [],
}

type Filter = {
  label: string
  values: { label: string; value: string }[]
}

interface Tags extends Filter {
  label: 'Tags'
}
interface Sort extends Filter {
  label: 'Sort'
  direction: 'ASC' | 'DESC'
}
interface Location extends Filter {
  label: 'Place'
  countries: string[]
}

export type Filters = {
  tags: Tags
  sort: Sort
  location: Location
}

export const defaultFilters: Filters = {
  tags: defaultTags,
  sort: defaultSort,
  location: defaultLocation,
}
