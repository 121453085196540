import MailOutlined from '@ant-design/icons/MailOutlined'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Badge, Card, Input, notification, Space, Table, Tabs } from 'antd'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { adminService } from '../../api/api-admin'
import { userService } from '../../api/api-user'
import AuthorizedButton from '../../components/Auth0/AuthorizedButton'
import { CustomLink } from '../../components/CustomLink'
import { permissionsStore } from '../../data/permissions'
import moment from 'moment'

type ActionCellProps = {
  item: { id: string; email: string; status: string }
  onChange: () => void
}

const ActionCell: React.FC<ActionCellProps> = ({ item, onChange }) => {
  const hasPermissionToUpdate = permissionsStore.getState().hasPermission('update:user')

  const { mutate: sendPasswordResetLinkMutation, isLoading: isResetPasswordLoading } = useMutation(
    ['sendPasswordReset', item.email, onChange],
    async () => await adminService.resetPasswordEmail(item.email, 'zielonamapa-website'),
    {
      onSuccess: (res) => {
        onChange()
        notification.open({
          type: 'success',
          message: 'Message',
          description: `The link to reset password is sent to ${item.email}.`,
        })
      },
    },
  )

  const { mutate: verifyUserMutation, isLoading: isVerifyUserLoading } = useMutation(
    ['verifyUser', item.id],
    async () => await userService.verifyUser({ id: item.id }),
    {
      onSuccess: (res) => {
        notification.open({
          type: 'success',
          message: 'Message',
          description: `${item.email} is verified.`,
        })
        onChange()
      },
    },
  )

  return (
    <Space size="middle">
      <AuthorizedButton
        hasPermission={hasPermissionToUpdate}
        loading={isResetPasswordLoading}
        icon={<MailOutlined />}
        onClick={() => sendPasswordResetLinkMutation()}
      >
        Send Reset Password Email
      </AuthorizedButton>
      {item.status !== 'verified' && (
        <AuthorizedButton
          hasPermission={hasPermissionToUpdate}
          loading={isVerifyUserLoading}
          onClick={() => verifyUserMutation()}
        >
          Verify Email
        </AuthorizedButton>
      )}
    </Space>
  )
}

const AllUsers = () => {
  const { pathname } = useLocation()
  const [currentTab, setCurrentTab] = useState('all')
  const [currentQuery, setCurrentQuery] = useState('')
  const navigate = useNavigate()
  const hasPermissionToCreate = permissionsStore.getState().hasPermission('create:user')

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'user_name',
      render: (text: string, item: any) => <CustomLink to={`${pathname}/${item.id}`}>{text}</CustomLink>,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Visited',
      key: 'visited',
      render: (_: any, record: any) => <div>{record.visited.length}</div>,
    },
    {
      title: 'Wishlist',
      key: 'wishlist',
      render: (_: any, record: any) => <div>{record.wishlist.length}</div>,
    },
    {
      title: 'Reviews',
      key: 'reviews',
      render: (_: any, record: any) => <div>{record.comments.length}</div>,
    },
    {
      title: 'Email Verified',
      key: 'email_verified_at',
      render: (_: any, record: any) => {
        let badgeType: any = 'default'
        let badgeTxt = record.status
        if (badgeTxt === 'verified') {
          badgeType = 'success'
        } else {
          badgeType = 'processing'
          badgeTxt = 'pending'
        }
        return <Badge status={badgeType} text={badgeTxt} />
      },
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'user_email',
    },
    {
      title: 'Premium',
      key: 'premium',
      render: (_: any, record: any) => {
        let badgeType: any = 'default'
        let badgeTxt;
        if (record.premium) {
			badgeType = 'success'
			badgeTxt = 'until '+moment(record.premium_expires_at).format('d.m.Y')
        } else {
			badgeType = 'default'
			badgeTxt = 'NO'
        }
        return <Badge status={badgeType} text={badgeTxt} />
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: '20%',
      render: (text: string, record: any) => <ActionCell item={record} onChange={() => refetch()} />,
    },
  ]

  const [tablePaginationOption, setTablePaginationOption] = useState<{
    total: number
    curPage: number
    pageSize: number
  }>({ total: 0, curPage: 1, pageSize: 10 })

  const {
    data: tableData,
    isLoading,
    refetch,
  } = useQuery(['allUsers', currentQuery, currentTab, tablePaginationOption], async () => {
    let query = currentQuery
    const { pageSize, curPage } = tablePaginationOption
    const { query: tabQuery } = tabs.find((t) => t.id === currentTab) || {}
    query += '&' + tabQuery

    const { data } = await userService.getAll(pageSize * (curPage - 1), pageSize, query)
    setTablePaginationOption({ curPage, pageSize, total: data.total })
    return data?.data
  })

  const tabs = [
    { id: 'all', title: 'All', query: '' },
    { id: 'fake', title: 'Fake', query: 'type=fake' },
    { id: 'real', title: 'Real', query: 'type=real' },
  ]

  return (
    <Card
      title="Users"
      extra={
        <div style={{ display: 'flex' }}>
          <Input.Search
            onPressEnter={(e) => setCurrentQuery(e.currentTarget.value)}
            onSearch={(query) => setCurrentQuery(query)}
            placeholder="Search for a user"
          />
          <AuthorizedButton
            style={{ marginLeft: 16 }}
            type="primary"
            hasPermission={hasPermissionToCreate}
            onClick={() => navigate(`${pathname}/create`)}
          >
            New
          </AuthorizedButton>
        </div>
      }
    >
      <Tabs style={{ marginTop: -24 }} activeKey={currentTab} onChange={(tab) => setCurrentTab(tab)}>
        {tabs.map((tab) => (
          <Tabs.TabPane tab={tab.title} key={tab.id} />
        ))}
      </Tabs>
      <Table
        loading={isLoading}
        rowKey="id"
        columns={columns}
        dataSource={tableData ?? []}
        onChange={(pagination) =>
          setTablePaginationOption({ ...tablePaginationOption, curPage: pagination.current ?? 0 })
        }
        pagination={{
          defaultPageSize: tablePaginationOption.pageSize,
          showSizeChanger: false,
          total: tablePaginationOption.total,
        }}
      />
    </Card>
  )
}

export default AllUsers
