import { Button, Col, Form, Image, Input, notification, Row, Space, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { imageService } from '../../api/api-image'
import { Modal } from '../../components/Modal'
import { permissionsStore } from '../../data/permissions'
import { useImageDetail } from '../../hooks/useImageDetail'
import { transformations } from '../../imagekit/helpers'
import { GetAllPhotosResponse } from '../../types/image-api'
import { defaultTags } from './filter'
import { centered } from './PhotoGallery'
import { defaultFilterState, FilterState } from './Photos'
import Tags from './Tags'

interface Props {
  photo: GetAllPhotosResponse | null
  onClose?: (values?: any) => void
  onDelete?: () => void
}

export function handleMessage(action: 'delete' | 'update', onSuccessCb?: () => void) {
  return {
    onSuccess: (res: any) => {
      notification.open({
        type: 'success',
        message: 'Success',
        description: `Image ${action}d successfully`,
        placement: 'bottomRight',
      })
      onSuccessCb && onSuccessCb()
    },
    onError: (err: any) =>
      notification.open({
        type: 'error',
        message: 'Something went wrong',
        description: `An error occurred trying to ${action} photo - ${JSON.stringify(err)}`,
        placement: 'bottomRight',
      }),
  }
}

const PhotoForm: React.FC<Props> = ({ photo, onClose, onDelete }) => {
  const [form] = Form.useForm()
  const [filters, setFilters] = useState<FilterState['filters']>(defaultFilterState)
  const [hasPermissionToUpdate] = permissionsStore.getState().hasPermissions(['update:image'])

  const { image, error, isLoading, refetch } = useImageDetail(photo?.fileId)

  const {
    mutate: updateSelectedPhoto,
    isLoading: isUpdateLoading,
    isSuccess: isSuccessUpdate,
  } = useMutation(
    () => {
      const { alt, description } = form.getFieldsValue() ?? {}
      return imageService.updateImageDetail(photo?.fileId ?? '', {
        alt,
        description,
        tags: JSON.stringify(filters.tags),
      })
    },
    handleMessage('update', () => refetch()),
  )

  useEffect(() => {
    if (image) {
      form.setFieldsValue({
        alt: image.alt,
        description: image.description,
        url: image.url,
      })
      try {
        const tags = typeof image.tags === 'string' ? JSON.parse(image?.tags) : image.tags
        setFilters({ ...filters, tags })
      } catch (e) {
        console.error('failed to parse tags', e)
      }
    }
  }, [image])

  function Loading() {
    return (
      <div style={{ ...centered, minHeight: '35vh' }}>
        <Spin />
      </div>
    )
  }

  return (
    <Modal title="Image detail" centered width="90%" visible={!!photo} footer={null} onCancel={onClose}>
      <Form form={form} style={{ paddingLeft: 16, paddingRight: 16 }}>
        <Row gutter={[32, 8]}>
          <Col span={8}>
            {isLoading ? (
              <Loading />
            ) : (
              <Image preview={{ src: image?.url }} src={image ? `${image.url}?${transformations.edit_modal}` : ''} />
            )}
          </Col>
          {isLoading ? (
            <Col span={16}>
              <Loading />
            </Col>
          ) : (
            <Col span={16}>
              Alt:
              <Form.Item name="alt">
                <Input />
              </Form.Item>
              Description:
              <Form.Item name="description">
                <Input />
              </Form.Item>
              Tags:
              <Form.Item name="tags">
                <Tags values={defaultTags.values} label="Tags" filters={filters} setFilters={setFilters} />
              </Form.Item>
              URL:
              <Form.Item name="url">
                <Input
                  disabled
                  suffix={
                    <Button
                      type="primary"
                      onClick={() => {
                        navigator.clipboard.writeText(form.getFieldValue('url'))
                        notification.open({
                          type: 'info',
                          message: '',
                          description: 'Copied!',
                        })
                      }}
                    >
                      Copy
                    </Button>
                  }
                  style={{ paddingTop: 0, paddingRight: 0, paddingBottom: 0 }}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <br />
        <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Button type="default" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 16 }}
              disabled={!hasPermissionToUpdate}
              onClick={() => hasPermissionToUpdate && updateSelectedPhoto()}
              loading={isUpdateLoading}
            >
              Save
            </Button>
          </div>
        </Space>
      </Form>
    </Modal>
  )
}

export default PhotoForm
