export const DEFAULT_CATEGORIES = {
  activities: {
    label: 'Activities',
    category_page: {},
    category: {
      id: 6,
    },
    child_places: [],
  },
  nightlife: {
    label: 'Nightlife',
    category: {
      id: 2,
    },
    category_page: {},
    child_places: [],
  },
  sights: {
    label: 'Sights',
    category: {
      id: 3,
    },
    category_page: {},
    child_places: [],
  },
  nature: {
    label: 'Nature',
    category: {
      id: 4,
    },
    category_page: {},
    child_places: [],
  },
  offthepath: {
    label: 'Off The Path',
    category: {
      id: 7,
    },
    category_page: {},
    child_places: [],
  },
  beach: {
    label: 'Beach',
    category: {
      id: 5,
    },
    category_page: {},
    child_places: [],
  },
}
