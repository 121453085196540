import { Button, Col, Form, Input, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { permissionsStore } from '../../../data/permissions'

interface Props {
  data: any
  onDelete?: () => void
  onSave?: (values: any) => void
}

const ExternalLinkSection: React.FC<Props> = ({ data, onDelete, onSave }) => {
  const [form] = Form.useForm()
  const [editing, setEditing] = useState(false)
  const [formData, setFormData] = useState<any>()
  const [hasPermissionToUpdate, hasPermissionToDelete] = permissionsStore
    .getState()
    .hasPermissions(['update:place', 'delete:place'])

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [formData])

  useEffect(() => {
    // form.setFieldsValue(data);
    setFormData(data)
  }, [data])

  const onSaveForm = () => {
    const values = form.getFieldsValue()
    values.id = data.id
    if (onSave) onSave(values)
  }

  const renderActionButton = () => {
    if (data.id > 0) {
      if (editing || data.editing) {
        return (
          <>
            <Button disabled={!hasPermissionToDelete} type="default" onClick={onDelete}>
              Delete Row
            </Button>
          </>
        )
      } else {
        return (
          <Button disabled={!hasPermissionToDelete} type="default" onClick={onDelete}>
            Delete Row
          </Button>
        )
      }
    } else {
      return (
        <>
          <Button disabled={!hasPermissionToUpdate} type="primary" style={{ marginRight: 20 }} onClick={onSaveForm}>
            Create
          </Button>
          <Button disabled={!hasPermissionToDelete} type="default" onClick={onDelete}>
            Cancel
          </Button>
        </>
      )
    }
  }

  return (
    <div className="content">
      <Form form={form} onChange={() => setEditing(true)}>
        <Row gutter={8}>
          <Col span={12}>
            Title:
            <Form.Item name="title">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            Url:
            <Form.Item name="url">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>{renderActionButton()}</Form.Item>
      </Form>
    </div>
  )
}

export default ExternalLinkSection
