import { PlaceDetail } from '../../../../types/place-api'

export type CategoryKey = 'when_to_go' | 'activities' | 'nightlife' | 'sights' | 'nature' | 'offthepath' | 'beach' | 'what_to_bring' | 'what_to_eat' | 'hire_car' | 'video'

type CityCategoryData = {
  label: string
  content: string
  title: string
  premium: boolean
}

export type AugmentedCityCategoryData = CityCategoryData & { id: number }

const defaultValues = { title: '', content: '', premium: false }
export const cityCategoryContents: Record<CategoryKey, CityCategoryData> = {
  when_to_go: { label: 'When to go', ...defaultValues },
  activities: { label: 'Activities', ...defaultValues },
  nightlife: { label: 'Nightlife', ...defaultValues },
  sights: { label: 'Sights', ...defaultValues },
  nature: { label: 'Nature', ...defaultValues },
  offthepath: { label: 'Off The Path', ...defaultValues },
  beach: { label: 'Beaches', ...defaultValues },

  what_to_bring: { label: 'What to bring', ...defaultValues },
  what_to_eat: { label: 'What to eat', ...defaultValues },
  hire_car: { label: 'Hire a car', ...defaultValues },
  video: { label: 'Partner miejsca poleca 🔥', ...defaultValues },
}

export const buildCityCategoryData = (placeDetail?: PlaceDetail): Record<CategoryKey, AugmentedCityCategoryData> => {
  return Object.entries(cityCategoryContents).reduce((acc, [key, value]) => {
    const element = placeDetail?.city_contents?.find((item) => item.content_key === key) as
      | AugmentedCityCategoryData
      | undefined
    const { title, id, content, premium } = element || {}
    return { ...acc, [key]: { ...value, content, title, id, premium } }
  }, {} as Record<CategoryKey, AugmentedCityCategoryData>)
}
