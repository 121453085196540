import { AxiosInstance } from 'axios'
import { apiClient } from './apiClient'

class AuthService {
  public URI: string = '/auth'
  constructor(private apiClient: AxiosInstance) {}

  public verify2FA = (token: string) => {
    return this.apiClient.post(`${this.URI}/verify_2fa`, { token })
  }

  public create2FA = (payload: { token: string; secret: string }) => {
    return this.apiClient.post(`${this.URI}/create_2fa`, payload)
  }

  public reset2FA = (id: string) => {
    return this.apiClient.get(`${this.URI}/reset_2fa/${id}`)
  }

  /** returns the QR code svg from the server */
  public generateQRCode = () => {
    return this.apiClient.get<{ qr_code: string; secret: string }>(`${this.URI}/generate_qr_code`)
  }
}

export const authService = new AuthService(apiClient)
