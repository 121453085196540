import { LockOutlined, MailOutlined } from '@ant-design/icons'
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined'
import { Card, Select, Space, Table } from 'antd'
import { get, head } from 'lodash'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AdminWithRolesAndPermissions } from '../../api/api-admin'
import AuthorizedButton from '../../components/Auth0/AuthorizedButton'
import PermissionsTooltip from '../../components/Auth0/PermissionsTooltip'
import { CustomLink } from '../../components/CustomLink'
import { Auth0RoleMap, Auth0Roles, AUTH0_NAMESPACE } from '../../constants/auth0'
import { permissionsStore } from '../../data/permissions'
import { useDeleteAdmin } from '../../hooks/admin/mutations/useDeleteAdmin'
import { useResetAdminPassword } from '../../hooks/admin/mutations/useResetPassword'
import useUpdateAdminRole from '../../hooks/admin/mutations/useUpdateAdminRole'
import { useAdminsWithRolesAndPermissions } from '../../hooks/admin/queries/useAdminsWithRolesAndPermissions'
import { useReset2fa } from '../../hooks/auth/mutations/useReset2fa'
import { useTokenClaims } from '../../hooks/useTokenClaims'

const ActionCell: React.FC<{ admin: AdminWithRolesAndPermissions; onChange: () => void }> = ({ admin, onChange }) => {
  const { claims } = useTokenClaims()
  const { roles } = get(claims, AUTH0_NAMESPACE) || {}
  const [hasPermissionToDelete] = permissionsStore.getState().hasPermissions(['delete:admin', 'update:auth'])
  const hasPermissionToResetPassword = claims?.email === admin.email || !!roles?.includes(Auth0Roles.OWNER)
  const useDeleteAdminMutation = useDeleteAdmin(admin.user_id, onChange)
  const useResetAdminPasswordMutation = useResetAdminPassword(admin)
  const useReset2faMutation = useReset2fa(admin.user_id)

  return (
    <Space size="middle">
      <AuthorizedButton
        hasPermission={hasPermissionToResetPassword}
        loading={useResetAdminPasswordMutation.isLoading}
        onClick={() => useResetAdminPasswordMutation.mutate()}
        icon={<MailOutlined />}
      >
        Reset Password
      </AuthorizedButton>
      <AuthorizedButton
        hasPermission={hasPermissionToResetPassword}
        loading={useReset2faMutation.isLoading}
        onClick={() => useReset2faMutation.mutate()}
        icon={<LockOutlined />}
      >
        Reset 2FA
      </AuthorizedButton>
      <AuthorizedButton
        hasPermission={hasPermissionToDelete}
        loading={useDeleteAdminMutation.isLoading}
        icon={<CloseCircleOutlined />}
        onClick={() => useDeleteAdminMutation.mutate()}
      />
    </Space>
  )
}

const RoleCell: React.FC<{ admin: AdminWithRolesAndPermissions; onChange: () => void }> = ({ admin, onChange }) => {
  const hasPermissionToUpdate = permissionsStore.getState().hasPermission('update:admin')
  const role = head(admin.roles)
  const useUpdateAdminRoleMutation = useUpdateAdminRole(admin, onChange)

  return (
    <PermissionsTooltip hasPermission={hasPermissionToUpdate}>
      <Select
        loading={useUpdateAdminRoleMutation.isLoading}
        disabled={!hasPermissionToUpdate}
        style={{ width: '150px' }}
        value={role?.name}
        onChange={(value) => {
          if (hasPermissionToUpdate) {
            useUpdateAdminRoleMutation.mutate(value)
          }
        }}
      >
        {Object.values(Auth0Roles).map((role) => (
          <Select.Option key={role} value={Auth0RoleMap[role]}>
            {role}
          </Select.Option>
        ))}
      </Select>
    </PermissionsTooltip>
  )
}

const AllAdmins = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { data: allAdmins, isLoading, refetch } = useAdminsWithRolesAndPermissions()

  const [tablePaginationOption, setTablePaginationOption] = useState<{
    total: number
    curPage: number
    pageSize: number
  }>({ total: 0, curPage: 1, pageSize: 10 })

  const [hasPermissionToCreate] = permissionsStore.getState().hasPermissions(['create:admin'])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, admin: AdminWithRolesAndPermissions) => (
        <CustomLink to={`${pathname}/${admin.user_id}`}>{text}</CustomLink>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'ID',
      dataIndex: 'user_id',
      key: 'user_id',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text: string, admin: AdminWithRolesAndPermissions) => <RoleCell admin={admin} onChange={refetch} />,
    },
    {
      title: 'Action',
      key: 'action',
      width: '20%',
      render: (text: string, admin: AdminWithRolesAndPermissions) => <ActionCell admin={admin} onChange={refetch} />,
    },
  ]

  return (
    <Card
      title="Admins"
      extra={
        <AuthorizedButton
          hasPermission={hasPermissionToCreate}
          type="primary"
          onClick={() => navigate(`${pathname}/0`)}
        >
          Add New Admin
        </AuthorizedButton>
      }
    >
      <Table
        rowKey="id"
        columns={columns}
        dataSource={allAdmins}
        onChange={(pagination) =>
          setTablePaginationOption({ ...tablePaginationOption, curPage: pagination.current ?? 0 })
        }
        loading={isLoading}
        pagination={{
          defaultPageSize: tablePaginationOption.pageSize,
          showSizeChanger: false,
          total: tablePaginationOption.total,
        }}
      />
    </Card>
  )
}

export default AllAdmins
