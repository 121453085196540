import { useQuery } from '@tanstack/react-query'
import { configService } from '../api/api-config'

export interface Lang {
  locale: string
  name: string
  nameLocal: string
  showInAdmin: boolean
}
export interface TranslationStatus {
	key: string
	name: string
	badgeStatus: "default" | "success" | "processing" | "error" | "warning" | undefined
}
export interface UserField {
	key: string
	value: string
}

export interface Config {
  langs: Lang[]
  translationStatuses: TranslationStatus[]
  userFields: Record<string, Record<string, string>>
}

export const useConfig = () => {

  const {
    data,
    isLoading,
    refetch,
  } = useQuery(['config'], async () => {
    const config = (await await configService.getConfig()).data as Config
    return config
	
  })

  return { data, isLoading, refetch }
}
