import { DeleteFilled, EditFilled } from '@ant-design/icons'
import { Button, Card, Form, Input, notification } from 'antd'
import React, { useImperativeHandle, useEffect, useState } from 'react'
import { PlaceDetail } from '../../../types/place-api'
import { useMutation } from '@tanstack/react-query'
import { mutationHandler } from '../../../utils/mutationHandler'
import { permissionsStore } from '../../../data/permissions'
import TextArea from 'antd/lib/input/TextArea'
import { imageService } from '../../../api/api-image'
import GalleryDialog from '../../../components/GalleryDialog/GalleryDialog'
import { first } from 'lodash'
import { placeService } from '../../../api/api-place'
import { transformations } from '../../../imagekit/helpers'
import { getLastSlug } from '../../../utils'
import { CDNFolders } from '../../../imagekit/helpers'
import { ImageModel } from '../../../types/image-api'
import { AxiosError } from 'axios'

interface Props {
	id: string
	placeDetail: PlaceDetail
}
const LocalGuideSection = React.forwardRef(({ id, placeDetail }: Props, ref) => {
    const [isDirty, setIsDirty] = useState<Boolean>(false)
    const [form] = Form.useForm<PlaceDetail | undefined>()
	const [localGuide, setLocalGuide] = useState(placeDetail ?? [])
	useEffect(() => {
		form.setFieldsValue(placeDetail);
		setLocalGuide(placeDetail ?? []);
	}, [placeDetail]);


	const [isGalleryOpen, setIsGalleryOpen] = useState(false)
	const onChooseImage = () => {
		setIsGalleryOpen(true)
	}
	const onSelectImage = async (photoIds: string[]) => {
		const id = first(photoIds) ?? ''
		
		let photo;
		try {
			const apiReposne = await imageService.getImageDetail(id)
			console.log('apiReposne', apiReposne);
			photo = apiReposne.data as ImageModel;
		} catch( e:unknown ) {
			let error = e instanceof AxiosError && e?.response?.status === 404 ? 'Image not found in the Database. Maybe it was uploaded from a dev version of the website. Please delete it from ImageKit and reupload it.' : 'Unknown error from ImageKit';
			
			notification.open({
				type: 'error',
				message: 'Something went wrong',
				description: error,
				placement: 'bottomRight',
			})
			return;
		}

		let payload = { ...localGuide }
		payload.local_guide_photo = photo
		payload.local_guide_photo_id = photo.id
		setLocalGuide(payload)

		setIsGalleryOpen(false)
	}

	const onDeleteImage = () => {
		let payload = { ...localGuide }
		payload.local_guide_photo = null
		setLocalGuide(payload)
	}


	const [hasPermissionToUpdate, hasPermissionToDelete] = permissionsStore
		.getState()
		.hasPermissions(['update:place', 'delete:place'])

	
	useImperativeHandle(
		ref,
		() => ({
			saveDataIfDirty: () => {
				isDirty && onSubmit()
			},
		}),
		[isDirty, form],
	)

	
    const onSubmit = () => {
    	updateLocalGuide()
      	setIsDirty(false)
    }

	
	const { mutate: updateLocalGuide, isLoading: isUpdateLocalGuideLoading } = useMutation(
		['updateLocalGuide', placeDetail],
		async () => {
			let payload = { ...localGuide, ...form.getFieldsValue() }
			setLocalGuide(payload)
      		return await placeService.updatePlaceDetail(placeDetail.id, payload);
		},
		mutationHandler({
			onSuccessMsg: 'Local Guide updated successfully',
			// onSuccessCallback: () => refetch(),
			onErrorMsg: 'An error occurred trying to update lcoal guide',
		}),
	)


	if (placeDetail?.id === 0) {
		return (
		<Card id={id} title="Local Guide">
			<h1>Available after place's basic detail is ready.</h1>
		</Card>
		)
	}

	
	const layout = {
		labelCol: { span: 24 },
		wrapperCol: { span: 24 },
	}

	return (
		<Card id={id} title="Local Guide">
			
			<Form {...layout} onValuesChange={() => setIsDirty(true)} form={form}>
				<Form.Item label="Full Name" name="local_guide_name" labelCol={{span: 24}} style={{ display: 'inline-block', width: 'calc(25% - 20px)' }}>
					<Input />
				</Form.Item>
				<Form.Item label="Button Name" name="local_guide_button" labelCol={{span: 24}} style={{ display: 'inline-block', width: 'calc(25% - 20px)', marginLeft: 20 }}>
					<Input />
				</Form.Item>
				<Form.Item label="Button Link" name="local_guide_link" labelCol={{span: 24}} style={{ display: 'inline-block', width: 'calc(50%)', marginLeft: 20 }}>
					<Input />
				</Form.Item>

				<Form.Item label="Description" name="local_guide_description">
					<TextArea />
				</Form.Item>
			
				<Form.Item label="Preview">
					<div className="imagePreview">
					<>
						{ localGuide?.local_guide_photo ? (<>
							<img
							alt="Local guide"
							src={
								localGuide?.local_guide_photo?.url
								? `${localGuide?.local_guide_photo?.url}?${transformations.small_thumbnail}`
								: ''
							}
							/>
						</>) : (
							<div className="dummy">
								No photo yet
							</div>
						) }
						<div className="actionBar">
							<span>
								<EditFilled
								disabled={!hasPermissionToUpdate}
								onClick={() => hasPermissionToUpdate && onChooseImage()}
								style={{ color: '#fff' }}
								/>
							</span>
							<span>
								<DeleteFilled
								disabled={!hasPermissionToDelete}
								style={{ color: '#fff' }}
								onClick={() => hasPermissionToDelete && onDeleteImage()}
								/>
							</span>
						</div>
					</>
					</div>
				</Form.Item>
				
				<Form.Item wrapperCol={{ offset: 0, span: 20 }}>
					<Button 
						disabled={!hasPermissionToUpdate}
						type="primary" 
						style={{ marginRight: 20 }} 
						onClick={() => hasPermissionToUpdate && onSubmit()}
						>
						Save
					</Button>
				</Form.Item>
			</Form>
			{ isGalleryOpen && (
				<GalleryDialog
					predefinedTags={['local-guides']}
					predefinedPlaceId={placeDetail?.id.toString()}
					fixedFolder={CDNFolders.LOCAL_GUIDES}
					cdnFolder={CDNFolders.LOCAL_GUIDES}
					open={true}
					onSelect={onSelectImage}
					onClose={() => setIsGalleryOpen(false)}
					requestParams={{ searchQuery: `tags IN["local-guides"]` }}
				/>
			) }
		</Card>
	)
});

export default LocalGuideSection
