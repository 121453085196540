import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { AdminWithRolesAndPermissions, adminService } from '../../../api/api-admin'

export const useAdminById = (id: string): UseQueryResult<AdminWithRolesAndPermissions, Error> => {
  return useQuery(
    ['getAdminDetail', id],
    async () => {
      const { data: admin } = await adminService.getAdminDetail(id)
      const [{ data: roles }, { data: permissions }] = await Promise.all([
        await adminService.getAdminRoles(admin.user_id),
        await adminService.getAdminPermissions(admin.user_id),
      ])
      return { ...admin, roles, permissions }
    },
    { staleTime: 1000 * 60 * 60, enabled: id !== '0' },
  )
}
