import { notification } from 'antd'
import { useQuery } from '@tanstack/react-query'
import { regionService } from '../api/api-region'

export type AllRegions = Region[]

export interface Region {
  id: number
  region_name: string
  region_title: string
  bounds: string
}

export const useAllRegions = (region: string) => {
  const { data: regions, isLoading } = useQuery<AllRegions>(
    ['useAllRegions', region],
    async () => {
      const { data } = await regionService.getAllRegions(0, 20, region)
      return data?.data || data
    },
    {
      onError: (err) => {
        notification.open({
          type: 'error',
          message: 'Something went wrong',
          description: `An error occurred trying to fetch the regions. region: ${region}`,
          placement: 'bottomRight',
        })
      },
    },
  )

  return { regions, isLoading }
}
