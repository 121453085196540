import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { apiClient } from '../api/apiClient'

/** this should be called when the users token has been modified */
export const useUpdateBearerToken = () => {
  const [hasToken, setHasToken] = useState(false)
  const { getAccessTokenSilently, isLoading } = useAuth0()
  useEffect(() => {
    if (!isLoading) {
      const updateToken = async () => {
        const token = await getAccessTokenSilently({ ignoreCache: true })
        apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
        setHasToken(!!token)
      }
      updateToken()
    }
  }, [getAccessTokenSilently, isLoading])

  return { isLoading: !hasToken }
}
