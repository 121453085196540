import get from 'lodash/get'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AUTH0_NAMESPACE } from '../../constants/auth0'
import { PATHS } from '../../constants/routes'
import { shouldReauthenticate } from '../../utils/auth/shouldReauthenticate'
import { useTokenClaims } from '../useTokenClaims'
const { DASHBOARD, PLACES } = PATHS

/** to be used when an authenticated user tries to access a route they don't need to be on e.g. /MFA/create */
const useRedirectIfAuthenticated = (redirectTo: string = `/${DASHBOARD}/${PLACES}`) => {
  const { claims } = useTokenClaims()
  const navigate = useNavigate()
  useEffect(() => {
    const appMeta = get(claims, `${AUTH0_NAMESPACE}`)?.appMeta
    const lastAuthenticated = appMeta?.admin_panel_2fa_last_authenticated
    const has2FA = !!appMeta?.admin_panel_2fa
    const isLastAuthenticatedInvalid = shouldReauthenticate(lastAuthenticated)

    if (has2FA && lastAuthenticated && !isLastAuthenticatedInvalid) {
      navigate(redirectTo)
    }
  }, [claims, navigate, redirectTo])
}

export default useRedirectIfAuthenticated
