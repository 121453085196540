import { Card } from 'antd'
import React, { useImperativeHandle, useRef } from 'react'
import { PlaceDetail } from '../../types/place-api'
import GoogleCard from './GoogleCard'

interface Props {
  id?: string
  placeDetail: PlaceDetail
  lang: string
}

const SeoCard = React.forwardRef(({ id, placeDetail, lang }: Props, ref) => {
  const googleCardRef = useRef<any>()

  const getSeoValues = () => {
    const seoDetail = googleCardRef.current.getSeoDetail()
    const googleValues = seoDetail.seo
    const thumbnail = seoDetail.thumbnail

    return { googleValues, thumbnail }
  }

  useImperativeHandle(
    ref,
    () => ({
      getSeoDetail: () => {
        return getSeoValues()
      },
    }),
    [],
  )


  return (
    <Card id={id} title="SEO">
      <GoogleCard lang={lang} placeDetail={placeDetail} ref={googleCardRef} />
    </Card>
  )
})

export default SeoCard
