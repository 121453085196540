import { Button, Col, Row } from 'antd'
import compact from 'lodash/compact'
import React from 'react'
import { ListFilesRequestParams } from '../../types/image-api'
import { defaultFilters, Filters } from './filter'
import Location from './Place'
import { defaultFilterState, FilterState } from './Photos'
import Sort from './Sort'
import Tags from './Tags'

const filterMap: Record<keyof Filters, React.FunctionComponent<any>> = {
  sort: Sort,
  tags: Tags,
  location: Location,
}

const AdvancedSearch: React.FC<{
  filters: FilterState['filters']
  setFilters: FilterState['setFilters']
  handleTogglePopover: () => void
  setParams: React.Dispatch<React.SetStateAction<ListFilesRequestParams>>
}> = ({ setParams, filters, setFilters, handleTogglePopover }) => {
  const generatedSearchQuery = (): string => {
    let query = [filters.tags.length && `tags IN ${JSON.stringify(filters.tags)}`]
    const sanitizedQuery = compact(query)
    return sanitizedQuery.join('') || ''
  }

  return (
    <div style={{ minWidth: 700 }}>
      {Object.entries(defaultFilters).map(([key, value]) => {
        const Filter = filterMap[key as keyof Filters]
        const isLast = key === 'country'
        return (
          <Row key={key} style={{ paddingBottom: isLast ? 0 : 16 }}>
            <Col>
              <Col style={{ fontWeight: 'bold', color: '#212121', marginBottom: 8 }} flex={1}>
                {value.label}
              </Col>
              <Filter filters={filters} setFilters={setFilters} values={value.values} />
            </Col>
          </Row>
        )
      })}

      <Row justify="end" style={{ marginTop: 32 }}>
        <Col flex={1}>
          <Button
            size="small"
            type="primary"
            onClick={() => {
              setParams((prevState) => ({
                ...prevState,
                searchQuery: generatedSearchQuery(),
                sort: filters.sortValue && filters.sortDirection ? `${filters.sortDirection}_${filters.sortValue}` : '',
                path: filters.location ? `/images${filters.location}` : '',
              }))
              handleTogglePopover()
            }}
          >
            Apply
          </Button>
          <Button type="link" onClick={() => setFilters(defaultFilterState)}>
            Reset
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default AdvancedSearch
