import { Typography } from 'antd'
import { useEffect, useRef, useState } from 'react'
import CodeInput from 'react-code-input'
import { FcLock } from 'react-icons/fc'
import { MFA_CODE_LENGTH } from '../../constants/auth0'
import { useVerify2fa } from '../../hooks/auth/mutations/useVerify2fa'
import { useUpdateBearerToken } from '../../hooks/useUpdateBearerToken'

const MFAChallenge: React.FC = () => {
  useUpdateBearerToken()
  const hasSubmitted = useRef(false)
  const [mfaCode, setMfaCode] = useState<string>()
  const useVerify2faMutation = useVerify2fa({
    onErrorCallback: () => {
      setMfaCode('')
      hasSubmitted.current = false
    },
  })

  useEffect(() => {
    if (!hasSubmitted.current && mfaCode?.length === MFA_CODE_LENGTH && !useVerify2faMutation.isLoading) {
      hasSubmitted.current = true
      useVerify2faMutation.mutate(mfaCode)
    }
  }, [mfaCode, useVerify2faMutation])

  return (
    <>
      <FcLock size={96} />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Typography.Title level={3}>2FA Challenge</Typography.Title>
        <Typography.Paragraph style={{ color: '#888888' }}>
          Enter the 6 digit code from your authenticator app
        </Typography.Paragraph>
      </div>
      {/** @ts-ignore */}
      <CodeInput
        value={mfaCode}
        onChange={(code) => setMfaCode(code)}
        inputMode="numeric"
        name="mfa"
        fields={MFA_CODE_LENGTH}
      />
    </>
  )
}

export default MFAChallenge
