import { AxiosInstance } from 'axios'
import { apiClient } from './apiClient'

class CommentsService {
  public URI: string = '/comments'
  constructor(private apiClient: AxiosInstance) {}

  public getAll = (offset = 0, limit = 20, query = '', user_id = '') => {
    return this.apiClient.get(`${this.URI}?offset=${offset}&limit=${limit}&query=${query}&user_id=${user_id}`)
  }

  public getCommentDetail = (commentId: string | number) => {
    return this.apiClient.get(`${this.URI}/detail/${commentId}`)
  }

  public getCommentsOfPlace = (placeId: string | number, offset = 0, limit = 20, query = '') => {
    return this.apiClient.get(`${this.URI}/places/${placeId}/admin?offset=${offset}&limit=${limit}&query=${query}`)
  }

  public searchComments = (query: string) => {
    return this.apiClient.get(`${this.URI}/search/${query}`)
  }

  public updateCommentDetail = (commentId: string | number, data: unknown) => {
    return this.apiClient.put(`${this.URI}/${commentId}`, data)
  }

  public deleteComment = (commentId: string) => {
    return this.apiClient.delete(`${this.URI}/${commentId}`)
  }

  public createComment = (data: unknown) => {
    return this.apiClient.post(`${this.URI}`, data)
  }

  public updateComment = (id: string | number, data: unknown) => {
    return this.apiClient.put(`${this.URI}/${id}`, data)
  }

  public toggleComment = (payload: { id: any; field: any; field_value: any }) => {
    return this.apiClient.get(`${this.URI}/${payload.id}/${payload.field}/${payload.field_value}`)
  }
}

export const commentsService = new CommentsService(apiClient)
