import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Form, FormInstance, Input, notification, Space, Table, Tooltip } from 'antd'
import { AxiosError } from 'axios'
import React, { useState } from 'react'
import { placeService } from '../../api/api-place'
import AuthorizedButton from '../../components/Auth0/AuthorizedButton'
import { permissionsStore } from '../../data/permissions'
const { TextArea } = Input

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean
  dataIndex: string
  title: any
  record: any
  index: number
  children: React.ReactNode
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = <TextArea autoSize={{ minRows: 3, maxRows: 555 }} />

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

type Props = {
  item: { id: string }
  editingId: string
  onChange: () => void
  onEditing: (id: string) => void
  form: FormInstance<any>
}

const ActionCell: React.FC<Props> = ({ item, editingId, onChange, onEditing, form }) => {
  const hasPermissionToUpdate = permissionsStore.getState().hasPermission('update:place')

  const onClickEditBtn = () => {
    onEditing(item.id)
    form.setFieldsValue(item)
  }

  const { mutate: updatePlaceSeoMutation, isLoading } = useMutation(
    ['updatePlaceSeo', item.id],
    async () => await placeService.updatePlaceSeo(item.id, form.getFieldsValue()),
    {
      onSuccess: (res) => {
        onEditing('')
        onChange()
      },
      onError: (err: AxiosError) => {
        notification.open({
          type: 'error',
          message: 'Something went wrong',
          description: `An error occurred trying to update SEO - ${JSON.stringify(err.response?.data)}`,
          placement: 'bottomRight',
        })
        onEditing('')
      },
    },
  )

  if (editingId === item.id) {
    return (
      <Space size="middle">
        <AuthorizedButton
          hasPermission={hasPermissionToUpdate}
          loading={isLoading}
          icon={<CheckCircleOutlined />}
          onClick={() => updatePlaceSeoMutation()}
        />
        <Tooltip title="Close">
          <Button disabled={!hasPermissionToUpdate} icon={<CloseCircleOutlined />} onClick={() => onEditing('')} />
        </Tooltip>
      </Space>
    )
  }

  return (
    <Space size="middle">
      <AuthorizedButton
        hasPermission={hasPermissionToUpdate}
        loading={isLoading}
        icon={<EditOutlined />}
        onClick={() => onClickEditBtn()}
      />
    </Space>
  )
}

const AllSeos = () => {
  const [form] = Form.useForm()
  const [editingId, setEditingId] = useState('')
  const [query, setQuery] = useState('')
  const [tablePaginationOptions, setTablePaginationOptions] = useState({ total: 0, curPage: 1, pageSize: 10 })

  const {
    data: tableData,
    isLoading,
    refetch,
  } = useQuery(['getAllSeo', query, tablePaginationOptions], async () => {
    const { pageSize, curPage } = tablePaginationOptions
    const { data } = await placeService.getAllSeo(pageSize * (curPage - 1), pageSize, query)
    setTablePaginationOptions({ curPage, pageSize, total: data.total })
    return data?.data
  })

  const columns = [
    {
      title: 'Place',
      dataIndex: 'place.title',
      key: 'place',
      render: (text: string, item: any) => <b>{item.place.title}</b>,
    },
    {
      title: 'SEO Title',
      dataIndex: 'title',
      editable: true,
    },
    {
      title: 'Meta Description',
      dataIndex: 'description',
      editable: true,
    },
    {
      title: 'Action',
      key: 'action',
      width: '20%',
      render: (text: string, record: any) => (
        <ActionCell
          form={form}
          item={record}
          editingId={editingId}
          onChange={refetch}
          onEditing={(val) => setEditingId(val)}
        />
      ),
    },
  ]

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: 'textarea',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: record.id === editingId,
      }),
    }
  })

  return (
    <>
      <div className="table-header">
        <Input.Search
          style={{ width: '40%' }}
          onPressEnter={(e) => setQuery(e.currentTarget.value)}
          onSearch={() => refetch()}
          placeholder="Search for a place"
        />
      </div>
      <Form form={form} component={false}>
        <Table
          rowKey="id"
          loading={isLoading}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={mergedColumns}
          dataSource={tableData}
          onChange={(pagination) =>
            setTablePaginationOptions({ ...tablePaginationOptions, curPage: pagination.current ?? 0 })
          }
          pagination={{
            defaultPageSize: tablePaginationOptions.pageSize,
            showSizeChanger: false,
            total: tablePaginationOptions.total,
          }}
        />
      </Form>
    </>
  )
}

export default AllSeos
