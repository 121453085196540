import { Select } from 'antd'
import { Filters } from './filter'
import { FilterState } from './Photos'

const Sort: React.FC<Filters['sort'] & FilterState> = ({ values, direction, filters, setFilters }) => {
  const handleChange = (key: 'sortDirection' | 'sortValue') => (value: string) =>
    setFilters({ ...filters, [key]: value })

  return (
    <div>
      <Select
        value={filters.sortValue || 'Created at'}
        placeholder="Sort"
        style={{ width: 120 }}
        onChange={(val) => handleChange('sortValue')(val)}
      >
        {values.map((value) => (
          <Select.Option key={value.value} value={value.value}>
            {value.label}
          </Select.Option>
        ))}
      </Select>
      <Select
        value={filters.sortDirection || 'Descending'}
        placeholder="Direction"
        style={{ width: 130 }}
        onChange={(dir) => handleChange('sortDirection')(dir)}
      >
        {[
          { label: 'Ascending', value: 'ASC' },
          { label: 'Descending', value: 'DESC' },
        ].map((value) => (
          <Select.Option key={value.value} value={value.value}>
            {value.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

export default Sort
