import { AxiosInstance } from 'axios'
import { apiClient } from './apiClient'

class PageService {
  public URI: string = '/pages'
  constructor(private apiClient: AxiosInstance) {}

  public createHomeCategoryLinks = (payload: any) => {
    return this.apiClient.post(`${this.URI}/home/category_links`, payload)
  }

  public updateHomeCategoryLinks = (payload: any) => {
    return this.apiClient.put(`${this.URI}/home/category_links`, payload)
  }

  public deleteHomeCategoryLinks = (id: string) => {
    return this.apiClient.delete(`${this.URI}/home/category_links/${id}`)
  }
}

export const pageService = new PageService(apiClient)
