export const PATHS = {
  LOGIN: '/',
  DASHBOARD: 'dashboard',
  PUBLIC_USER_MANAGEMENT: 'public user management',
  USER_MANAGEMENT: 'user management',
  PRODUCTS_ALL: 'products/all',
  PRODUCTS_TAGS: 'products/tags',
  PLACES: 'places',
  COMMENTS: 'comments',
  USERS: 'users',
  ADMINS: 'admins',
  Regions: 'regions',
  SEO: 'seo',
  TRANSLATION: 'translation',
  REGIONS: 'regions',
  REDIRECTIONS: 'redirections',
  PHOTOS: 'photos',
  ANALYSTIC_PHOTO: 'analystic_photos',
  WAKACJE_LOG: 'wakacje-log',
  UNAUTHORIZED: 'unauthorized',
  MFA: 'mfa',
} as const

type ValueOf<T> = T[keyof T]
export type Routes = ValueOf<typeof PATHS>
