import { Button, Card, Spin, notification } from 'antd'
import { placeService } from '../../../api/api-place'
import GalleryDialog from '../../../components/GalleryDialog/GalleryDialog'
import React, { useState, useImperativeHandle, useRef, createRef, useEffect } from 'react'
import Hotelitem from './HotelItem'
import { PlaceDetail, WhereToStay } from '../../../types/place-api'
import { useMutation } from '@tanstack/react-query'
import { mutationHandler } from '../../../utils/mutationHandler'
import { CDNFolders } from '../../../imagekit/helpers'
import { first } from 'lodash'
import { imageService } from '../../../api/api-image'
import { permissionsStore } from '../../../data/permissions'
import { ImageModel } from '../../../types/image-api'
import { AxiosError } from 'axios'

interface Props {
  id: string
  placeDetail?: PlaceDetail
  refetch: () => void
  lang: string
}

const newHotelId = 0

const newHotel = {
  id: newHotelId,
  title: '',
  text: '',
  opinions: 0,
  rating: 0,
} as any

const HotelSection = React.forwardRef(({ id, placeDetail, refetch, lang }: Props, ref) => {
  const [hotels, setHotels] = useState(placeDetail?.where_to_stay ?? [])
	useEffect(() => {
		setHotels(placeDetail?.where_to_stay ?? []);
	}, [placeDetail]);

  const [isGalleryOpen, setIsGalleryOpen] = useState(false)
  const [editingHotelIndex, setEditingHotelIndex] = useState<number | undefined>()

  const [hasPermissionToUpdate, hasPermissionToDelete] = permissionsStore
    .getState()
    .hasPermissions(['update:place', 'delete:place'])

  const myRefs = useRef([])
  myRefs.current = hotels.map((element, i) => myRefs.current[i] ?? createRef())

  useImperativeHandle(
    ref,
    () => ({
      saveHotelData: () => {
        myRefs.current?.forEach((element: any) => {
          element.current?.saveDataIfDirty()
        })
      },
    }),
    [myRefs],
  )

  const { mutate: deleteHotelMutation, isLoading: isDeleteHotelMutationLoading } = useMutation(
    ['deleteHotel', hotels],
    async (index: number) => {
      const hotel = hotels[index]

      if (hotel.id === newHotelId) {
        setHotels((prevState) => prevState.filter((_, i) => i !== newHotelId))
      } else {
        await placeService.deleteHotel(hotel.id)
        return hotel.id
      }
    },
    mutationHandler({
      onSuccessCallback: (id: number) => {

        setHotels((prevState) => prevState.filter((hotel) => hotel.id !== id))
        refetch()
      },
      onErrorMsg: 'An error occurred trying to delete hotel',
      onSuccessMsg: 'Hotel deleted successfully',
    }),
  )

  const { mutate: createHotelMutation, isLoading: isCreateHotelLoading } = useMutation(
    ['createHotel'],
    async (hotelDetail: WhereToStay) => {
      return await placeService.createHotel({ ...hotelDetail, city_id: placeDetail?.id ?? 0 })
    },
    mutationHandler({
      onSuccessMsg: 'Hotel created successfully',
      onSuccessCallback: () => refetch(),
      onErrorMsg: 'An error occurred trying to create hotel',
    }),
  )

  const { mutate: updateHotelMutation, isLoading: isUpdateHotelLoading } = useMutation(
    ['updateHotel', placeDetail],
    async (hotelDetail: WhereToStay) => {
      return await placeService.updateHotel(hotelDetail.id, { ...hotelDetail, city_id: placeDetail?.id })
    },
    mutationHandler({
      onSuccessMsg: 'Hotel updated successfully',
      onSuccessCallback: () => refetch(),
      onErrorMsg: 'An error occurred trying to update hotel',
    }),
  )

  const onChooseImage = (index: number) => {
    setEditingHotelIndex(index)
    setIsGalleryOpen(true)
  }

  const onSelectPhoto = async (photoIds: string[]) => {
    const id = first(photoIds) ?? ''
	
	let photo;
	try {
		const apiReposne = await imageService.getImageDetail(id)
		console.log('apiReposne', apiReposne);
		photo = apiReposne.data as ImageModel;
	} catch( e:unknown ) {
		let error = e instanceof AxiosError && e?.response?.status === 404 ? 'Image not found in the Database. Maybe it was uploaded from a dev version of the website. Please delete it from ImageKit and reupload it.' : 'Unknown error from ImageKit';
		
		notification.open({
			type: 'error',
			message: 'Something went wrong',
			description: error,
			placement: 'bottomRight',
		})
		return;
	}

    if (editingHotelIndex !== undefined) {
      hotels[editingHotelIndex].photo = photo
      hotels[editingHotelIndex].photo_id = photo.id
      setHotels([...hotels])
    }

    setIsGalleryOpen(false)
  }

  const onDeleteHotelImage = (index: number) => {
    hotels[index].photo = null as any
    setHotels([...hotels])
  }

  const loading = isCreateHotelLoading || isDeleteHotelMutationLoading || isUpdateHotelLoading

  if (placeDetail?.id === 0) {
    return (
      <Card title="Hotels">
        <h1>Available after place's basic detail is ready.</h1>
      </Card>
    )
  }

  return (
    <Card id={id} title="Hotels">
      {hotels?.map((item, index) => (
        <Hotelitem
          data={item}
		  lang={lang}
          placeDetail={placeDetail}
          key={`${item.id}-${index}`}
          onDelete={() => hasPermissionToDelete && deleteHotelMutation(index)}
          onSave={updateHotelMutation}
          onCreate={createHotelMutation}
          onDeleteImage={() => hasPermissionToDelete && onDeleteHotelImage(index)}
          onChooseImage={() => hasPermissionToUpdate && onChooseImage(index)}
          ref={myRefs.current[index]}
        />
      ))}
      <div style={{ textAlign: 'right', marginTop: 24 }}>
        {loading && <Spin style={{ marginRight: 24 }} />}
        <Button
          disabled={!hasPermissionToUpdate}
          type="primary"
          onClick={() => hasPermissionToUpdate && setHotels((prevState) => [...prevState, newHotel])}
        >
          Add More
        </Button>
      </div>
      <GalleryDialog
        cdnFolder={CDNFolders.ACCOMMODATION}
        open={isGalleryOpen}
        onSelect={onSelectPhoto}
        onClose={() => setIsGalleryOpen(false)}
        requestParams={placeDetail?.guid ? { path: `${CDNFolders.ACCOMMODATION}${placeDetail.guid}` } : undefined}
      />
    </Card>
  )
})

export default HotelSection
