import { Button, Card } from 'antd'
import { placeService } from '../../../api/api-place'
import React, { useState } from 'react'
import LinkItem from './LinkItem'
import { PlaceDetail } from '../../../types/place-api'
import { permissionsStore } from '../../../data/permissions'

interface Props {
  id: string
  placeDetail: PlaceDetail
}
const ExternalLinkSection: React.FC<Props> = ({ id, placeDetail }) => {
  const [linksArr, setLinksArr] = useState(placeDetail?.external_links ?? [])
  const [hasPermissionToUpdate] = permissionsStore.getState().hasPermissions(['update:place', 'delete:place'])

  const onDeleteExternalLink = (index: number) => {
    const item = linksArr[index]
    if (item.id === 0) {
      const newArr = [...linksArr]
      newArr.pop()
      setLinksArr(newArr)
    } else {
      // make api call to delete
      placeService
        .deleteExternalLink(item.id)
        .then((res) => {
          const newArr = [...linksArr]
          newArr.splice(index, 1)
          setLinksArr(newArr)
        })
        .catch((error) => {
          console.log(error.message)
        })
    }
  }

  const onSaveExternalLink = (values: any, index: number) => {
    values.place_id = placeDetail?.id
    if (values.id === 0) {
      placeService
        .createExternalLink(values)
        .then((res) => {
          linksArr[index].id = res.data.id
          setLinksArr([...linksArr])
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      placeService
        .updateExternalLink(values.id, values)
        .then((res) => {})
        .catch((error) => {
          console.log('ERROR ==>', error.message)
        })
    }
  }

  const addMoreItems = () => {
    const newLinkObj = {
      id: 0,
      title: '',
      url: '',
    }
    setLinksArr([...linksArr, newLinkObj])
  }

  if (placeDetail?.id === 0) {
    return (
      <Card id={id} title="External Links">
        <h1>Available after place's basic detail is ready.</h1>
      </Card>
    )
  }

  return (
    <Card id={id} title="External Links">
      {linksArr.map((item, index) => (
        <LinkItem
          data={item}
          key={index}
          onDelete={() => {
            onDeleteExternalLink(index)
          }}
          onSave={(values) => {
            onSaveExternalLink(values, index)
          }}
        />
      ))}
      <div style={{ textAlign: 'right', marginTop: 24 }}>
        <Button
          disabled={!hasPermissionToUpdate}
          type="primary"
          onClick={() => hasPermissionToUpdate && addMoreItems()}
        >
          Add More
        </Button>
      </div>
    </Card>
  )
}

export default ExternalLinkSection
