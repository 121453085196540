import { Button, Result } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const FourZeroFour: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button type="primary" onClick={() => navigate(-1)}>
              Go back
            </Button>
          }
        />
      </div>
    </div>
  )
}

export default FourZeroFour
