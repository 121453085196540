import { ImageModel } from './image-api'
import { AdminDatabase } from '../api/api-admin'

export type AllCountriesAPI = Continent[]

export interface Continent {
  id: number
  title: string
  place_parent: string
  guid: string
  countries: Country[]
}

export interface Country {
  id: number
  title: string
  place_parent: string
  guid: string
}

export type AllSlugs = Slug[]

type Slug = {
  id: number
  title: string
  guid: string
  place_type: 'city' | 'country' | 'homepage' | 'child'
}

export type PlaceNotesModel = {
  placeId: string
  note: string
}
export type PlaceModel = {
  id: number
  title: string
  page_link_title: string
  content: string
  second_page_content: string
  place_parent: string
  search_label: string
  longitude: string
  latitude: string
  bounds: string
  guid: string
  place_type: string
  list_label: string
  thumbnail_photo_id: string
  status: string
  affiliates_count: number
  approved_tomek_at: string
  approved_weronika_at: string
  photos_counter: number
}

export interface PlaceTranslation {
	id: number | undefined
	place_id: number | undefined
	status: string
	locale: string
	title: string
	content: string
	guid: string
	search_label: string
	second_page_content: string
	list_label: string
	page_link_title: string
	translation_status: string
}

export function PlaceTranslationEmptyObject(): PlaceTranslation {
	return {
		id: undefined,
		place_id: undefined,
		status: 'draft',
		locale: '',
		title: '',
		content: '',
		guid: '',
		search_label: '',
		second_page_content: '',
		list_label: '',
		page_link_title: '',
		translation_status: 'untranslated',
	}
};


export interface PlaceDetail {
	id: number
	title: string
	content: string
	place_parent: string
	guid: string
	place_type: 'city' | 'country' | 'homepage' | 'child'
	search_label: string
	longitude: string
	latitude: string
	bounds: string
	second_page_content: string
	created_at: string
	updated_at: string
	list_label: string
	iso_code: string
	english: string
	thumbnail_photo_id: string
	status: string
	page_link_title: any
	related_posts: RelatedPost[]
	rating: Rating
	categories: Category[]
	images: ImageModel[]
	children: PlaceDetail[]
	category_pages: CategoryPage[]
	thumbnail: ImageModel
	seo: PlaceSeo
	city_contents?: CityContent[]
	where_to_stay?: WhereToStay[]
	country?: string
	region?: Region
	external_links?: any[]
	cached?: boolean
	category_page?: CategoryPageDetail
	redirection?: {
		target_url: string
	}
	translations: PlaceTranslation[]
	notes: PlaceNotesModel[]
	local_guide_link: string
	local_guide_photo_id: string
	local_guide_photo: ImageModel | null
	local_guide_name: string
	local_guide_button: string
	local_guide_description: string
	admin_id?: number
	admin?: AdminDatabase
}

export interface Region {
  id: number
  region_name: string
  region_title: string
  bounds: string
}

export interface CityContent {
  id: number
  content_key: string
  city_id: number
  title: string
  content: string
  premium: boolean
}

export interface WhereToStay {
  id: number
  city_id: number
  category_id: number
  title: string
  link: string
  photo_id: string
  stars: number
  opinions: number
  rating: number
  text: string
  photo: ImageModel
  translations: WhereToStayTranslation[]
}
export interface WhereToStayTranslation {
  locale: string
  text: string
}
export function WhereToStayTranslationEmptyObject(): WhereToStayTranslation {
	return {
		locale: '',
		text: '',
	}
};

export interface RelatedPost {
  id: number
  title: string
  content: string
  place_parent: string
  guid: string
  place_type: string
  search_label: string
  longitude: string
  latitude: string
  bounds: string
  second_page_content: string
  created_at: any
  updated_at: any
  list_label: any
  iso_code: any
  english: any
  thumbnail_photo_id: string
  status: string
  page_link_title: any
  category: Category
  thumbnail: ImageModel
}

export interface Category {
  id: number
  category_name: string
  uri: string
  link: string
}

export interface Rating {
  ratingValue: number
  reviewCount: number
}

export interface Category2 {
  category_name: string
  id: number
  uri: string
  link: string
}

export interface Gallery {
  id: number
  alt: string
  bad_license: number
  place_id: number
  description: string
  photo_id?: number
  author: string
  provider?: string
  photo: Photo[]
}

export interface Photo {
  id: number
  url: string
  alt: string
  description: string
  filename: string
  height: number
  width: number
  verified: number
  provider: any
  pivot: Pivot
}

export interface Pivot {
  id: number
  photo_id: number
}

export interface PageChildren {
  id: number
  title: string
  content: string
  place_parent: string
  guid: string
  place_type: string
  search_label: string
  longitude: string
  latitude: string
  bounds: string
  second_page_content?: string
  created_at: any
  updated_at: string
  list_label: string
  iso_code: any
  english: any
  thumbnail_photo_id: string
  status: string
  page_link_title: any
  thumbnail: ImageModel
  rating: Rating
}

export interface CategoryPageDetail {
  id: number
  title: string
  content: string
  place_parent: string
  guid: string
  place_type: string
  search_label: string
  longitude: string
  latitude: string
  bounds: string
  second_page_content: string
  created_at: any
  updated_at: any
  list_label: any
  iso_code: any
  english: any
  thumbnail_photo_id: string
  status: string
  page_link_title: any
  children: PageChildren[]
  thumbnail: ImageModel
}

export interface CategoryPage {
  id: number
  place_id: number
  category_id: number
  category_page_id: number
  category_page: CategoryPageDetail
  category: Category
}

export interface PlaceSeo {
  id: number
  place_id: number
  title: string
  description: string
  index: string
  follow: string
  translations: PlaceSeoTranslation[]
}

export interface PlaceSeoTranslation {
  locale: string
  title: string
  description: string
  index: string
  follow: string
}

export function PlaceSeoTranslationEmptyObject(): PlaceSeoTranslation {
	return {
		locale: '',
		title: '',
		description: '',
		index: '',
		follow: '',
	}
};

export interface Breadcrumb {
  position: number
  name: string
  item: string
}

export interface SeoImage {
  url: string
  width: number
  height: number
  alt: string
}

//next-seo says it's a string
export interface AggregateRating {
  ratingCount: string
  ratingValue: string
}
