import { Button, Card, Checkbox, Form, Input, notification } from 'antd'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { commentsService } from '../../../api/api-comments'

type FormValues = {
  comment_author: string
  comment_author_email: string
  comment_content: string
  ratings: any
  comment_approved: string
}

const CommentForm = () => {
  const params = useParams()
  const commentId = params.commentId
  const [form] = Form.useForm<FormValues>()

  useQuery(['getCommentDetail', commentId, form], async () => await commentsService.getCommentDetail(commentId ?? ''), {
    onSuccess: (res) => {
      form.setFieldsValue(res.data)
    },
  })

  const { mutate: updateOrCreateComment, isLoading } = useMutation(
    ['updateOrCreateComment'],
    async (formValues: FormValues) => {
      return commentId && parseInt(commentId) > 0
        ? await commentsService.updateCommentDetail(commentId, formValues)
        : await commentsService.createComment(formValues)
    },
    {
      onSuccess: (res) => {
        notification.open({ type: 'success', message: 'Success', placement: 'bottomRight' })
      },
    },
  )

  return (
    <>
      <Card title="Comment">
        <Form form={form} style={{ marginTop: 20 }} onFinish={updateOrCreateComment}>
          Author Name:
          <Form.Item name="comment_author" rules={[{ required: true, message: 'required!' }]}>
            <Input />
          </Form.Item>
          Author Email:
          <Form.Item name="comment_author_email" rules={[{ required: true, message: 'required!' }]}>
            <Input />
          </Form.Item>
          Comment:
          <Form.Item name="comment_content" rules={[{ required: true, message: 'required!' }]}>
            <Input.TextArea autoSize={true} />
          </Form.Item>
          Rate:
          <Form.Item name="ratings" rules={[{ required: true, message: 'required!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Approve" name="comment_approved" valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <br />
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  )
}

export default CommentForm
