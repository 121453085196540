import { Button, Card, Col, Form, Badge, Input, notification, Row, Select, Checkbox, CheckboxOptionType, Spin } from 'antd'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useRef, useState, createRef } from 'react'
import { useMutation } from '@tanstack/react-query'
import { placeService } from '../../../../api/api-place'
import config from '../../../../api/config'
import HTMLEditor from '../../../../components/HTMLEditor'
import SEOCard from '../../../../components/SEOCard'
import { MenuLink, StickyMenu } from '../../../../components/StickyMenu'
import { PATHS } from '../../../../constants/routes'
import { useAllRegions } from '../../../../hooks/useAllRegions'
import { AllPlaces } from '../../../../hooks/usePlaceDetail'
import { PlaceDetail, PlaceModel, PlaceTranslation, PlaceTranslationEmptyObject } from '../../../../types/place-api'
import { generateUrlFromTitle, getLastSlug } from '../../../../utils'
import ExternalLinkSection from '../../ExternalLinkSection'
import GallerySection from '../../GallerySection'
import HotelSection from '../../HotelSection'
import { saveSeoContent } from '../CountryForm/CountryForm'
import { SeoFields } from '../CountryForm/types'
import { useStickyHeader } from '../../../../../src/hooks/useStickyHeader'
import { useConfig } from '../../../../hooks/useConfig'
import { LanguageServiceMode } from 'typescript'

interface Props {
  lang: string
  placeDetail: PlaceDetail
  refetch: () => void
}
const TranslatePlace: React.FC<Props> = ({ lang, placeDetail, refetch }) => {
  	
  const { data: configData, isLoading: isConfigLoading, refetch: refecthConfig } = useConfig()
  const [form] = Form.useForm()

  useEffect(() => {
    if (placeDetail) {
      form.setFieldsValue(placeDetail)
    }
  }, [form, placeDetail])


  const [translatedContent, setTranslatedContent] = useState<PlaceTranslation | undefined>(placeDetail?.translations[0])
  useEffect(() => {
    if (placeDetail) {
		let translated = placeDetail.translations.find(element => element.locale === lang);
		if(!translated) {
			translated = PlaceTranslationEmptyObject()
		}
      	setTranslatedContent( translated )
    }
  }, [lang, placeDetail])



  const primaryButtonStyle = { backgroundColor: '#0ab068', color: '#fff', margin: '0px 16px 16px 0' }
  const secondaryButtonStyle = { backgroundColor: '#1890ff', color: '#fff', margin: '0px 16px 16px 0' }

	
  const { mutate: sendToTranslators, isLoading: isSendToTranslatorsLoading } = useMutation(
    [form],
    async (autoTranslate:boolean|undefined) => {
		const payload = {
			...form.getFieldsValue(),
			autotranslate: autoTranslate
		}
    	return await placeService.sendPlaceForTranslation(placeDetail.id, payload);
    },
    {
      onSuccess: (data: unknown) => {
        notification.success({ message: 'Success', description: 'Translation actions executed' })
        refetch()
      },
      onError: (err: unknown) => {
        notification.open({
          type: 'error',
          message: 'Something went wrong',
          description: `An error occurred trying to do this`,
          placement: 'bottomRight',
        })
      },
    },
  )
	
  const { mutate: setTranslationStatus, isLoading: isSetTranslationStatusLoading } = useMutation(
    [form],
    async (status:string) => {
    	return await placeService.setPlaceTranslationStatus(placeDetail.id, {
			status: status,
			lang: lang,
		});
    },
    {
      onSuccess: (data: unknown) => {
        notification.success({ message: 'Success', description: 'Translation actions executed' })
        refetch()
      },
      onError: (err: unknown) => {
        notification.open({
          type: 'error',
          message: 'Something went wrong',
          description: `An error occurred trying to do this`,
          placement: 'bottomRight',
        })
      },
    },
  )

  let langsList:Array<CheckboxOptionType> = [];
  configData?.langs.map( (curLang) => {
	const currentTranslation = placeDetail?.translations.find(element => element.locale === curLang.locale)
	const ts = currentTranslation?.translation_status ?? 'untranslated'
	langsList.push({
		label: (<>
			<b>
				{curLang?.name}
			</b>
			<br/>
			<Badge status={configData.translationStatuses.find( e => e.key === ts )?.badgeStatus} text={configData.translationStatuses.find( e => e.key === ts )?.name} />
			<br/>
			{ currentTranslation?.content ? 
				<Badge status="success" text="Has content" />
			:  
				<Badge status="default" text="No content yet" />
			}
			<br/>
			{ (currentTranslation?.status ?? 'draft') == 'draft' ? 
				<Badge status="default" text="Draft" />
			:  
				<Badge status="success" text="Live" />
			}
			<br/>
			<br/>
		</>),
		value: curLang.locale
	})
  })

  return (
    <>
		<br/>
      <Row gutter={16}>
        <Col span={20}>
          <Card
            id="translate-content"
            title={lang === 'pl' ? 'Translation management' : 'Translation status'}
          >
			{ lang === 'pl' && (
			<Row gutter={8}>
				<Col span={24}>
					<p>
						<b>Admins only</b>, translators won't have access to PL at all
					</p>
				</Col>
				<Col span={24}>
					<Form form={form}>
						<Form.Item name="langs"  valuePropName="checked">
							<Checkbox.Group
								options={langsList}
							/>
						</Form.Item>
					</Form>
				</Col>
				
				{isSetTranslationStatusLoading || isSendToTranslatorsLoading ? (
					<Col span={12}>
						<Spin />
					</Col>
				) : (
					<>
						<Col span={12}>
							<Button style={primaryButtonStyle} onClick={() => { sendToTranslators(true) } }>
								Autotranslate & send for proofreading
							</Button>
							<p>
								* Will <b>overwrite: Place info, Category Contents, Hotels and SEO</b> for the selected languages. Then the selected laguage versions' status will be switched to <b>draft</b> until translated. 
							</p>
						</Col>
						<Col span={12}>
							<Button style={secondaryButtonStyle} onClick={() => { sendToTranslators(false) } }>
								Keep content & send for proofreading
							</Button>
							<p>
								* Will notput the  selected <b>live</b> laguage versions to <b>draft</b>, as they have valid content
							</p>
						</Col>
					</>
				)}
			</Row>
			)}
			{ lang !== 'pl' && (
				<Row gutter={8}>
					<Col span={24}>
						{ translatedContent?.translation_status === 'live' && (<>
							<p>
								<b>Published</b>. 
								Great job! 
								Feel free to update the translation if you feel like it needs editing.
							</p>
						</>) }
						{ translatedContent?.translation_status === 'untranslated' && (<>
							<p>
								<b>No content yet</b>. Nothing to do here... yet.
							</p>
						</>) }
						{ (translatedContent?.translation_status === 'ready' || translatedContent?.translation_status === 'changes') && (<>
							{ translatedContent?.translation_status === 'ready' && (
							<p>
								<b>Ready for translation</b>. 
								Now it's your time to shine. 
								You can proceed the content, click Update to save it. 
								Then when you are ready - click the button below:
							</p>
							)}
							{ translatedContent?.translation_status === 'changes' && (
							<p>
								<b>New content needs translation</b>. 
								There was some change in the content here. Please check, translate and hit Update.
								Then when you are ready - click the button below:
							</p>
							)}
							<Button style={primaryButtonStyle} onClick={() => { setTranslationStatus('translated') } }>
								Mark as "Translated"
							</Button>						
						</>) }
						{ (translatedContent?.translation_status === 'translated') && (<>
							<p>
								<b>Ready for review & release</b>. 
								Please review if the translator has filled all necessary fields.
								{ (translatedContent?.status ?? 'draft') === 'draft' && <>If you feel like everything looks good - click the button below to publis the page</> }
								{ (translatedContent?.status ?? 'draft') === 'active' && <>If you feel like everything looks good - click the button below to mark it as approved</> }
							</p>
							<Button style={primaryButtonStyle} onClick={() => { setTranslationStatus('live') } }>
								{ (translatedContent?.status ?? 'draft') === 'draft' && <>Approve & Publish</> }
								{ (translatedContent?.status ?? 'draft') === 'active' && <>Approve</> }
							</Button>						
						</>) }
					</Col>
				</Row>
			)}
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default TranslatePlace
