import { Button } from 'antd'
import React, { useState } from 'react'
import { CDNFolders } from '../../imagekit/helpers'
import PhotoForm from '../../pages/Photos/PhotoForm'
import PhotoGallery from '../../pages/Photos/PhotoGallery'
import { GetAllPhotosResponse, ListFilesRequestParams } from '../../types/image-api'
import { Modal } from '../Modal'

interface Props {
  open: boolean
  title?: string
  tab?: '1' | '2'
  cdnFolder?: CDNFolders
  requestParams?: ListFilesRequestParams /** see https://docs.imagekit.io/api-reference/media-api/list-and-search-files */
  onClose: () => void
  onSelect: (photoIds: string[]) => void
  onUploadSuccess?: () => void
  fixedFolder?: string | undefined
  predefinedPlaceId?: string
  predefinedTags?: string[] | undefined
}
const GalleryDialog: React.FC<Props> = ({
  open,
  onSelect,
  title,
  onClose,
  tab,
  onUploadSuccess,
  cdnFolder,
  requestParams,
  fixedFolder,
  predefinedPlaceId,
  predefinedTags,
}) => {
  const [selectedPhotos, setSelectedPhotos] = useState<string[]>([])
  const [openedPhoto, setOpenedPhoto] = useState<GetAllPhotosResponse | null>(null)

  const onTabChange = () => {
    setSelectedPhotos([])
    setOpenedPhoto(null)
  }

  const handleSelectPhoto = (photo: GetAllPhotosResponse) => {
    setSelectedPhotos((prevState) =>
      prevState.includes(photo.fileId) ? prevState.filter((id) => id !== photo.fileId) : [photo.fileId],
    )
  }

  return (
    <Modal
      title={title ?? 'Select Image'}
      centered
      width="90%"
      visible={open}
      onCancel={onClose}
      footer={
        <div>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" disabled={selectedPhotos.length === 0} onClick={() => onSelect(selectedPhotos)}>
            Ok
          </Button>
        </div>
      }
    >
      <PhotoGallery
        setSelectedPhoto={setOpenedPhoto}
        selectedPhotos={selectedPhotos}
        handleOpenPhoto={handleSelectPhoto}
        onTabChange={onTabChange}
        tab={tab}
        onUploadSuccess={onUploadSuccess}
        cdnFolder={cdnFolder}
        requestParams={requestParams}
        fixedFolder={fixedFolder}
        predefinedPlaceId={predefinedPlaceId}
        predefinedTags={predefinedTags}
      />
      <PhotoForm photo={openedPhoto} onClose={() => setOpenedPhoto(null)} />
    </Modal>
  )
}

export default GalleryDialog
