import React from 'react'
import { usePlaceDetail } from '../../../hooks/usePlaceDetail'
import CityForm from './CityForm/CityForm'

const NewCityForm: React.FC = () => {
  const { placeDetail, allPlaces, isLoading, refetch } = usePlaceDetail('0')
  return <CityForm lang="pl" placeDetail={placeDetail} allPlaces={allPlaces} refetch={refetch} />
}

export default NewCityForm
