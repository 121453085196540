import { useState } from 'react'

export interface AjaxCheckboxParams {
  item: Record<string, any>
  field: string
  label: string
  callback: Function
}

const AjaxCheckbox = ({ item, field, label, callback }: AjaxCheckboxParams) => {
  const checked = item[field] && item[field] !== '0'
  const [isChecked, setIsChecked] = useState(checked)

  const toggle = () => {
    const newChcked = !isChecked
    setIsChecked(newChcked)
    item[field] = newChcked
    callback({
      id: item.id,
      field: field,
      field_value: newChcked,
    })
  }

  const wrapperStyle = {
    display: 'flex',
    alignItems: 'center',
    minWidth: 140,
    border: '1px solid #d9d9d9',
    padding: '7px 14px',
    marginBottom: 8,
  }

  return (
    <label htmlFor={field + '-' + item.id} style={wrapperStyle}>
      <input id={field + '-' + item.id} type="checkbox" checked={isChecked} onChange={toggle} />
      <span>
        &nbsp;
        {label}
      </span>
    </label>
  )
}

export default AjaxCheckbox
