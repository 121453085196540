import { useAuth0 } from '@auth0/auth0-react'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { authService } from '../../../api/api-auth'
import { apiClient } from '../../../api/apiClient'
import { PATHS } from '../../../constants/routes'
import { mutationHandler } from '../../../utils/mutationHandler'

export const useVerify2fa = ({ onErrorCallback }: { onErrorCallback: () => void }) => {
  const { DASHBOARD, PLACES } = PATHS
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  return useMutation(
    ['verify2fA', getAccessTokenSilently, navigate, onErrorCallback],
    (secret: string) => authService.verify2FA(secret),
    mutationHandler({
      /** refetch the token with the updated appMeta */
      onSuccessCallback: async () => {
        const token = await getAccessTokenSilently({ ignoreCache: true })
        apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
        navigate(`/${DASHBOARD}/${PLACES}`)
      },
      onErrorCallback: () => {
        onErrorCallback()
      },
    }),
  )
}
