import { AxiosInstance } from 'axios'
import { AllSlugs, PlaceDetail, PlaceModel, WhereToStay, PlaceNotesModel } from '../types/place-api'
import { ImageModel } from '../types/image-api'
import { apiClient } from './apiClient'

export type ScrapeHotelPayload = {
  hotel_link: string
  cdn_folder_path: string
  place_id: string | number
}

export interface Continent {
  id: number
  title: string
  place_parent: string
  guid: string
  countries: Country[]
}

export interface Country {
  id: number
  title: string
  place_parent: string
  guid: string
}

export interface GetAllCities {
  id: number
  title: string
}

class PlaceService {
  public URI: string = '/places'
  constructor(private apiClient: AxiosInstance) {}

  public getFiltered = (offset = 0, limit = 20, query = '') => {
    return this.apiClient.get(`${this.URI}/searchForAdmin?offset=${offset}&limit=${limit}&query=${query}`)
  }

  public getAllCountries = () => {
    return this.apiClient.get<Continent[]>(`${this.URI}/all/countries?locale=pl`) //shared with FE
  }

  public getPlaceDetail = (placeId?: string) => {
    return this.apiClient.get(`${this.URI}/detail/ids/${placeId}`)
  }

  public getPlaceDetailByGuid = (guid: string) => {
    return this.apiClient.get(`${this.URI}/detail/guids?guid=${guid}&locale=pl`) //shared with FE
  }

  public searchPlaces = (query: string) => {
    return this.apiClient.get(`${this.URI}/search/${query}`)
  }

  public updatePlaceDetail = (placeId: string | number, data: Partial<PlaceModel>) => {
    return this.apiClient.put(`${this.URI}/${placeId}`, data)
  }
  
  public sendPlaceForTranslation = (placeId: any, data: any) => {
    return this.apiClient.put(`${this.URI}/${placeId}/send-for-translation`, data);
  }
  
  public setPlaceTranslationStatus = (placeId: any, data: any) => {
    return this.apiClient.put(`${this.URI}/${placeId}/set-status`, data);
  }

  public deletePlace = (placeId: string | number) => {
    return this.apiClient.delete(`${this.URI}/${placeId}`)
  }

  public createPlace = (data: PlaceModel) => {
    return this.apiClient.post<PlaceDetail>(`${this.URI}`, data)
  }

  public createGalleryItem = (data: unknown) => {
    return this.apiClient.post(`${this.URI}/gallery`, data)
  }

  public updateGalleryItem = (galleryId: string | number, data: unknown) => {
    return this.apiClient.put(`${this.URI}/gallery/${galleryId}`, data)
  }

  public deleteGalleryItem = (galleryId: string | number) => {
    return this.apiClient.delete(`${this.URI}/gallery/${galleryId}`)
  }

  public createCityContent = (data: unknown) => {
    return this.apiClient.post(`${this.URI}/citycontent`, data)
  }

  public updateCityContent = (id: string | number, data: any) => {
    return this.apiClient.put(`${this.URI}/citycontent/${id}`, data)
  }

  public deleteCityContent = (id: string | number) => {
    return this.apiClient.delete(`${this.URI}/citycontent/${id}`)
  }

  public scrapeHotelDetailFromBookingCom = (data: ScrapeHotelPayload) => {
    return this.apiClient.post(`${this.URI}/hotels/scrape`, data)
  }

  public createHotel = (data: WhereToStay) => {
    return this.apiClient.post(`${this.URI}/hotels`, data)
  }

  public updateHotel = (id: string | number, data: any) => {
    return this.apiClient.put(`${this.URI}/hotels/${id}`, data)
  }

  public deleteHotel = (id: string | number) => {
    return this.apiClient.delete(`${this.URI}/hotels/${id}`)
  }
  public createExternalLink = (data: unknown) => {
    return this.apiClient.post(`${this.URI}/externallinks`, data)
  }

  public updateExternalLink = (id: string, data: unknown) => {
    return this.apiClient.put(`${this.URI}/externallinks/${id}`, data)
  }

  public deleteExternalLink = (id: string) => {
    return this.apiClient.delete(`${this.URI}/externallinks/${id}`)
  }

  public getComments = (placeId: string, offset = 0, limit = 20, query = '') => {
    return this.apiClient.get(`/comments/places/${placeId}?offset=${offset}&limit=${limit}&query=${query}`)
  }

  public saveSEOData = (placeId: string | number, data: unknown) => {
    return this.apiClient.post(`${this.URI}/seo/${placeId}`, data)
  }

  public updatePlaceSeo = (placeId: string, data: unknown) => {
    return this.apiClient.put(`${this.URI}/seo/${placeId}`, data)
  }

  public getAllContinents = () => {
    return this.apiClient.get(`${this.URI}/all/continents`)
  }

  public getAllCities = (country_id: string | number) => {
    return this.apiClient.get<GetAllCities[]>(`${this.URI}/${country_id}/children/city`)
  }

  public createCategoryPage = (data: Partial<PlaceModel>) => {
    return this.apiClient.post(`${this.URI}/category`, data)
  }

  public deleteCategoryPage = (id: string | number) => {
    return this.apiClient.delete(`${this.URI}/category/${id}`)
  }

  public getAllCitiesForPhotoAnalystic = () => {
    return this.apiClient.get(`${this.URI}/get_all_cities`)
  }

  public updatePlaceField = (payload: any) => {
    return this.apiClient.post(`${this.URI}/update_place_stats/${payload.id}`, {
		field: payload?.field,
		field_value: payload?.field_value
	})
  }
  public updatePlaceForPhotoAnalystic = (payload: any) => {
    return this.apiClient.get(`${this.URI}/update_place_stats/${payload.id}/${payload.field}/${payload.field_value}`)
  }

  public getWakacjeStats = () => {
    return this.apiClient.get(`${this.URI}/get_affiliate_stats`)
  }

  public getAllSeo = (offset = 0, limit = 20, query = '') => {
    return this.apiClient.get(`${this.URI}/seo/all?offset=${offset}&limit=${limit}&query=${query}`)
  }

  public getAllPlaces = () => {
    return this.apiClient.get<AllSlugs>(`${this.URI}/allForAdmin`)
  }

  public addNote = (data: Partial<PlaceNotesModel>) => {
    return this.apiClient.put(`${this.URI}/${data.placeId}/notes`, data);
  }

  public getGallery = (id:Number) => {
    return this.apiClient.get<ImageModel[]>(`${this.URI}/${id}/gallery`)
  }
}

export const placeService = new PlaceService(apiClient)
