import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Space, Table } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import AjaxCheckbox from './AjaxCheckbox'
import AjaxSelect from './AjaxSelect'
import EditReviewForm from '../pages/Comments/EditReviewForm'
import { CustomLink } from './CustomLink'
import { commentsService } from '../api/api-comments'

const ActionCell = ({ item, onChange, onClickEditBtn }: any) => {
  const [isRequesting, setIsRequesting] = useState(false)
  const onClickBtn = () => {
    const c = window.confirm('Sure?')
    if (!c) {
      return
    }
    setIsRequesting(true)
    commentsService
      .deleteComment(item.id)
      .then((res: any) => {
        setIsRequesting(false)
        onChange()
      })
      .catch((err: any) => {
        console.log(err)
      })
  }
  return (
    <Space size="middle">
      <Button loading={isRequesting} icon={<EditOutlined />} onClick={onClickEditBtn}>
        {/* Edit */}
      </Button>
      <Button loading={isRequesting} icon={<DeleteOutlined />} onClick={onClickBtn}>
        {/* Delete */}
      </Button>
    </Space>
  )
}

const TagsCell = ({ item, onChange, hasTransition }: any) => {

	const updateAndRefecth = async (payload: any) => {
		const { data } = await commentsService.toggleComment(payload);
		onChange();
	}

	const selectOptions = [
		{
			key: '',
			value: 'Unmoderated',
		},
		{
			key: 'gold',
			value: 'Gold',
		},
		{
			key: 'silver',
			value: 'Silver',
		},
		{
			key: 'bronze',
			value: 'Bronze',
		},
	];

  	return (
		<div>
			<AjaxSelect 
				callback={updateAndRefecth} 
				options={selectOptions}
				item={item} 
				field={'type'}
			/>
			<AjaxCheckbox 
				callback={updateAndRefecth} 
				item={item} 
				field={'free'} 
				label={'Free'} 
			/>
			{item.free && (!hasTransition || item.transition) ? (<>
				<AjaxCheckbox 
					callback={updateAndRefecth} 
					item={item} 
					field={'transition'} 
					label={'Transition'} 
				/>
			</>
			) : ''}
			{item.moderated_by && (<>
				✓ Moderated<br/>
				{moment(new Date(item.moderated_at)).format('HH:mm DD.MM.YYYY')} <br/>
				by {item.moderator?.name}
			</>)}
		</div>
  	)
}

export interface CommentsTableParams {
  isLoading: boolean
  refetch: Function
  onChange: any //todo
  dataSource: any //todo
  tablePaginationOption: any //todo
}

const CommentsTable = ({ isLoading, refetch, dataSource, tablePaginationOption, onChange }: CommentsTableParams) => {
	const [activeComment, setActiveComment] = useState()
	const [hasTransition, setHasTransition] = useState<boolean>(true);

	useEffect(() => {
		const hasOne = dataSource.filter( (element:any) => element.transition );
		setHasTransition(hasOne.length>0);
	}, [dataSource])

	const columns = [
		{
			title: 'Author',
			dataIndex: 'author.name',
			key: 'comment_author',
			render: (text: string, item: any) => (
				<div>
				{item?.author ? (
					<>
					<CustomLink target="_blank" to={`/dashboard/users/${item.author.id}`}>
						{item.author.name}
					</CustomLink>
					</>
				) : (
					''
				)}
				{item.author ? item.author.email : ''}
				<br />
				</div>
			),
		},
		{
			title: 'Del / Edit',
			key: 'action',
			width: '10%',
			render: (text: string, record: any) => (
				<ActionCell
				item={record}
				onChange={refetch}
				onClickEditBtn={() => {
					setActiveComment(record)
				}}
				/>
			),
		},
		{
			title: 'Content',
			key: 'comment_content',
			render: (text: string, item: any) => (
				<div>
				{item.place ? (
					<>
					<CustomLink target="_blank" to={`/dashboard/places/${item.place.id}`}>
						{item.place.title}
						&nbsp; &nbsp;
						{Array.from(Array(item.ratings).keys()).map(() => '*')}
					</CustomLink>
					</>
				) : (
					<>
					{Array.from(Array(item.ratings).keys()).map(() => '*')}
					<br />
					</>
				)}
				{item.comment_content}
				</div>
			),
		},
		{
			title: 'Info',
			key: 'info',
			render: (text: string, item: any) => (
				<div>
					{item.comment_author_IP}
					<br />
					{moment(new Date(item.created_at)).format('YYYY-MM-DD')}
				</div>
			),
		},
		{
			title: 'Action',
			key: 'action',
			width: '10%',
			render: (text: string, record: any) => <TagsCell item={record} onChange={refetch} hasTransition={hasTransition} />,
		},
	]

	return (
		<>
			<Table
				loading={isLoading}
				rowKey="id"
				columns={columns}
				dataSource={dataSource}
				onChange={onChange}
				pagination={{
				defaultPageSize: tablePaginationOption.pageSize,
				showSizeChanger: false,
				total: tablePaginationOption.total,
				}}
			/>
			<EditReviewForm
				data={activeComment}
				isModalVisible={!!activeComment}
				onClose={(updated: boolean) => {
				if (updated) {
					refetch()
				}
				setActiveComment(undefined)
				}}
			/>
		</>
	)
}

export default CommentsTable
