export default Object.freeze({
  baseURL: process.env.REACT_APP_API_HOST,
  fontend: process.env.REACT_APP_WEBSITE,
  imageCDN: process.env.REACT_APP_IMAGE_CDN,
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    dbConnectionId: process.env.REACT_APP_AUTH_DB_CONNECTION_ID,
  },
})
