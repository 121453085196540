import { Button, Card, Col, Form, Input, notification, Row, Select } from 'antd'
import { AxiosError } from 'axios'
import React, { useEffect, useRef, createRef, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { placeService } from '../../../../api/api-place'
import { PlaceDetail, PlaceModel, PlaceTranslation, PlaceTranslationEmptyObject } from '../../../../types/place-api'
import config from '../../../../api/config'
import HTMLEditor from '../../../../components/HTMLEditor'
import SEOCard from '../../../../components/SEOCard'
import { MenuLink, StickyMenu } from '../../../../components/StickyMenu'
import { PATHS } from '../../../../constants/routes'
import { useStickyHeader } from '../../../../hooks/useStickyHeader'
import { permissionsStore } from '../../../../data/permissions'
import { generateUrlFromTitle, getLastSlug } from '../../../../utils'
import CommentSection from '../../CommentSection'
import ExternalLinkSection from '../../ExternalLinkSection'
import GallerySection from '../../GallerySection'
import CityList from './CityList'
import { DEFAULT_CATEGORIES } from './constants'
import CountryCategoryCard from './CountryCategoryCard'
import { CountryCardData, SeoFields } from './types'
import AuthorizedButton from '../../../../components/Auth0/AuthorizedButton'
import { useConfig } from '../../../../hooks/useConfig'
import { useQuery } from '@tanstack/react-query'
import { adminService } from '../../../../api/api-admin'

export type Continents = Continent[]

export interface Continent {
  id: number
  title: string
  place_parent: string
  guid: string
}

interface Props {
  lang: string
  placeDetail: PlaceDetail
  continents: Continents
  refetch: () => void
}

type PlaceParent = {
  disabled?: boolean
  key: string
  label: string
  value: number
}

type SeoData = { googleValues: SeoFields; thumbnail: PlaceDetail['thumbnail'] }

export const saveSeoContent = async (seoData: SeoData, place: PlaceDetail) => {
  await placeService.saveSEOData(place.id, seoData.googleValues)
}

const CountryForm: React.FC<Props> = ({ lang, placeDetail, continents, refetch }) => {
  useStickyHeader()
  const [form] = Form.useForm<PlaceModel>()
  const navigate = useNavigate()
  const editorRef = useRef<any>()
  const secondEditorRef = useRef<any>()
  const seoCardRef = useRef<any>()

  const [hasPermissionToUpdate, hasPermissionToCreate] = permissionsStore
    .getState()
    .hasPermissions(['update:place', 'create:place'])

  const handleUpdatePlaceNotification = {
    onSuccess: (data: unknown) => {
      notification.success({ message: 'Success', description: 'Country data updated successfully' })
      refetch()
    },
    onError: (err: unknown) => {
      notification.open({
        type: 'error',
        message: 'Something went wrong',
        description: `An error occurred trying to update place detail`,
        placement: 'bottomRight',
      })
    },
  }

  function generatePayload() {
    const seoData: { googleValues: SeoFields; thumbnail: PlaceDetail['thumbnail'] } = seoCardRef.current.getSeoDetail()
    const placeParent: PlaceParent = form.getFieldValue('place_parent')
    const placeParentGuid = continents.find((c) => c.id === Number(placeParent))?.guid
    const guid = `${placeParentGuid}${generateUrlFromTitle(form.getFieldValue('title'))}/`

    const payload = {
      ...form.getFieldsValue(),
      place_parent: `${placeParent}`,
      content: editorRef?.current?.getContent(),
      second_page_content: secondEditorRef?.current?.getContent(),
      thumbnail_photo_id: seoData?.thumbnail?.id,
      place_type: 'country',
      guid,
	  lang: lang,
    }

    return { data: payload, seoData }
  }

  const { mutate: updatePlaceMutation, isLoading: isUpdatePlaceLoading } = useMutation(
    ['updatePlaceDetail', form, generatePayload],
    async () => {
      categoryRefs.current?.forEach((element: any) => {
        element.current?.saveDataIfDirty()
      })

      const { data, seoData } = generatePayload()
      const { guid, ...rest } = data

      const { data: updatePlaceResponse } = await placeService.updatePlaceDetail(placeDetail.id, rest)
      await saveSeoContent(seoData, updatePlaceResponse)
    },
    handleUpdatePlaceNotification,
  )

  const { mutate: createPlaceMutation, isLoading: isCreatePlaceLoading } = useMutation(
    ['createPlace', form, continents, generatePayload],
    async () => {
      const { data, seoData } = generatePayload()
      const { data: createPlaceData, status } = await placeService.createPlace(data)
	  
	  console.log( 'status', status );

      if (status !== 200 && status !== 201) {
        throw new Error(`Something went wrong creating place, ${JSON.stringify(createPlaceData)}`)
      }

      return createPlaceData
    },
    {
      onSuccess: (place) => {
        if (place) {
          navigate(`/${PATHS.DASHBOARD}/${PATHS.PLACES}/${place?.id}`)
        }
      },
      onError: (err: AxiosError) => {
        notification.open({
          type: 'error',
          message: 'Something went wrong',
          description: JSON.stringify(err?.response?.data || err),
          placement: 'bottomRight',
        })
      },
    },
  )

  const categoryContent = Object.entries(DEFAULT_CATEGORIES).reduce((acc, [key, value]) => {
    const data = placeDetail.category_pages.find((c) => c.category_id === value.category.id)
    let content = {
      //@ts-ignore
      ...DEFAULT_CATEGORIES[key],
    }
    if (data) {
      //@ts-ignore
      const { id, category_page, category, child_places } = data
      content = {
        ...content,
        id,
        category,
        category_page,
        child_places,
      }
    }
    return [...acc, content]
  }, [] as CountryCardData[]) as CountryCardData[]

  const categoryRefs = useRef([])
  categoryRefs.current = Object.keys(categoryContent).map((categoryKey, i) => categoryRefs.current[i] ?? createRef())

  useEffect(() => {
    if (placeDetail) {
      form.setFieldsValue(placeDetail)
    }
  }, [placeDetail, form])

  const imageSearchRequestParams = placeDetail?.guid
    ? { searchQuery: `name: ${getLastSlug(placeDetail.guid)}` }
    : undefined


  const [menuLinks, setMenuLinks] = useState<MenuLink[]>([]);
  const [translatedContent, setTranslatedContent] = useState<PlaceTranslation | undefined>(placeDetail?.translations ? placeDetail.translations[0] : PlaceTranslationEmptyObject())
  useEffect(() => {
    if (placeDetail) {
		let translated;
		if(placeDetail?.translations) {
			translated = placeDetail.translations.find(element => element.locale === lang);
		}
		if(!translated) {
			translated = PlaceTranslationEmptyObject()
		}
      	setTranslatedContent( translated )
		form.setFieldsValue( {
			...placeDetail,
			...translated,
		})
    }

	if(lang==='pl') {			
		setMenuLinks([
			{ href: '#main-content', title: 'Place Info' },
			{ href: '#city-list-section', title: 'City List' },
			{ href: '#gallery-section', title: 'Gallery' },
			{ href: '#category-content', title: 'Category Contents' },
			{ href: '#external-link-section', title: 'External Links' },
			{ href: '#seo-section', title: 'SEO' },
			{ href: '#comments-section', title: 'Reviews' },
			{ href: '#notes-section', title: 'Notes' },
			{ href: '#translate-content', title: 'Translation management' },
		])
	} else {	
		setMenuLinks([
			{ href: '#main-content', title: 'Place Info' },
			{ href: '#city-list-section', title: 'City List' },
			{ href: '#category-content', title: 'Category Contents' },
			{ href: '#seo-section', title: 'SEO' },
			{ href: '#notes-section', title: 'Notes' },
			{ href: '#translate-content', title: 'Translation status' },
		])
		
	}
  }, [lang, placeDetail])
	
  const { data: configData, isLoading: isConfigLoading, refetch: refecthConfig } = useConfig()

	const [hasPermissionToReadAdmins] = permissionsStore
		.getState()
		.hasPermissions(['read:admin'])
	const {
		data: adminsListRaw,
	} = useQuery(['getAllAdmins'], async () => {
		if(!hasPermissionToReadAdmins) {
			return [];
		}
		const { data } = await adminService.getAllAdmins()
		return data
	})

	interface SelectOption {
		label: string
		value: string|number
	}
	const [adminsList, setAdminsList] = useState<SelectOption[]>([{
		value: '',
		label: 'None'
	}])
	useEffect( () => {
		const list:SelectOption[] = [{
			value: '',
			label: 'None'
		}];
		if( adminsListRaw && adminsListRaw.length ) {
			adminsListRaw.map( (admin) => { 
				if( admin?.app_metadata?.database_admin_id ) {
					list.push({
						value: admin?.app_metadata?.database_admin_id,
						label: admin.name + ' ('+admin.email+')'
					}) 
				}
			} )
		}
		setAdminsList(list);
	}, [adminsListRaw] )
  


  return (
    <>
      <Row gutter={16}>
        <Col span={20}>
          <Card
            id="main-content"
			title={translatedContent?.title ? translatedContent?.title : (placeDetail?.title ?? 'Add new country')}
            extra={
              placeDetail.id > 0 ? (
                <>
                  <Button onClick={() => window.open(`${config.fontend}${placeDetail.guid}`, '_blank')}>
                    Visit Page
                  </Button>
                  <AuthorizedButton
                    hasPermission={hasPermissionToUpdate}
                    loading={isUpdatePlaceLoading}
                    onClick={() => updatePlaceMutation()}
                    style={{ marginLeft: 16 }}
                    type="primary"
                  >
                    Update
                  </AuthorizedButton>
                </>
              ) : (
                <>
                  <AuthorizedButton
                    hasPermission={hasPermissionToUpdate}
                    loading={isUpdatePlaceLoading}
                    onClick={() => updatePlaceMutation()}
                  >
                    Save
                  </AuthorizedButton>
                  <AuthorizedButton
                    hasPermission={hasPermissionToCreate}
                    loading={isCreatePlaceLoading}
                    onClick={() => createPlaceMutation()}
                    type="primary"
                    style={{ marginLeft: 16 }}
                  >
                    Create
                  </AuthorizedButton>
                </>
              )
            }
          >
            <Form form={form} style={{ marginTop: 20 }}>
              Guid:
              <Form.Item name="guid">
                <Input disabled defaultValue={placeDetail?.guid} />
              </Form.Item>
              {placeDetail.id > 0 && (
                <>
                  Status:
                  <Form.Item name="status" rules={[{ required: true, message: 'required!' }]}>
                    <Select
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      style={{ width: '100%' }}
                      defaultValue={placeDetail.status}
                    >
                      <Select.Option value="active">Active</Select.Option>
                      <Select.Option value="draft">Draft</Select.Option>
                    </Select>
                  </Form.Item>
                </>
              )}
              {placeDetail && placeDetail?.id > 0 && lang !== 'pl' && (
                <>
                  Translation Status:
                  <Form.Item name="translation_status" rules={[{ required: true, message: 'required!' }]}>
                    <Select
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      style={{ width: '100%' }}
                      defaultValue={translatedContent?.translation_status}
                    >
							
					{configData?.translationStatuses.map((status) => (
						<Select.Option key={'status-'+status.key} value={`${status.key}`}>
							{status.name}
						</Select.Option>
						))}
                    </Select>
                  </Form.Item>
                </>
              )}
              Place title:
              <Form.Item name="title" rules={[{ required: true, message: 'required!' }]}>
                <Input defaultValue={translatedContent?.title} />
              </Form.Item>
              Search Label:
              <Form.Item name="search_label" rules={[{ required: true, message: 'required!' }]}>
                <Input defaultValue={translatedContent?.search_label} />
              </Form.Item>
              Parent Place:
              <Form.Item name="place_parent" rules={[{ required: true, message: 'required!' }]}>
                {continents?.length > 0 && (
                  <Select
                    showSearch
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    style={{ width: '100%' }}
                    defaultValue={{
                      key: placeDetail.place_parent,
                      label: continents.find((c) => c.id === Number(placeDetail.place_parent))?.title,
                    }}
                  >
                    {continents.map((continent) => (
                      <Select.Option key={continent.title} value={continent.id}>
                        {continent.title}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              Longitude:
              <Form.Item name="longitude" rules={[{ required: true, message: 'required!' }]}>
                <Input defaultValue={placeDetail?.longitude} />
              </Form.Item>
              Latitude:
              <Form.Item name="latitude" rules={[{ required: true, message: 'required!' }]}>
                <Input defaultValue={placeDetail?.latitude} />
              </Form.Item>
              Bounds:
              <Form.Item name="bounds" rules={[{ required: true, message: 'required!' }]}>
                <Input defaultValue={placeDetail?.bounds} />
              </Form.Item>
              Page Link Title:
              <Form.Item name="page_link_title">
                <Input defaultValue={translatedContent?.page_link_title} />
              </Form.Item>
              Content:
              <HTMLEditor ref={editorRef} html={translatedContent?.content} requestParams={imageSearchRequestParams} />
              <br />
              Second Content:
              <HTMLEditor
                ref={secondEditorRef}
                html={translatedContent?.second_page_content}
                requestParams={imageSearchRequestParams}
              />
              	<br />
              	Author (Google's E-E-A-T):
				
				{hasPermissionToReadAdmins ? (
					<Form.Item name="admin_id">
						<Select options={adminsList}>
						</Select>
					</Form.Item>
				) : (
					<>
						{placeDetail?.admin ? placeDetail?.admin?.name + ' ('+placeDetail.admin.email+')' : 'None'}
					</>
				)}
            </Form>
          </Card>
          <br />

          <div id="city-list-section">
            {placeDetail?.children ? (
              <CityList countryId={placeDetail.id} cities={placeDetail.children} />
            ) : (
              <Card title={'City List'}>
                <h1>Available after place's basic detail is ready.</h1>
              </Card>
            )}
          </div>
          <br />
		  
		  { lang === 'pl' && placeDetail?.id && (
			<>
				<GallerySection id="gallery-section" placeDetail={placeDetail} refetch={refetch} />
				<br />
			</>
		  )}

          <div id="category-content">
            {categoryContent.length > 0 ? (
              categoryContent.map((page, i) => (
                <CountryCategoryCard
                  key={i}
                  parentPlace={placeDetail}
                  data={page}
                  countryId={placeDetail.id}
                  cities={placeDetail.children}
                  refetch={refetch}
                  ref={categoryRefs.current[i]}
                  lang={lang}
                />
              ))
            ) : (
              <Card title={'Category Contents'}>
                <h1>Available after place's basic detail is ready.</h1>
              </Card>
            )}
          </div>
          <br />

		  
		  { lang === 'pl' && placeDetail?.id && (
			<>
          		<ExternalLinkSection id="external-link-section" placeDetail={placeDetail} />
				<br />
			</>
		  )}
          
		  <SEOCard lang={lang} placeDetail={placeDetail} id="seo-section" ref={seoCardRef} />
          <br />

		  { lang === 'pl' && placeDetail?.id && (
			<>
          		<CommentSection id="comments-section" placeId={placeDetail?.id} countryId={placeDetail?.id.toString()} />
				<br />
			</>
		  )}
        </Col>
        <Col span={4}>
          <StickyMenu menuLinks={menuLinks} />
        </Col>
      </Row>
    </>
  )
}

export default CountryForm
