import { Card, Col, Row } from 'antd'
import { CustomLink } from '../../../../components/CustomLink'
import { PATHS } from '../../../../constants/routes'
import { PlaceDetail } from '../../../../types/place-api'

interface Props {
  id?: string
  countryId: any
  cities: PlaceDetail[]
}

const CityList: React.FC<Props> = ({ id, cities }) => {

	const sortedCities = cities.sort((a,b) => a.title < b.title ? -1 : 1);
	
	const colCount = 6
	const divider = Math.floor(sortedCities.length / colCount)
	const remainder = sortedCities.length % colCount
	const cols = new Array(colCount)
	const colCounts = new Array(colCount)
	const thresholds = new Array(colCount)
	for (let i = 0; i < colCount; i++) {
		cols[i] = []
		thresholds[i] = (i ? thresholds[i - 1] : 0) + divider + (i < remainder ? 1 : 0)
		colCounts[i] = 0;
	}
	sortedCities?.forEach((link: any, index: number) => {
		let j
		for (j = 0; j < thresholds.length; j++) {
			if (index < thresholds[j]) {
				break
			}
		}
		cols[j].push(link)
		for(let i=(j+1);i<=colCount;i++) {
			colCounts[i]++;
		}
	})

	return ( (cities && cities.length>0) ? (
		<Card id={id} title="Cities">
			<Row gutter={[16, 16]}>
				{cols.map((cities, index) => (
				<Col span={4} key={index}>
					{cities.map((child:any, subindex:number) => (
						<div className="cityRow">
						<span>{ colCounts[index] + subindex + 1}.&ensp;</span>
						<CustomLink
							onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
							to={`/${PATHS.DASHBOARD}/${PATHS.PLACES}/${child.id}`}
						>
							{child.title}
						</CustomLink>
						</div>
					))}
				</Col>
				))}
			</Row>
		</Card>
	) : (<></>)
	)
}

export default CityList
