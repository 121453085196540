import axios from 'axios'
import config from './config'

export const apiClient = axios.create({
  baseURL: config.baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})
