import { useAuth0 } from '@auth0/auth0-react'
import { Button, Col, Row } from 'antd'
import React from 'react'
import { Outlet } from 'react-router-dom'
import useRedirectIfAuthenticated from '../hooks/auth/useRedirectIfAuthenticated'

const flexCenter = {
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const MFALayout: React.FC = () => {
  const { logout } = useAuth0()
  useRedirectIfAuthenticated()

  return (
    <Row>
      <Col span={16}>
        <div style={flexCenter}>
          <div style={{ display: 'grid', gap: '1.25rem', justifyItems: 'center' }}>
            <Outlet />
          </div>
        </div>
      </Col>
      <Col span={8}>
        <div
          style={{
            background: '#001529',
            ...flexCenter,
          }}
        >
          <div
            className="logo"
            style={{ display: 'grid', gap: '2rem', gridTemplateColumns: '1fr', justifyItems: 'center' }}
          >
            <a href="https://travelbay.pl">
              <img  src="/images/logo-white.svg" alt="Travelbay logo" />
            </a>
            <Button type="primary" style={{ width: 'fit-content' }} onClick={() => logout()}>
              Logout
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default MFALayout
