import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AdminWithRolesAndPermissions, adminService } from '../../../api/api-admin'

const useUpdateAdminRole = (admin: AdminWithRolesAndPermissions, onSuccessCallback: () => void) => {
  const queryClient = useQueryClient()
  return useMutation(
    ['updateAdminRole', admin.user_id],
    async (value: string) => {
      const existingRoles = (admin?.roles || []).map((role) => role.id)
      return await adminService.updateAdminRole(admin.user_id, { role: value, existingRoles })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getAllAdmins'])
        queryClient.invalidateQueries(['getAdminDetail', admin.user_id])
        onSuccessCallback()
      },
    },
  )
}

export default useUpdateAdminRole
