import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Card, Form, FormInstance, Input, InputNumber, notification, Space, Table, Tooltip } from 'antd'
import { AxiosError } from 'axios'
import React, { useState } from 'react'
import { regionService } from '../../api/api-region'
import AuthorizedButton from '../../components/Auth0/AuthorizedButton'
import { Modal } from '../../components/Modal'
import { permissionsStore } from '../../data/permissions'

interface Item {
  id: string
  source_url: string
  taret_url: number
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean
  dataIndex: string
  title: any
  inputType: 'number' | 'text'
  record: Item
  index: number
  children: React.ReactNode
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const InputNode = inputType === 'number' ? InputNumber : Input

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[{ required: true, message: `Please Input ${title}!` }]}
        >
          <InputNode />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

type ActionCellProps = {
  item: { id: string }
  editingId: string
  onChange: () => void
  onEditing: (id: string) => void
  form: FormInstance<any>
}

const ActionCell: React.FC<ActionCellProps> = ({ item, editingId, onChange, onEditing, form }) => {
  const [hasPermissionToUpdate, hasPermissionToDelete] = permissionsStore
    .getState()
    .hasPermissions(['update:region', 'delete:region'])

  const { mutate: deleteRegionMutation, isLoading: isDeleteLoading } = useMutation(
    ['deleteRegion', item.id],
    async () => await regionService.deleteRegion(item.id),
    { onSuccess: (res) => onChange() },
  )

  const { mutate: updateRegionMutation, isLoading: isUpdateLoading } = useMutation(
    ['updateRegion', item.id, form],
    async () => await regionService.updateRegion(item.id, form.getFieldsValue()),
    {
      onSuccess: (res) => {
        onEditing('')
      },
      onError: (err: AxiosError) => {
        notification.open({
          type: 'error',
          message: 'Something went wrong',
          description: `An error occurred trying to update region - ${JSON.stringify(err?.response?.data)}`,
          placement: 'bottomRight',
        })
        onEditing('')
      },
    },
  )

  const onClickEditBtn = () => {
    onEditing(item.id)
    form.setFieldsValue(item)
  }

  if (editingId === item.id) {
    return (
      <Space size="middle">
        <AuthorizedButton
          loading={isUpdateLoading}
          hasPermission={hasPermissionToUpdate}
          icon={<CheckCircleOutlined />}
          onClick={() => updateRegionMutation()}
        />
        <Tooltip title="Close">
          <Button icon={<CloseCircleOutlined />} onClick={() => onEditing('')} />
        </Tooltip>
      </Space>
    )
  }

  return (
    <Space size="middle">
      <AuthorizedButton
        hasPermission={hasPermissionToUpdate}
        loading={isUpdateLoading}
        icon={<EditOutlined />}
        onClick={() => onClickEditBtn()}
      />
      <AuthorizedButton
        hasPermission={hasPermissionToDelete}
        loading={isDeleteLoading}
        icon={<DeleteOutlined />}
        onClick={() => deleteRegionMutation()}
      />
    </Space>
  )
}
const layout = { labelCol: { span: 6 }, wrapperCol: { span: 18 } }

const Regions: React.FC = () => {
  const [form] = Form.useForm()
  const [createForm] = Form.useForm()
  const [editingId, setEditingId] = useState('')
  const [isModalVisible, setModalVisible] = useState(false)
  const [query, setQuery] = useState('')

  const [hasPermissionToCreate] = permissionsStore.getState().hasPermissions(['create:region'])

  const [tablePaginationOption, setTablePaginationOption] = useState<{
    total: number
    curPage: number
    pageSize: number
  }>({ total: 0, curPage: 1, pageSize: 10 })

  const {
    data: tableData,
    isLoading,
    refetch,
  } = useQuery(['getAllRegions', query, tablePaginationOption], async () => {
    const { pageSize, curPage } = tablePaginationOption
    const { data } = await regionService.getAllRegions(pageSize * (curPage - 1), pageSize, query)
    setTablePaginationOption({ curPage, pageSize, total: data.total })
    return data?.data
  })

  const columns = [
    {
      title: 'Region Title',
      dataIndex: 'region_title',
      editable: true,
    },
    {
      title: 'Region Name',
      dataIndex: 'region_name',
      editable: true,
    },
    {
      title: 'Bounds',
      dataIndex: 'bounds',
      editable: true,
    },
    {
      title: '',
      key: 'action',
      width: '20%',
      render: (text: string, record: any) => (
        <ActionCell
          form={form}
          item={record}
          editingId={editingId}
          onChange={() => refetch()}
          onEditing={(val: string) => setEditingId(val)}
        />
      ),
    },
  ]

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: record.id === editingId,
      }),
    }
  })

  const { mutate: createRegionMutation, isLoading: isCreateRegionLoading } = useMutation(
    ['createRegion'],
    async () => await regionService.createRegion(createForm.getFieldsValue()),
    {
      onSuccess: (res) => {
        refetch()
        setModalVisible(false)
        createForm.resetFields()
      },
    },
  )

  return (
    <>
      <Card
        title="Regions"
        extra={
          <div
            style={{
              display: 'grid',
              gap: 16,
              gridTemplateColumns: '1fr min-content',
              width: 'fit-content',
              justifyItems: 'end',
            }}
          >
            <Input.Search
              style={{ width: 200 }}
              onPressEnter={(e) => setQuery(e.currentTarget.value)}
              onSearch={() => refetch()}
              placeholder="Search for a region"
            />
            <Button style={{ width: 'fit-content' }} type="primary" onClick={() => setModalVisible(true)}>
              Add more
            </Button>
          </div>
        }
      >
        <Form form={form} component={false}>
          <Table
            rowKey="id"
            loading={isLoading}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            columns={mergedColumns}
            dataSource={tableData}
            onChange={(pagination) =>
              setTablePaginationOption({ ...tablePaginationOption, curPage: pagination.current ?? 0 })
            }
            pagination={{
              defaultPageSize: tablePaginationOption.pageSize,
              showSizeChanger: false,
              total: tablePaginationOption.total,
            }}
          />
        </Form>
      </Card>

      <Modal
        title="New"
        visible={isModalVisible}
        onOk={() => hasPermissionToCreate && createRegionMutation()}
        onCancel={() => setModalVisible(false)}
        footer={[
          <AuthorizedButton
            key="submit"
            type="primary"
            hasPermission={hasPermissionToCreate}
            loading={isCreateRegionLoading}
            onClick={() => createRegionMutation()}
          >
            Ok
          </AuthorizedButton>,
        ]}
      >
        <Form form={createForm} {...layout}>
          <Form.Item
            label="Region Name"
            name="region_name"
            tooltip={{ title: 'Region Name', icon: <InfoCircleOutlined /> }}
            required
            rules={[{ required: true, message: 'required!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Region Title"
            name="region_title"
            tooltip={{ title: 'Region Title', icon: <InfoCircleOutlined /> }}
            required
            rules={[{ required: true, message: 'required!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Bounds"
            name="bounds"
            tooltip={{ title: 'Bounds', icon: <InfoCircleOutlined /> }}
            required
            rules={[{ required: true, message: 'required!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default Regions
