import { Table } from 'antd'
import moment from 'moment'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { placeService } from '../../../api/api-place'
import { PATHS } from '../../../constants/routes'

const AjaxCheckbox = ({ item, field }: any) => {
  const checked = item[field] && item[field] != '0'
  const [isChecked, setIsChecked] = useState(checked)

  const toggle = () => {
    const newChcked = !isChecked
    setIsChecked(newChcked)
    item[field] = newChcked ? moment() : null
    placeService.updatePlaceForPhotoAnalystic({
      id: item.id,
      field: field,
      field_value: newChcked,
    })
  }

  const wrapperStyle = {
    display: 'flex',
    alignItems: 'center',
  }

  return (
    <div style={wrapperStyle}>
      <input type="checkbox" checked={isChecked} onChange={toggle} />
      <span>
        &nbsp;
        {isChecked ? moment(item[field]).format('DD.MM.YYYY hh:mm') : ''}
      </span>
    </div>
  )
}

const AjaxCounter = ({ item, field }: any) => {
  const val = isNaN(parseInt(item[field])) ? 0 : parseInt(item[field])
  const [inputVal, setInputVal] = useState(val)
  const [debounceFunc, setDebounceFunc] = useState<any>(null)

  const wrapperStyle = {
    display: 'flex',
    alignItems: 'center',
  }
  const plusMinusStyle = {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #1890ff',
    borderRadius: '50%',
  }
  const inputStyle = {
    width: 70,
    height: 24,
    border: '1px solid #1890ff',
    borderRadius: 12,
    outline: 'none',
    margin: '0 5px',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    padding: '0px 10px',
  }

  const castToNumber = (val: any) => {
    return isNaN(val) ? 0 : val
  }

  const saveVal = (newVal: number) => {
    clearTimeout(debounceFunc)
    setDebounceFunc(
      setTimeout(() => {
        placeService.updatePlaceForPhotoAnalystic({
          id: item.id,
          field: field,
          field_value: newVal,
        })
      }, 300),
    )
  }

  const incrementVal = (incrementor: number) => {
    const curVal = inputVal
    const newVal = Math.max(0, castToNumber(curVal) + incrementor)
    setInputVal(newVal)
    saveVal(newVal)
  }

  const changeVal = (e: any) => {
    const newVal = Math.max(0, castToNumber(e.target.value))
    setInputVal(newVal)
    saveVal(newVal)
  }

  return (
    <div style={wrapperStyle}>
      <button style={plusMinusStyle} onClick={() => incrementVal(-1)}>
        -
      </button>
      <input style={inputStyle} type="text" value={inputVal} place-id={item.id} onChange={changeVal} />
      <button style={plusMinusStyle} onClick={() => incrementVal(1)}>
        +
      </button>
    </div>
  )
}

const AnalysticPhotos = () => {
  const columns: any = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'City',
      dataIndex: 'title',
      key: 'title',
      render: (text: string, item: any) => <a href={`https://travelbay.pl${item.guid}`}>{text}</a>,
    },
    {
      title: 'Weronika',
      dataIndex: 'approved_weronika_at',
      key: 'approved_weronika_at',
      render: (text: string, item: any) => <AjaxCheckbox item={item} field="approved_weronika_at" />,
    },
    {
      title: 'Tomek',
      dataIndex: 'approved_tomek_at',
      key: 'approved_tomek_at',
      render: (text: string, item: any) => <AjaxCheckbox item={item} field="approved_tomek_at" />,
    },
    {
      title: 'Link',
      dataIndex: 'title',
      key: 'link',
      render: (text: string, item: any) => (
        <Link to={`/${PATHS.DASHBOARD}/${PATHS.PLACES}/${item.id}`}>Link to place</Link>
      ),
    },
    {
      title: 'Photos Now',
      dataIndex: 'photos',
      key: 'photos',
      defaultSortOrder: 'ascend',
      sorter: (a: any, b: any) => a.photos - b.photos,
    },
    {
      title: 'Photos Saved',
      dataIndex: 'photos_counter',
      key: 'photos_counter',
      render: (text: string, item: any) => <AjaxCounter item={item} field="photos_counter" />,
    },
  ]

  const { data: tableData, isLoading } = useQuery(['getAllCitiesForPhotoAnalystic'], async () => {
    const { data } = await placeService.getAllCitiesForPhotoAnalystic()
    return data
  })

  return (
    <Table
      rowKey="id"
      loading={isLoading}
      className="table-striped-rows"
      columns={columns}
      dataSource={tableData}
      pagination={false}
    />
  )
}

export default AnalysticPhotos
