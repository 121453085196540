import { Button, ButtonProps } from 'antd'
import React from 'react'
import PermissionsTooltip from './PermissionsTooltip'

type Props = {
  hasPermission: boolean
  onClick: () => void
}

const AuthorizedButton: React.FC<Props & ButtonProps> = ({ hasPermission, onClick, loading, ...rest }) => {
  return (
    <PermissionsTooltip hasPermission={hasPermission}>
      <Button {...rest} disabled={!hasPermission} loading={loading} onClick={() => hasPermission && onClick()} />
    </PermissionsTooltip>
  )
}

export default AuthorizedButton
