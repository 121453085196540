import { Button, Spin, Typography } from 'antd'
import { useEffect, useRef, useState } from 'react'
import CodeInput from 'react-code-input'
import { FcLock } from 'react-icons/fc'
import { MFA_CODE_LENGTH } from '../../constants/auth0'
import { useCreate2fa } from '../../hooks/auth/mutations/useCreate2fa'
import { useGenerateQrCode } from '../../hooks/auth/queries/useGenerateQrCode'
import { useUpdateBearerToken } from '../../hooks/useUpdateBearerToken'

const MFACreate: React.FC = () => {
  const { isLoading } = useUpdateBearerToken()
  const hasSubmitted = useRef(false)
  const [mfaCode, setMfaCode] = useState<string>()
  const [activeStep, setActiveStep] = useState<number>(0)
  const { data, isLoading: isQrCodeLoading } = useGenerateQrCode(isLoading)
  const create2faAuth = useCreate2fa({
    onErrorCallback: () => {
      setMfaCode('')
      hasSubmitted.current = false
    },
  })
  const loading = isQrCodeLoading || isLoading || create2faAuth.isLoading

  const steps = [
    <div key={0} dangerouslySetInnerHTML={{ __html: data?.qr_code ?? '' }} />,
    // @ts-ignore
    <CodeInput
      value={mfaCode}
      onChange={(code) => setMfaCode(code)}
      inputMode="numeric"
      name="mfa"
      fields={MFA_CODE_LENGTH}
    />,
  ]

  useEffect(() => {
    if (
      !hasSubmitted.current &&
      mfaCode &&
      data?.secret &&
      mfaCode?.length === MFA_CODE_LENGTH &&
      create2faAuth.isLoading === false
    ) {
      hasSubmitted.current = true
      create2faAuth.mutateAsync({ token: mfaCode, secret: data.secret })
    }
  }, [mfaCode, create2faAuth, data])

  return (
    <>
      <FcLock size={96} />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Typography.Title level={3}>2FA Create</Typography.Title>
        <Typography.Paragraph style={{ color: '#888888' }}>
          {activeStep === 0
            ? 'Scan the QR code with your Google Authenticator app'
            : 'Enter the 6 digit code from your authenticator app'}
        </Typography.Paragraph>
      </div>
      {loading ? <Spin /> : steps[activeStep]}
      {activeStep === 0 ? (
        <>
          <Button type="primary" onClick={() => setActiveStep(1)}>
            Next
          </Button>
          <div style={{ marginTop: '1.5rem', minHeight: 40 }}>
            <p style={{ fontSize: 10 }}>Download the Google Authenticator app</p>
            <div style={{ fontSize: 10, display: 'flex', justifyContent: 'center' }}>
              <a href="https://apps.apple.com/app/google-authenticator/id388497605">
                <img
                  width={84}
                  style={{ marginTop: 5 }}
                  src="https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/en-us.svg"
                  alt=""
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">
                <img
                  width={100}
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </>
      ) : (
        <Button onClick={() => setActiveStep(0)}>Back</Button>
      )}
    </>
  )
}

export default MFACreate
