import Jimp from 'jimp/es'
import latinize from 'latinize'
import Resizer from 'react-image-file-resizer'

type Transformations = 'hero' | 'small_thumbnail' | 'edit_modal' | 'lightbox' | 'carousel' | 'card' | 'open_graph'
type Transformation = Record<Transformations, string>
export enum CDNFolders {
  ACCOMMODATION = '/accommodation',
  IMAGES = '/images',
  LOCAL_GUIDES = '/local-guides',
}

// Named image transformations config for ImageKit (https://docs.imagekit.io/features/named-transformations)
// Configured in https://imagekit.io/dashboard/settings/named-transforms
export const transformations: Transformation = {
  hero: 'tr=n-hero',
  small_thumbnail: 'tr=n-small_thumbnail',
  edit_modal: 'tr=n-edit_modal',
  lightbox: 'tr=n-lightbox',
  carousel: 'tr=n-carousel',
  card: 'tr=n-card',
  open_graph: 'tr=n-open_graph',
}

export function resize(buffer: Buffer) {
  Jimp.read(buffer, (err, file) => {
    if (err) {
      console.log(err)
    } else {
      return file.quality(80).resize(3000, Jimp.AUTO)
    }
  })
}

export function sanitizeFileOrFolder(value: string) {
  return latinize(value.trim().replace(/\s/g, '-').toLowerCase())
}

export const resizeImage = async (file: any) => {
  let quality = 100
  //4MB image file
  if (file.size > 4000000) {
    quality = 90
  }
  //8MB image file
  if (file.size > 8000000) {
    quality = 85
  }

  // file, // Is the file of the image which will resized.
  // maxWidth, // Is the maxWidth of the resized new image.
  // maxHeight, // Is the maxHeight of the resized new image.
  // compressFormat, // Is the compressFormat of the resized new image.
  // quality, // Is the quality of the resized new image.
  // rotation, // Is the degree of clockwise rotation to apply to uploaded image.
  // responseUriFunc, // Is the callBack function of the resized new image URI.
  // outputType, // Is the output type of the resized new image.
  // minWidth, // Is the minWidth of the resized new image.
  // minHeight // Is the minHeight of the resized new image.
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      3000,
      3000,
      'JPEG',
      quality,
      0,
      (uri) => {
        resolve(uri)
      },
      'blob',
    )
  })
}
