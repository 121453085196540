import latinize from 'latinize'
import { last } from 'lodash'

export const formatPrice = (price: number) => {
  return price.toLocaleString('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 2,
  })
}

export const isValidURL = (string: string) => {
  if (string) {
    // eslint-disable-next-line no-useless-escape
    const res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    )
    return res !== null
  }
  return false
}

/** used to get the place name (url safe and latinized) */
export const getLastSlug = (guid: string) => {
  const slugs = guid.split('/').filter(Boolean)
  return last(slugs)
}

export const generateUrlFromTitle = (title: string) => {
  if (title) {
    return latinize(
      title
        .trim()
        .replace(/\s/g, '-')
        .replace(/[\?\.]+/g, '')
        .toLowerCase(),
    )
  }
  return ''
}
