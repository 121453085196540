import { useQuery } from '@tanstack/react-query'
import { authService } from '../../../api/api-auth'

export const useGenerateQrCode = (isLoading: boolean) => {
  return useQuery(
    ['generateQRCode'],
    async () => {
      const { data } = await authService.generateQRCode()
      /** qr_code is HTML from the server containing the QR code */
      return data
    },
    { enabled: !isLoading },
  )
}
