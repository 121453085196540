import { apiClient } from './apiClient'

/** Needed to make authorized calls to the Laravel + Auth0 APIs. `accessToken` comes from the `useAuth0` hook */
export const setApiClientTokenInterceptor = async (accessToken: string): Promise<void> => {
  apiClient.interceptors.request.use(async (config) => {
    if (accessToken && config.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  })
}

apiClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const redirect = error?.response?.data?.['redirect_url']

    if (error.response.status === 401 && redirect) {
      window.location.replace(redirect)
    }

    return Promise.reject(error)
  },
)
