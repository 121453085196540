import { IdToken, useAuth0 } from '@auth0/auth0-react'
import { useAsync } from 'react-use'
import { Jwt } from '../constants/auth0'

export type IdTokenWithRoles = IdToken & Jwt

export const useTokenClaims = (): { isLoading: boolean; claims: IdTokenWithRoles | undefined } => {
  const { getIdTokenClaims, isLoading } = useAuth0()
  const { loading, value: claims } = useAsync(async () => {
    if (!isLoading) {
      return (await getIdTokenClaims()) as IdTokenWithRoles
    }
  }, [getIdTokenClaims, isLoading])
  return { isLoading: loading, claims }
}
