import React from 'react'
import { Modal as _Modal, ModalProps } from 'antd'

export type Props = {
  children: React.ReactNode
}

export const Modal: React.FC<ModalProps & Props> = ({
  visible,
  onCancel,
  children,
  className,
  width,
  title,
  footer,
  onOk,
  centered,
}) => {
  return (
    <div>
      {visible && (
        <_Modal
          width={width}
          onOk={onOk}
          title={title}
          className={className}
          visible={visible}
          onCancel={onCancel}
          footer={footer}
          centered={centered}
        >
          {children}
        </_Modal>
      )}
    </div>
  )
}
