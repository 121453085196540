import { notification } from 'antd'
import { Navigate } from 'react-router-dom'
import { PATHS } from '../constants/routes'

export type ProtectedRouteProps = {
  predicate: boolean
  outlet: JSX.Element
}

export default function ProtectedRoute({ predicate, outlet }: ProtectedRouteProps) {
  if (predicate) {
    return outlet
  } else {
    notification.destroy()
    notification.open({
      type: 'error',
      message: 'Unauthorized',
      description: "You don't have the correct permissions to view this screen",
    })
    return <Navigate to={{ pathname: PATHS.LOGIN }} />
  }
}
