import { CopyFilled } from '@ant-design/icons'
import { Button, Col, Form, Image, Input, notification, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { imageService } from '../../../api/api-image'
import { permissionsStore } from '../../../data/permissions'
import { transformations } from '../../../imagekit/helpers'
import { ImageModel } from '../../../types/image-api'
import { defaultTags } from '../../Photos/filter'
import { handleMessage } from '../../Photos/PhotoForm'
import { defaultFilterState, FilterState } from '../../Photos/Photos'
import Tags from '../../Photos/Tags'
import { ImageTag, imageTags } from '../PlaceForm/CountryForm/CountryCategoryCard'

interface Props {
  image: ImageModel
  refetch: () => void
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
}

const GalleryItem: React.FC<Props> = ({ image, refetch }) => {
  const [form] = Form.useForm()
  const [filters, setFilters] = useState<FilterState['filters']>(defaultFilterState)
  const [hasPermissionToUpdate, hasPermissionToDelete] = permissionsStore
    .getState()
    .hasPermissions(['update:image', 'delete:image'])

  useEffect(() => {
    form.setFieldsValue({ photo_id: image.id, alt: image.alt, description: image.description, url: image.url })
  }, [form, image])

  useEffect(() => {
    if (Array.isArray(image.tags)) {
      const filteredTags = image.tags.filter((tag) => imageTags.includes(tag as ImageTag))
      setFilters((prevState) => ({ ...prevState, tags: filteredTags }))
    }
  }, [JSON.stringify(image.tags)])

  const { mutate: deleteSelectedPhoto, isLoading: isDeleteLoading } = useMutation(
    [image.id],
    () => imageService.deleteImagesInDB([image.id]),
    handleMessage('delete', () => refetch()),
  )

  const { mutate: updateSelectedPhoto, isLoading: isUpdateLoading } = useMutation(
    [filters.tags, form],
    () => {
      const { alt, description } = form.getFieldsValue() ?? {}
      return imageService.updateImageDetail(image?.id, { alt, description, tags: JSON.stringify(filters.tags) })
    },
    handleMessage('update', () => refetch()),
  )

  function copyToClipboard(id: string) {
    return (
      <Button
        icon={<CopyFilled />}
        onClick={() => {
          navigator.clipboard.writeText(form.getFieldValue(id))
          notification.open({
            type: 'info',
            message: '',
            description: 'Copied!',
          })
        }}
      />
    )
  }

  return (
    <div className="content">
      <Form {...layout} form={form}>
        <Form.Item label="Photo ID" name="photo_id">
          <Input disabled suffix={copyToClipboard('photo_id')} />
        </Form.Item>

        <Form.Item label="URL" name="url">
          <Input disabled suffix={copyToClipboard('url')} />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <Input />
        </Form.Item>

        <Form.Item label="Alt" name="alt">
          <Input />
        </Form.Item>

        <Form.Item label="Tags" name="tags">
          <Tags
            disabled={!hasPermissionToUpdate}
            values={defaultTags.values}
            label="Tags"
            filters={filters}
            setFilters={setFilters}
          />
        </Form.Item>

        <Form.Item label="Preview">
          <Image src={image.url + '?' + transformations.small_thumbnail} preview={{ src: image.url }} />
          <Row style={{ paddingBottom: 24, paddingTop: 24 }} gutter={8}>
            <Col>
              <Button
                disabled={!hasPermissionToUpdate}
                loading={isUpdateLoading}
                onClick={() => hasPermissionToUpdate && updateSelectedPhoto()}
              >
                Save
              </Button>
            </Col>
            <Col>
              <Button
                disabled={!hasPermissionToDelete}
                type="primary"
                danger
                loading={isDeleteLoading}
                onClick={() => hasPermissionToDelete && deleteSelectedPhoto()}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>
  )
}

export default GalleryItem
