import { Tag } from 'antd'
import { Filters } from './filter'
import { FilterState } from './Photos'

const Tags: React.FC<Filters['tags'] & FilterState & { disabled?: boolean }> = ({
  values,
  filters,
  setFilters,
  disabled = false,
}) => {
  const handleChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked ? [...filters.tags, tag] : filters.tags.filter((t) => t !== tag)
    setFilters({ ...filters, tags: nextSelectedTags })
  }

  return (
    <>
      {values.map((tag) => {
        const isChecked = filters?.tags?.indexOf(tag.value) > -1
        return (
          <Tag.CheckableTag
            style={{ border: isChecked ? '1px solid #108ee9' : '1px solid #ccc' }}
            onChange={(checked) => !disabled && handleChange(tag.value, checked)}
            checked={isChecked}
            key={tag.value}
          >
            {tag.label}
          </Tag.CheckableTag>
        )
      })}
    </>
  )
}

export default Tags
