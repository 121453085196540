export enum Auth0Roles {
  ADMIN = 'Admin',
  OWNER = 'Owner',
  EDITOR = 'Editor',
  READ = 'Read',
}

export const Auth0RoleMap = {
  [Auth0Roles.ADMIN]: 'rol_jqUTm85AYxf1c0B5',
  [Auth0Roles.OWNER]: 'rol_hSfuQGq6PqXD79AY',
  [Auth0Roles.EDITOR]: 'rol_BBVAt1jQkms9bEA2',
  [Auth0Roles.READ]: 'rol_9y48gpeAAKrkUH3u',
}

export const MFA_CODE_LENGTH = 6

export const AUTH0_NAMESPACE = 'https://zielonamapa.pl'

export const AUTH0_SCOPES = [
  'create:admin', // Can create admins
  'read:admin', // Can see admins
  'update:admin', // Can update admins
  'delete:admin', // Can delete admins

  // place resources
  'create:place', // Can create places
  'read:place', // Can view places
  'update:place', // Can update places
  'delete:place', // Can delete places

  // comment resources
  'create:comment', // Can create comments
  'read:comment', // Can view comments
  'update:comment', // Can edit comments
  'delete:comment', // Can delete comments

  // region resources
  'create:region', // Can create regions
  'read:region', // Can view regions
  'update:region', // Can edit regions
  'delete:region', // Can delete regions

  // user resources
  'create:user', // Can create website users
  'read:user', // Can view website users
  'update:user', // Can edit website users
  'delete:user', // Can delete website users

  // image resources
  'create:image', // Can create images
  'read:image', // Can view images
  'update:image', // Can edit images
  'delete:image', // Can delete images

  // page resources
  'create:page', // Can create pages
  'read:page', // Can view pages
  'update:page', // Can edit pages
  'delete:page', // Can delete pages

  // redirect resources
  'create:redirect', // Can create redirects
  'read:redirect', // Can view redirects
  'update:redirect', // Can edit redirects
  'delete:redirect', // Can delete redirects

  // auth resources
  'create:auth', // Can create auth resources
  'read:auth', // Can view auth resources
  'update:auth', // Can edit auth resources
  'delete:auth', // Can delete auth resources
] as const

export type Auth0Scope = typeof AUTH0_SCOPES[number]

type AppMeta = {
  admin_panel_2fa: string
  /** current time measured in the number of seconds since the Unix Epoch */
  admin_panel_2fa_last_authenticated: number
}

export type Jwt = {
  permissions?: Auth0Scope[]
  [AUTH0_NAMESPACE]: {
    roles: Auth0Roles[]
    appMeta: AppMeta
  }
}
