import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, notification, Radio, Select, Space, Alert } from 'antd'
import { AxiosError } from 'axios'
import React, { useMemo, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { commentsService } from '../../api/api-comments'
import { GetAllCities, placeService } from '../../api/api-place'
import { userService } from '../../api/api-user'
import { Modal } from '../../components/Modal'
import { permissionsStore } from '../../data/permissions'
import { useStore } from '../../data/store'
import { useCreateNewUser } from '../../hooks/useCreateNewUser'
import { User } from '../../types/user-api'
import AuthorizedButton from '../../components/Auth0/AuthorizedButton'
import debounce from 'lodash/debounce';


const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}
interface Props {
  isModalVisible: boolean
  onClose: (updated: boolean) => void
  placeId?: any
  onSuccess?: Function
}

const CreateReviewForm: React.FC<Props> = ({ isModalVisible, onClose, placeId, onSuccess }) => {
  const { place } = useStore()
  const [allCities, setAllCities] = useState<GetAllCities[]>([])
  const [disablePlacePick, setDisablePlacePick] = useState(false)
  const [authorEmail, setAuthorEmail] = useState<string>('')
  const [form] = Form.useForm()
  const [searchedUsers, setSearchUsers] = useState<User[]>([])
  const [errors, setErrors] = useState<any>({})
  const [lastSearch, setLastSearch] = useState<string>('')
  const [createNewUser, setCreateNewUser] = useState<string>('')
  
  const [hasPermissionToCreateUser, hasPermissionToCreateComment] = permissionsStore
    .getState()
    .hasPermissions(['create:user', 'create:comment'])

  const { data: allCountries } = useQuery(['loadAllCountries'], async () => {
    const { data } = await placeService.getAllCountries()
    return data
  })

  const { mutate: getAllCitiesMutation, isLoading: isGetAllCitiesLoading } = useMutation(
    ['getAllCities'],
    async (countryId: string) => {
      const { data } = await placeService.getAllCities(countryId)
      setAllCities(data)
    },
  )

  const { mutate: createCommentMutation, isLoading: isCreateCommentLoading } = useMutation(
    ['createComment', form, searchedUsers],
    async () => {
	  setErrors([]);

      // Fix me - never mutate values
      const values = { ...form.getFieldsValue() }
	  if( placeId ) {
		values.place_id = placeId;
	  } else {
		  values.place_id = values.country
		  if (values.city) {
			values.place_id = values.city
		  }
	  }
	  if (createNewUser) {
		values.user_to_create = createNewUser;
	  } else if (values.user_id) {
        const authorIndex = searchedUsers.findIndex((ele) => ele.id === values.user_id)
        values.comment_author_IP = searchedUsers[authorIndex].user_ip
      }
      return await commentsService.createComment(values)
    },
    {
      onSuccess: (res) => {
        notification.open({
          type: 'success',
          message: 'Success',
          description: 'The review was created succesfully.',
        })

        form.resetFields()
		onSuccess && onSuccess();
      },
      onError: (err: AxiosError) => {
		const response = err.response?.data as any;
		if( response?.error ) {
			setErrors(response?.error);
		} else {
			notification.open({
			  type: 'error',
			  message: 'Error',
			  description: `Something went (very) wrong!`,
			})
		}
      },
    },
  )

  const { mutate: searchUserMutation, isLoading } = useMutation(['searchUser'], async (value: string) => {
	const val = value.trim();
	setLastSearch(val)
	if( val ) {
		const { data } = await userService.searchUser(val, 10)
		setSearchUsers(data)
	} else {
		setSearchUsers([])
	}
  })

  
	const debounceFetcher = useMemo(() => {
		return debounce(searchUserMutation, 500);
	}, [searchUserMutation]);

  useEffect(() => {
    if (place && place.place_type === 'city') {
      form.setFieldsValue({
        country: place.parent.id,
        city: place.id,
      })
      getAllCitiesMutation(place.parent.id)
      setDisablePlacePick(true)
    } else {
      setDisablePlacePick(false)
    }
  }, [place, form, getAllCitiesMutation])

  return (
    <Modal
      title="Add Review"
      visible={isModalVisible}
      onOk={() => createCommentMutation()}
      onCancel={() => onClose(false)}
      width={1000}
      footer={
        <div
          style={{
            display: 'grid',
            gap: 8,
            gridTemplateColumns: '1fr 1fr',
            width: 'fit-content',
            justifySelf: 'flex-end',
            marginLeft: 'auto',
          }}
        >
          <Button key="back" onClick={() => onClose(true)}>
            Close
          </Button>
          <AuthorizedButton
            hasPermission={hasPermissionToCreateComment}
            key="submit"
            type="primary"
            loading={isCreateCommentLoading}
            onClick={() => createCommentMutation()}
          >
            Ok
          </AuthorizedButton>
        </div>
      }
    >
      <Form form={form} {...layout}>
        <Form.Item
          label="Author"
          name="user_id"
          tooltip={{ title: 'Author', icon: <InfoCircleOutlined /> }}
          required
          rules={[{ required: true, message: 'Author is required!' }]}
        >
			{ createNewUser ? (
				<>
					{createNewUser} (<i>new user</i>) &middot; <a onClick={() => { setCreateNewUser('') }}>Cancel</a>
				</>
			) : (
				<Select
					showSearch
					style={{ width: '100%' }}
					notFoundContent={
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<span>
							{ isLoading ? 'Loading' : (lastSearch ? 'No Results for '+lastSearch : 'Start typing please') }
							
						</span>
						{ !isLoading && lastSearch && (
							<AuthorizedButton
							hasPermission={hasPermissionToCreateUser}
							onClick={() => setCreateNewUser(lastSearch)}
							>
								Create it
							</AuthorizedButton>
						)}
					</div>
					}
					onSearch={debounceFetcher}
					loading={isLoading}
					filterOption={(input, option: any) => {
						console.log( 'filterOption ??' );
					setAuthorEmail(input.toLocaleLowerCase())
					return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}}
				>
					{searchedUsers.map((user) => (
					<Select.Option key={user.id} value={user.id}>
						{`${user.email} (${user.name})`}
					</Select.Option>
					))}
				</Select>
			) }
          
        </Form.Item>

		{!placeId ? (<>
			<Form.Item
				label="Country"
				name="country"
				required
				rules={[{ required: true, message: 'Country is required!' }]}
				tooltip={{ title: 'Country', icon: <InfoCircleOutlined /> }}
			>
				<Select
					disabled={disablePlacePick}
					showSearch
					style={{ width: '100%' }}
					onChange={(val) => {
					form.setFieldsValue({ city: null })
					getAllCitiesMutation(val)
					}}
					filterOption={(input, option: any) => {
					if (option.children) {
						return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
					return false
					}}
				>
					{(allCountries || []).map((country, index) => (
					<Select.OptGroup key={index} label={country.title}>
						{country.countries.map((country) => (
						<Select.Option key={country.id} value={country.id}>
							{country.title}
						</Select.Option>
						))}
					</Select.OptGroup>
					))}
				</Select>
			</Form.Item>

			<Form.Item label="City" name="city" tooltip={{ title: 'City', icon: <InfoCircleOutlined /> }}>
				<Select
					disabled={disablePlacePick}
					showSearch
					loading={isGetAllCitiesLoading}
					style={{ width: '100%' }}
					filterOption={(input, option: any) => {
					if (option.children) {
						return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
					return true
					}}
				>
					{allCities.map((city) => (
					<Select.Option key={city.id} value={city.id}>
						{city.title}
					</Select.Option>
					))}
				</Select>
			</Form.Item>

		</>): (<>
		</>)}
        
        <Form.Item label="Reviews" name="comment_content" required>
          <Input.TextArea autoSize={true} showCount rows={8} />
        </Form.Item>

        <Form.Item label="Rating" name="ratings" required>
          <Radio.Group style={{ justifyContent: 'space-between', flexDirection: 'row', flex: '1' }} buttonStyle="solid">
            <Space size="small">
              <Radio.Button value="1">1</Radio.Button>
              <Radio.Button value="2">2</Radio.Button>
              <Radio.Button value="3">3</Radio.Button>
              <Radio.Button value="4">4</Radio.Button>
              <Radio.Button value="5">5</Radio.Button>
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
      {Object.keys(errors).map((key) => (
        <Alert message="Error" description={errors[key]} type="error" closable style={{ marginBottom: 8 }} />
      ))}

     
    </Modal>
  )
}

export default CreateReviewForm
