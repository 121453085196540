import { useQuery } from '@tanstack/react-query'
import { Card, Input, Tabs } from 'antd'
import React, { useState } from 'react'
import { commentsService } from '../../api/api-comments'
import AuthorizedButton from '../../components/Auth0/AuthorizedButton'
import CommentsTable from '../../components/CommentsTable'
import { permissionsStore } from '../../data/permissions'
import CreateReviewForm from './CreateReviewForm'

const AllComments: React.FC = () => {
  const [isModalVisible, setModalVisible] = useState(false)
  const [currentTab, setCurrentTab] = useState('all')
  const [currentQuery, setCurrentQuery] = useState('')
  const hasPermissionToCreate = permissionsStore.getState().hasPermission('create:comment')

  const [tablePaginationOption, setTablePaginationOption] = useState<{
    total: number
    curPage: number
    pageSize: number
  }>({ total: 0, curPage: 1, pageSize: 10 })

  const {
    data: tableData,
    isLoading,
    refetch,
  } = useQuery(['allComments', currentQuery, currentTab, tablePaginationOption], async () => {
    let query = currentQuery
    const { pageSize, curPage } = tablePaginationOption
    const { query: tabQuery } = tabs.find((t) => t.id === currentTab) || {}
    query += '&' + tabQuery

    const { data } = await commentsService.getAll(pageSize * (curPage - 1), pageSize, query)
    setTablePaginationOption({ curPage, pageSize, total: data.total })
    return data.data
  })

  const tabs = [
    {
      id: 'all',
      title: 'All',
      query: '',
    },
    {
      id: 'fake',
      title: 'Fake',
      query: 'type=fake',
    },
    {
      id: 'real',
      title: 'Real',
      query: 'type=real',
    },
  ]

  return (
    <Card
      title="Reviews"
      extra={
        <div style={{ display: 'flex' }}>
          <Input.Search
            style={{ width: 250 }}
            onPressEnter={(e) => setCurrentQuery(e.currentTarget.value)}
            onSearch={(query) => setCurrentQuery(query)}
            placeholder="Search comment contents"
          />
          <AuthorizedButton
            style={{ marginLeft: 16 }}
            type="primary"
            onClick={() => setModalVisible(true)}
            hasPermission={hasPermissionToCreate}
          >
            Add Comment
          </AuthorizedButton>
        </div>
      }
    >
      <Tabs style={{ marginTop: -24 }} activeKey={currentTab} onChange={(tab) => setCurrentTab(tab)}>
        {tabs.map((tab) => (
          <Tabs.TabPane tab={tab.title} key={tab.id} />
        ))}
      </Tabs>
      <CommentsTable
        refetch={refetch}
        isLoading={isLoading}
        dataSource={tableData ?? []}
        onChange={(pagination: any) =>
          setTablePaginationOption({ ...tablePaginationOption, curPage: pagination.current ?? 0 })
        }
        tablePaginationOption={tablePaginationOption}
      />
      <CreateReviewForm
        isModalVisible={isModalVisible}
        onClose={(updated: boolean) => {
          if (updated) {
            refetch()
          }
          setModalVisible(false)
        }}
      />
    </Card>
  )
}

export default AllComments
