import { CheckCircleFilled } from '@ant-design/icons'
import { Button, Col, Input, Result, Row, Spin, Tabs, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useQueryClient, useInfiniteQuery } from '@tanstack/react-query'
import { useToggle } from 'react-use'
import { UploadForm } from '../../components/GalleryDialog/UploadForm'
import { CDNFolders, transformations } from '../../imagekit/helpers'
import { GetAllPhotosResponse, ListFilesRequestParams } from '../../types/image-api'
import AdvancedSearchPopover from './AdvancedSearchPopover'
import { defaultFilterState, FilterState } from './Photos'
import { imageService } from '../../api/api-image'
import { useInView } from 'react-intersection-observer'
import { permissionsStore } from '../../data/permissions'

type Props = {
  selectedPhotos?: string[]
  shouldRefresh?: boolean
  tab?: string
  cdnFolder?: CDNFolders
  requestParams?: ListFilesRequestParams
  setSelectedPhoto: (photo: GetAllPhotosResponse | null) => void
  handleOpenPhoto: (photo: GetAllPhotosResponse) => void
  onTabChange?: () => void
  onUploadSuccess?: () => void
  fixedFolder?: string | undefined
  predefinedPlaceId?: string
  predefinedTags?: string[] | undefined
}

export const centered = {
  width: '100%',
  height: '100%',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
}

const defaultSearchParams: ListFilesRequestParams = {
  limit: 48,
  offset: 0,
  sort: 'DESC_CREATED',
}

const PhotoGallery: React.FC<Props> = ({
  setSelectedPhoto,
  handleOpenPhoto,
  selectedPhotos,
  shouldRefresh,
  onTabChange,
  tab = '1',
  onUploadSuccess,
  cdnFolder,
  requestParams,
  fixedFolder = undefined,
  predefinedPlaceId = undefined,
  predefinedTags = undefined, 
}) => {
  const queryClient = useQueryClient()
  const { ref, inView } = useInView({ threshold: 0.66 })
  const [activeTab, setActiveTab] = useState(tab)
  const [popoverVisible, togglePopover] = useToggle(false)
  const [params, setParams] = useState<ListFilesRequestParams>({ ...defaultSearchParams, ...requestParams })
  const [filters, setFilters] = useState<FilterState['filters']>(defaultFilterState)
  const [hasPermissionToCreate, hasPermissionToUpdate] = permissionsStore
    .getState()
    .hasPermissions(['create:image', 'update:image'])

  const { data, isLoading, error, isError, isFetchingNextPage, fetchNextPage, refetch } = useInfiniteQuery(
    ['images-list', params],
    async ({ pageParam = 0 }) => {
      const offset = pageParam === 0 ? 0 : pageParam * 48
      const { data } = await imageService.getAll({ ...params, ...filters, offset })
      return {
        results: data.success,
        nextPage: pageParam + 1,
      }
    },
    {
		getNextPageParam: (lastPage, pages) => {
			if (lastPage && lastPage.nextPage < 100) {
			return lastPage.nextPage
			}
		},
    },
  )

  const isEveryPageEmpty = data?.pages?.every((p) => p?.results?.length === 0)

  const search = (query: string) => {
    const searchQuery: ListFilesRequestParams = { ...params, ...filters, query, searchQuery: undefined }
    setParams(searchQuery)
  }

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage])

  useEffect(() => {
    if (shouldRefresh) {
      refetch()
    }
  }, [shouldRefresh, refetch])

	const addNewImageToResultSet = (responseData:any) => {
		queryClient.setQueryData(
			['images-list', params],
			(oldData:any) => {
				let old = { ...oldData }
				if( old?.pages[0].results ) {
					if( responseData.length ) {
						for(let i in responseData) {
							if( responseData[i].success ) {
								const newImage = { ...responseData[i].success };
								console.log('Adding image to the result set: ', newImage)
								old.pages[0].results.unshift(newImage);
							}
						}
					}
				}
				return old
			}
		)
	}

  return (
    <div style={{ height: '70vh', overflow: 'scroll', position: 'relative' }}>
      <Tabs
        onChange={(activeKey) => {
          onTabChange && onTabChange()
          setActiveTab(activeKey)
          togglePopover(false)
        }}
        type="card"
        activeKey={activeTab}
      >
        <Tabs.TabPane tab="Media Library" key="1">
          <Col>
            <Row>
              <Col>
                <Input.Search
                  style={{ width: '400px' }}
                  onPressEnter={(query) => search(query.currentTarget.value)}
                  placeholder="Search place photos"
                  addonAfter={
                    <AdvancedSearchPopover
                      visible={popoverVisible}
                      handleTogglePopover={togglePopover}
                      filters={filters}
                      setFilters={setFilters}
                      setParams={setParams}
                    />
                  }
                />
              </Col>
            </Row>
            <div style={{ height: 'calc(70vh - 100px)', marginTop: '8px', overflowY: 'auto', overflowX: 'hidden' }}>
              {isLoading ? (
                <div style={centered}>
                  <Spin size="large" />
                </div>
              ) : isError ? (
                <div>Something went wrong... {JSON.stringify(error)}</div>
              ) : isEveryPageEmpty ? (
                <div>
                  <p>Couldn't find any results, try resetting the query</p>
                  <Button onClick={() => setParams(defaultSearchParams)}>Reset Query</Button>
                </div>
              ) : data?.pages?.length ? (
                <Row gutter={[8, 8]}>
                  {data?.pages?.map((page) => (
                    <React.Fragment key={page?.nextPage}>
                      {page?.results ? page?.results.map((image) => (
                        <Col key={image.fileId} md={4} sm={6} lg={3} style={{ overflow: 'hidden' }}>
                          <div style={{ position: 'relative' }}>
                            <img
                              style={{ width: '100%' }}
                              src={`${image.url}?${transformations.small_thumbnail}`}
                              onClick={() => setSelectedPhoto(image)}
                              alt={image.name ?? ''}
                            />
                          </div>
                          {hasPermissionToUpdate && (
                            <div className="photos-checkbox-group">
                              <span className="checkbox" onClick={() => handleOpenPhoto(image)}>
                                {selectedPhotos?.includes(image.fileId) && <CheckCircleFilled />}
                              </span>
                            </div>
                          )}
                        </Col>
                      )) : <></>}
                    </React.Fragment>
                  ))}
                  <div ref={ref} style={{ width: '100%', height: '24px' }} />
                  {isFetchingNextPage && (
                    <div style={centered}>
                      <Spin size="large" />
                    </div>
                  )}
                </Row>
              ) : (
                <div style={centered}>
                  <Typography.Title level={3} style={{ color: '#ccc', textAlign: 'center' }}>
                    No results found.
                    <br /> Try a different search query
                  </Typography.Title>
                </div>
              )}
            </div>
          </Col>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Upload files" key="2">
          {hasPermissionToCreate ? (
            <UploadForm
              cdnFolder={cdnFolder}
			  fixedFolder={fixedFolder}
			  predefinedPlaceId={predefinedPlaceId}
			  predefinedTags={predefinedTags}
              onFinishedUpload={(folder: string, responseData: any) => {
                setParams({ ...defaultSearchParams, path: folder })
                setFilters(defaultFilterState)
                setActiveTab('1')
				addNewImageToResultSet(responseData);

                onUploadSuccess && onUploadSuccess()
              }}
            />
          ) : (
            <Result
              status="error"
              title="You are not authorized to create images"
              subTitle="Please talk to the Engineers if you need access."
            />
          )}
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default PhotoGallery
