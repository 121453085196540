import { useState } from 'react'
import { Form, Select } from 'antd'

export interface AjaxSelectOption {
  key: string
  value: string
}
export interface AjaxSelectParams {
  item: Record<string, any>
  field: string
  options: AjaxSelectOption[]
  callback: Function
}

const AjaxSelect = ({ item, field, callback, options }: AjaxSelectParams) => {
  const val = item[field];
  const [selectValue, setSelectValue] = useState(val ? val.toString() : val);

  const toggle = (val:string) => {
    setSelectValue(val)
    item[field] = val
    callback({
      id: item.id,
      field: field,
      field_value: val ? val : 'null',
    })
  }

  const selectStyle = {
    minWidth: 140,
    marginBottom: 8,
  }

  return (
	<Form.Item
		style={selectStyle}
	>
		<Select
			onChange={toggle}
			value={selectValue ?? ''}
		>
			{options.map((option) => (
				<Select.Option key={item.id} value={option.key}>
					{option.value}
				</Select.Option>
			))}
		</Select>
	</Form.Item>
  );
}

export default AjaxSelect
