import { useMutation } from '@tanstack/react-query'
import { AdminWithRolesAndPermissions, adminService } from '../../../api/api-admin'
import { mutationHandler } from '../../../utils/mutationHandler'

export const useResetAdminPassword = (adminDetail?: AdminWithRolesAndPermissions) => {
  return useMutation(
    ['resetPasswordAuth0', adminDetail],
    async () => {
      if (adminDetail?.email) {
        await adminService.resetPasswordEmail(adminDetail.email, 'zielonamapa-admin-panel')
      }
    },
    mutationHandler({ onSuccessMsg: `Reset password email has been sent to ${adminDetail?.email}` }),
  )
}
