import { Navigate } from 'react-router-dom'
import FullPageLoading from '../../components/Auth0/FullPageLoading'
import { PATHS } from '../../constants/routes'
import { useAuth } from '../../hooks/useAuth'

const LoginPage: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth()
  const { UNAUTHORIZED, DASHBOARD, PLACES } = PATHS
  return isLoading ? (
    <FullPageLoading />
  ) : isAuthenticated ? (
    <Navigate replace to={`/${DASHBOARD}/${PLACES}`} />
  ) : (
    <Navigate replace to={`/${UNAUTHORIZED}`} />
  )
}

export default LoginPage
