import { Anchor } from 'antd'

export type MenuLink = { href: string; title: string }
type Props = {
  menuLinks: MenuLink[]
}

export const StickyMenu: React.FC<Props> = ({ menuLinks }) => (
  <Anchor offsetTop={80} onClick={(e) => e.preventDefault()}>
    {menuLinks.map((link) => (
      <Anchor.Link key={link.href} href={link.href} title={link.title} />
    ))}
  </Anchor>
)
