import { AxiosInstance } from 'axios'
import { User } from '../types/user-api'
import { apiClient } from './apiClient'
import { UserNotesModel } from '../types/user-api'


class UserService {
  public URI: string = '/users'
  constructor(private apiClient: AxiosInstance) {}

  public getAll = (offset = 0, limit = 20, query = '') => {
    return this.apiClient.get(`${this.URI}?offset=${offset}&limit=${limit}&query=${query}`)
  }

  public deleteUser = (userId: string | number) => {
    return this.apiClient.delete(`${this.URI}/${userId}`)
  }

  public getAllFakeUsers = () => {
    return this.apiClient.get<User[]>(`${this.URI}/get_all_fake_users`)
  }

  public getUserDetailById = (user_id: string) => {
    return this.apiClient.get(`${this.URI}/ids/${user_id}`)
  }

  public createNewUser = (payload: unknown) => {
    return this.apiClient.post(`${this.URI}/create`, payload)
  }

  public searchUser = (payload: unknown, limit?: unknown) => {
    return this.apiClient.get(`${this.URI}/search?query=${payload}` + (limit ? `&limit=${limit}` : ``)  )
  }

  public verifyUser = (payload: unknown) => {
    return this.apiClient.put(`${this.URI}/verify`, payload)
  }

  public removePremium = (payload: unknown) => {
    return this.apiClient.put(`${this.URI}/remove-premium`, payload)
  }

  public addPremium = (payload: unknown) => {
    return this.apiClient.put(`${this.URI}/add-premium`, payload)
  }

  public addNote = (data: Partial<UserNotesModel>) => {
    return this.apiClient.put(`${this.URI}/${data.userId}/notes`, data);
  }

  public toggleUser = (payload: { id: any; field: any; field_value: any }) => {
    return this.apiClient.get(`${this.URI}/${payload.id}/toggle/${payload.field}/${payload.field_value}`)
  }
}

export const userService = new UserService(apiClient)
