import { useQuery } from '@tanstack/react-query'
import { imageService } from '../api/api-image'

export const useImageDetail = (imageId?: string) => {
  const {
    data: image,
    error,
    isLoading,
    refetch,
  } = useQuery(['imageDetail', imageId], async () => {
    if (imageId) {
      const { data } = await imageService.getImageDetail(imageId)
      return data
    }
  })
  return { image, error, isLoading, refetch }
}
