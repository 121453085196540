import { Button, Card, Col, Form, Input, notification, Row, Select, Checkbox } from 'antd'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useRef, useState, createRef } from 'react'
import { useMutation } from '@tanstack/react-query'
import { placeService } from '../../../../api/api-place'
import config from '../../../../api/config'
import HTMLEditor from '../../../../components/HTMLEditor'
import SEOCard from '../../../../components/SEOCard'
import { MenuLink, StickyMenu } from '../../../../components/StickyMenu'
import { PATHS } from '../../../../constants/routes'
import { useAllRegions } from '../../../../hooks/useAllRegions'
import { AllPlaces } from '../../../../hooks/usePlaceDetail'
import { PlaceDetail, PlaceModel, PlaceTranslation, PlaceTranslationEmptyObject } from '../../../../types/place-api'
import { generateUrlFromTitle, getLastSlug } from '../../../../utils'
import CommentSection from '../../CommentSection'
import ExternalLinkSection from '../../ExternalLinkSection'
import LocalGuideSection from '../../LocalGuideSection/LocalGuideSection'
import GallerySection from '../../GallerySection'
import HotelSection from '../../HotelSection'
import { saveSeoContent } from '../CountryForm/CountryForm'
import CityCategoryCard from './CityCategoryCard'
import { buildCityCategoryData, CategoryKey } from './cityCategoryContents'
import { SeoFields } from '../CountryForm/types'
import { useNavigate } from 'react-router-dom'
import { permissionsStore } from '../../../../data/permissions'
import { AxiosError } from 'axios'
import AuthorizedButton from '../../../../components/Auth0/AuthorizedButton'
import { useStickyHeader } from '../../../../hooks/useStickyHeader'
import { useConfig } from '../../../../hooks/useConfig'
import { useQuery } from '@tanstack/react-query'
import { adminService } from '../../../../api/api-admin'

interface Props {
  lang: string
  placeDetail?: PlaceDetail
  allPlaces?: AllPlaces
  refetch: () => void
}

type SeoData = { googleValues: SeoFields; thumbnail: PlaceDetail['thumbnail'] }

const buttonStyle = { backgroundColor: '#0ab068', color: '#fff', marginLeft: 16 }

const CityForm: React.FC<Props> = ({ lang, placeDetail, allPlaces, refetch }) => {
  useStickyHeader()
  const navigate = useNavigate()
  const editorRef = useRef<any>()
  const secondEditorRef = useRef<any>()
  const seoCardRef = useRef<any>()
  const hotelsCardRef = useRef<any>()
  const localGuideRef = useRef<any>()
  const [form] = Form.useForm<PlaceModel>()
  const [hasPermissionToUpdate, hasPermissionToCreate] = permissionsStore
    .getState()
    .hasPermissions(['update:place', 'create:place'])

  const [isAdding, setIsAdding] = useState<Boolean>(true)
  const [placeParentQuery, setPlaceParentQuery] = useState('')
  const [searchRegionQuery, setSearchRegionQuery] = useState('')
  const { regions, isLoading } = useAllRegions(searchRegionQuery)
  const cityCategoryData = buildCityCategoryData(placeDetail)

  const categoryRefs = useRef([])
  categoryRefs.current = Object.keys(cityCategoryData).map((categoryKey, i) => categoryRefs.current[i] ?? createRef())

  function generatePayload() {
    const seoData: SeoData = seoCardRef?.current?.getSeoDetail()
    const placeParent: string = form.getFieldValue('place_parent')
    const parent = allPlaces?.find((place) => place.id === parseInt(placeParent))
    const guid = `${parent?.guid}${generateUrlFromTitle(form.getFieldValue('title'))}/`

    const payload = {
      ...form.getFieldsValue(),
      place_parent: `${placeParent}`,
      content: editorRef?.current?.getContent(),
      second_page_content: secondEditorRef?.current?.getContent(),
      thumbnail_photo_id: seoData?.thumbnail?.id,
      place_type: 'city',
      guid,
	  lang: lang,
    }

    return { data: payload, seoData }
  }


  const { mutate: updatePlaceMutation, isLoading: isUpdatePlaceLoading } = useMutation(
    ['updatePlaceDetail', form, allPlaces, placeDetail],
    async () => {
      const { data, seoData } = generatePayload() || {}
      const { guid, ...rest } = data || {}

      if (isEmpty(rest) || !placeDetail?.id) {
        console.log({ rest, id: placeDetail?.id })
        throw new Error('malformed payload')
      }

      hotelsCardRef?.current?.saveHotelData()

      localGuideRef?.current?.saveDataIfDirty()

      categoryRefs.current?.forEach((element: any) => {
        element.current?.saveDataIfDirty()
      })

      const placeUpdateResult = await (await placeService.updatePlaceDetail(placeDetail.id, rest)).data
      return await saveSeoContent(seoData, placeUpdateResult)
    },
    {
      onSuccess: (data: unknown) => {
        notification.success({ message: 'Success', description: 'City data updated successfully' })
        refetch()
      },
      onError: (err: unknown) => {
        notification.open({
          type: 'error',
          message: 'Something went wrong',
          description: `An error occurred trying to update place detail`,
          placement: 'bottomRight',
        })
      },
    },
  )

  const { mutate: createPlaceMutation, isLoading: isCreatePlaceLoading } = useMutation(
    ['createPlace', form, generatePayload],
    async () => {
      const { data, seoData } = generatePayload() || {}

      if (isEmpty(data) || !data) {
        return
      }

      const { data: createPlaceData, status } = await placeService.createPlace(data)

	  console.log( 'status', status );

      if (status !== 200 && status !== 201) {
        throw new Error(`Something went wrong, ${JSON.stringify(createPlaceData)}`)
      }

      return createPlaceData
    },
    {
      onSuccess: (place) => {
        if (place) {
          navigate(`/${PATHS.DASHBOARD}/${PATHS.PLACES}/${place?.id}`)
        }
      },
      onError: (err: AxiosError) => {
        notification.open({
          type: 'error',
          message: 'Something went wrong',
          description: JSON.stringify(err?.response?.data || err),
          placement: 'bottomRight',
        })
      },
    },
  )
  

  useEffect(() => {
    if (placeDetail) {
      form.setFieldsValue(placeDetail)
    }
	setIsAdding( placeDetail && placeDetail?.id ? false : true );
  }, [form, placeDetail])

  const [menuLinks, setMenuLinks] = useState<MenuLink[]>([]);

  const [translatedContent, setTranslatedContent] = useState<PlaceTranslation | undefined>(placeDetail?.translations ? placeDetail.translations[0] : PlaceTranslationEmptyObject())
  useEffect(() => {
    if (placeDetail) {
		let translated;
		if(placeDetail?.translations) {
			translated = placeDetail.translations.find(element => element.locale === lang);
		}
		if(!translated) {
			translated = PlaceTranslationEmptyObject()
		}
      	setTranslatedContent( translated )
		form.setFieldsValue( {
			...placeDetail,
			...translated,
		})
    }

	if( isAdding ) {
		setMenuLinks([
			{ href: '#main-content', title: 'Place Info' },
		]);
	} else if(lang==='pl') {			
		setMenuLinks([
			{ href: '#main-content', title: 'Place Info' },
			{ href: '#gallery-section', title: 'Gallery' },
			{ href: '#category-content', title: 'Category Contents' },
			{ href: '#hotels', title: 'Hotels' },
			{ href: '#external-link-section', title: 'External Links' },
			{ href: '#local-guide-section', title: 'Local guide' },
			{ href: '#seo-section', title: 'SEO' },
			{ href: '#comments-section', title: 'Reviews' },
			{ href: '#notes-section', title: 'Notes' },
			{ href: '#translate-content', title: 'Translation management' },
		])
	} else {	
		setMenuLinks([
			{ href: '#main-content', title: 'Place Info' },
			{ href: '#category-content', title: 'Category Contents' },
			{ href: '#hotels', title: 'Hotels' },
			{ href: '#seo-section', title: 'SEO' },
			{ href: '#notes-section', title: 'Notes' },
			{ href: '#translate-content', title: 'Translation status' },
		])
		
	}
  }, [lang, placeDetail, isAdding])

  const imageSearchRequestParams = placeDetail?.guid
    ? { searchQuery: `name: ${getLastSlug(placeDetail.guid)}` }
    : undefined
	
  const { data: configData, isLoading: isConfigLoading, refetch: refecthConfig } = useConfig()

	
	const [hasPermissionToReadAdmins] = permissionsStore
		.getState()
		.hasPermissions(['read:admin'])
	const {
		data: adminsListRaw,
	} = useQuery(['getAllAdmins'], async () => {
		if(!hasPermissionToReadAdmins) {
			return [];
		}
		const { data } = await adminService.getAllAdmins()
		return data
	})

	interface SelectOption {
		label: string
		value: string|number
	}
	const [adminsList, setAdminsList] = useState<SelectOption[]>([{
		value: '',
		label: 'None'
	}])
	useEffect( () => {
		const list:SelectOption[] = [{
			value: '',
			label: 'None'
		}];
		if( adminsListRaw && adminsListRaw.length ) {
			adminsListRaw.map( (admin) => { 
				if( admin?.app_metadata?.database_admin_id ) {
					list.push({
						value: admin?.app_metadata?.database_admin_id,
						label: admin.name + ' ('+admin.email+')'
					}) 
				}
			} )
		}
		setAdminsList(list);
	}, [adminsListRaw] )
  

  return (
    <>
      <Row gutter={16}>
        <Col span={20}>
          <Card
            id="main-content"
			title={ isAdding ? 'Add new city' : (translatedContent?.title ? translatedContent?.title : placeDetail?.title)}
            extra={
              !isAdding ? (
                <>
                  {placeDetail?.status === 'active' && (
					<>
						<Button 
							onClick={() => window.open(`https://premium.travelbay.pl${placeDetail.guid}`, '_blank')}
						>
							Preview Reviews
						</Button>
						
						<Button
                    		style={{ marginLeft: 16 }}
							onClick={() => window.open(`${config.fontend}${placeDetail.guid}`, '_blank')}
						>
							Visit Page
						</Button>
					</>
                  )}
                  <AuthorizedButton
                    style={{ marginLeft: 16 }}
                    hasPermission={hasPermissionToUpdate}
                    loading={isUpdatePlaceLoading}
                    onClick={() => updatePlaceMutation()}
                  >
                    Update
                  </AuthorizedButton>
                </>
              ) : (
                <AuthorizedButton
                  hasPermission={hasPermissionToCreate}
                  loading={isCreatePlaceLoading}
                  onClick={() => createPlaceMutation()}
                  type="primary"
                >
                  Add New
                </AuthorizedButton>
              )
            }
          >
            <Form form={form} style={{ marginTop: 20 }}>
              Guid:
              <Form.Item name="guid">
                <Input disabled defaultValue={translatedContent?.guid} />
              </Form.Item>
              {!isAdding && (
                <>
                  Status:
                  <Form.Item name="status" rules={[{ required: true, message: 'required!' }]}>
                    <Select
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      style={{ width: '100%' }}
                      defaultValue={translatedContent?.status}
                    >
                      <Select.Option value="active">Active</Select.Option>
                      <Select.Option value="draft">Draft</Select.Option>
                    </Select>
                  </Form.Item>
                </>
              )}
              {!isAdding && lang !== 'pl' && (
                <>
                  Translation Status:
                  <Form.Item name="translation_status" rules={[{ required: true, message: 'required!' }]}>
                    <Select
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      style={{ width: '100%' }}
                      defaultValue={translatedContent?.translation_status}
                    >
							
					{configData?.translationStatuses.map((status) => (
						<Select.Option key={'status-'+status.key} value={`${status.key}`}>
							{status.name}
						</Select.Option>
						))}
                    </Select>
                  </Form.Item>
                </>
              )}
              Place title:
              <Form.Item name="title" rules={[{ required: true, message: 'required!' }]}>
                <Input defaultValue={translatedContent?.title} />
              </Form.Item>
              Search Label:
              <Form.Item name="search_label" rules={[{ required: true, message: 'required!' }]}>
                <Input defaultValue={translatedContent?.search_label} />
              </Form.Item>
              Parent Place:
              <Form.Item name="place_parent" rules={[{ required: true, message: 'required!' }]}>
                <Select
                  showSearch
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  style={{ width: '100%' }}
                  onSearch={(value) => setPlaceParentQuery(value)}
                  defaultValue={{
                    key: placeDetail?.place_parent,
                    label: allPlaces?.find((p) => p.id === Number(placeDetail?.place_parent))?.title,
                  }}
                >
                  {allPlaces
                    ?.filter((place) => place.title.includes(placeParentQuery) && place.place_type == 'country')
                    ?.map((place) => (
                      <Select.Option key={place.id} value={`${place.id}`}>
                        {place.title}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

			  {!isAdding ? (
				<>
					Longitude:
					<Form.Item name="longitude" rules={[{ required: true, message: 'required!' }]}>
						<Input defaultValue={placeDetail?.longitude} />
					</Form.Item>
					Latitude:
					<Form.Item name="latitude" rules={[{ required: true, message: 'required!' }]}>
						<Input defaultValue={placeDetail?.latitude} />
					</Form.Item>
				</>
			  ) : ''}
              Region:
              <Form.Item name="region_id" rules={[{ required: true, message: 'required!' }]}>
                <Select
                  showSearch
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  style={{ width: '100%' }}
                  onSearch={(value) => setSearchRegionQuery(value)}
                  loading={isLoading}
                  defaultValue={{ key: placeDetail?.region?.id, label: placeDetail?.region?.region_title }}
                >
                  {regions?.map((region) => (
                    <Select.Option key={region.id} value={`${region.id}`}>
                      {region.region_title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
			  {!isAdding ? (
				<>
					Bounds:
					<Form.Item name="bounds" rules={[{ required: true, message: 'required!' }]}>
						<Input defaultValue={placeDetail?.bounds} />
					</Form.Item>
				</>
			  ) : ''}
              List Label:
              <Form.Item name="list_label" rules={[{ required: true, message: 'required!' }]}>
                <Input.TextArea autoSize={true} defaultValue={translatedContent?.list_label} />
              </Form.Item>
              Content:
              <HTMLEditor ref={editorRef} html={translatedContent?.content} requestParams={imageSearchRequestParams} />
              <br />
              Second Content:
              <HTMLEditor
                ref={secondEditorRef}
                html={translatedContent?.second_page_content}
                requestParams={imageSearchRequestParams}
              />
              <br />
              Disable Wakacje listings:
              <Form.Item valuePropName="checked" name="wakacje_disabled">
                <Checkbox>Hide the Wakacje listings from this place's page</Checkbox>
              </Form.Item>
              	<br />
              	Author (Google's E-E-A-T):
				
				{hasPermissionToReadAdmins ? (
					<Form.Item name="admin_id">
						<Select options={adminsList}>
						</Select>
					</Form.Item>
				) : (
					<>
						{placeDetail?.admin ? placeDetail?.admin?.name + ' ('+placeDetail.admin.email+')' : 'None'}
					</>
				)}
            </Form>
          </Card>
          <br />
		  
		  { lang === 'pl' && !isAdding && (
			<>
				<GallerySection id="gallery-section" placeDetail={placeDetail as PlaceDetail} refetch={refetch} />
				<br />
			</>
		  )}
		  


          {!isAdding && (
			<>
				<div id="category-content">
					{Object.keys(cityCategoryData).map((categoryKey, i) => (
					<CityCategoryCard
						data={cityCategoryData[categoryKey as CategoryKey]}
						categoryKey={categoryKey as CategoryKey}
						key={categoryKey}
						cityId={placeDetail?.id}
						refetch={refetch}
						ref={categoryRefs.current[i]}
						requestParams={imageSearchRequestParams}
					/>
					))}
				</div>
				<br />
				<HotelSection lang={lang} ref={hotelsCardRef} id="hotels" placeDetail={placeDetail} refetch={refetch} />
				<br />
			</>
		  )}

		  { lang === 'pl' && !isAdding && (
			<>
          		<ExternalLinkSection id="external-link-section" placeDetail={placeDetail as PlaceDetail} />
				<br />
          		<LocalGuideSection ref={localGuideRef} id="local-guide-section" placeDetail={placeDetail as PlaceDetail} />
				<br />
			</>
		  )}

          {!isAdding && (
			<>
				<SEOCard lang={lang} placeDetail={placeDetail as PlaceDetail} id="seo-section" ref={seoCardRef} />
				<br />
			</>
		  )}

		  { lang === 'pl' && !isAdding && (
			<>
          		<CommentSection id="comments-section" placeId={placeDetail?.id} />
				<br />
			</>
		  )}
        </Col>
        <Col span={4}>
          <StickyMenu menuLinks={menuLinks} />
        </Col>
      </Row>
    </>
  )
}

export default CityForm
