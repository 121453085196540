import { UpcastWriter } from '@ckeditor/ckeditor5-engine'
import HtmlDataProcessor from '@ckeditor/ckeditor5-engine/src/dataprocessor/htmldataprocessor'
import { StylesProcessor } from '@ckeditor/ckeditor5-engine/src/view/stylesmap'
import { parseHtml } from '@ckeditor/ckeditor5-paste-from-office/src/filters/parse'
import removeBoldWrapper from '@ckeditor/ckeditor5-paste-from-office/src/filters/removeboldwrapper'
import { unwrapParagraphInListItem } from '@ckeditor/ckeditor5-paste-from-office/src/filters/list'

export function pasteFromOffice(text, stylesString) {
  // Remove Pseudo tags
  // text = text.replace(/\r?\n|\r/g, '');
  // text = text.replace(/<!\[if !supportLists\]>.*?<!\[endif\]>/gim, '');

  const parsedPaste = parseHtml(text, new StylesProcessor())
  const documentFragment = parsedPaste.body
  const writer = new UpcastWriter(documentFragment.document)

  console.log('initial', text)

  removeBoldWrapper(documentFragment, writer)
  unwrapParagraphInListItem(documentFragment, writer)

  const htmlDataProcessor = new HtmlDataProcessor()
  let result = htmlDataProcessor.toData(documentFragment)

  console.log('converted', result, documentFragment)
  // console.log('with lists', result);

  // remove attributes ' style="..."'
  // const badAttributes = ['style', 'start'];
  // for (var i=0; i< badAttributes.length; i++) {
  //     const attributeStripper = new RegExp(' ' + badAttributes[i] + '="(.*?)"','gi');
  //     result = result.replace(attributeStripper, '');
  // 	// console.log('replacedText', text);
  //     const attributeStripperSQ = new RegExp(' ' + badAttributes[i] + "='(.*?)'",'gi');
  //     result = result.replace(attributeStripperSQ, '');
  // 	// console.log('replacedText', text);
  // }

  return result
}
