import { Spin } from 'antd'
import React from 'react'

type Props = {
  fixed?: boolean
}

const fixedProps = {
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100vh',
} as const

const FullPageLoading: React.FC<Props> = ({ fixed = true }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        ...(fixed && fixedProps),
      }}
    >
      <Spin />
      <span>Loading</span>
    </div>
  )
}

export default FullPageLoading
