import { Auth0Roles } from '../../constants/auth0'

/** This function returns a boolean and accepts a list of user roles and a list
 * of the roles that are authorized */
export const isRoleAuthorized = ({
  roles,
  authorized,
}: {
  roles?: Auth0Roles[]
  authorized: Auth0Roles[]
}): boolean => {
  return !!roles && roles.some((role) => authorized.includes(role))
}
