import sampleSize from 'lodash/sampleSize'
import shuffle from 'lodash/shuffle'

export const generateStrongPassword = (alpha = 14, numbers = 3, special = 3) => {
  const alphaChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const numberChars = '0123456789'
  const specialChars = '!"£$%^&*()-=+_?'
  const pickedChars = sampleSize(alphaChars, alpha)
    .concat(sampleSize(numberChars, numbers))
    .concat(sampleSize(specialChars, special))
  return shuffle(pickedChars).join('')
}
