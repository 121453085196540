import 'antd/dist/antd.css'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import Auth0ProviderWithHistory from './components/Auth0/Auth0ProviderWithHistory'
import './index.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Toaster } from 'react-hot-toast'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      retry: false,
    },
  },
})
const container = document.getElementById('app') as HTMLElement
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Router>
      <Auth0ProviderWithHistory>
        <QueryClientProvider client={queryClient}>
          <App />
          {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
          <Toaster position="top-center" reverseOrder={false} gutter={8} />
        </QueryClientProvider>
      </Auth0ProviderWithHistory>
    </Router>
  </React.StrictMode>,
)
