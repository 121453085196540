import { DeleteFilled, EditFilled } from '@ant-design/icons'
import { Button } from 'antd'
import GalleryDialog from '../GalleryDialog/GalleryDialog'
import React, { useState } from 'react'
import { PlaceDetail } from '../../types/place-api'
import { transformations } from '../../imagekit/helpers'
import { CDNFolders } from '../../imagekit/helpers'
import { getLastSlug } from '../../utils'

interface Props {
  image?: PlaceDetail['thumbnail']
  placeDetail?: PlaceDetail
  onChangePhoto: (photoId?: string) => void
}

const PhotoPicker: React.FC<Props> = ({ image, placeDetail, onChangePhoto }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const onSelectPhoto = (imageIds: string[]) => {
    setIsDialogOpen(false)
    onChangePhoto(imageIds[0])
  }

  return (
    <div>
      <GalleryDialog
		predefinedPlaceId={placeDetail?.id.toString()}
		fixedFolder={`${CDNFolders.IMAGES}${placeDetail?.guid}`}
        cdnFolder={CDNFolders.IMAGES}
        open={isDialogOpen}
        onSelect={onSelectPhoto}
        onClose={() => setIsDialogOpen(false)}
        requestParams={placeDetail?.guid ? { path: `${CDNFolders.IMAGES}${placeDetail.guid}` } : undefined}
      />
      <div className="imagePreview">
        {image ? (
          <>
            <img src={`${image.url}?${transformations.small_thumbnail}`} alt={image?.alt ?? ''} />
            <div className="actionBar">
              <span>
                <EditFilled onClick={() => setIsDialogOpen(true)} style={{ color: '#fff' }} />
              </span>
              <span>
                <DeleteFilled style={{ color: '#fff' }} onClick={() => onChangePhoto(undefined)} />
              </span>
            </div>
          </>
        ) : (
          <Button type="default" onClick={() => setIsDialogOpen(true)}>
            Add Image
          </Button>
        )}
      </div>
    </div>
  )
}

export default PhotoPicker
