import { useMutation } from '@tanstack/react-query'
import { adminService } from '../../../api/api-admin'
import { mutationHandler } from '../../../utils/mutationHandler'

export const useDeleteAdmin = (adminId: string, onSuccessCallback: () => void) => {
  return useMutation(
    ['deleteAdmin', adminId, onSuccessCallback],
    async () => await adminService.deleteAdmin(adminId),
    mutationHandler({ onSuccessMsg: `${adminId} has been deleted`, onSuccessCallback }),
  )
}
