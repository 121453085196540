import { Cascader, Spin } from 'antd'
import { capitalize, last } from 'lodash'
import { SingleValueType } from 'rc-cascader/lib/Cascader'
import { useQuery } from '@tanstack/react-query'
import { placeService } from '../../api/api-place'
import { useAllCountries } from '../../hooks/useAllCountries'
import { AllCountriesAPI, AllSlugs } from '../../types/place-api'
import { Filters } from './filter'
import { FilterState } from './Photos'

type PlaceCascaderProps = {
  continentData: AllCountriesAPI | undefined
  allSlugs: AllSlugs | undefined
  guid: string
  onChange: (value: SingleValueType) => void
  onClear?: () => void
}

export const PlaceCascader: React.FC<PlaceCascaderProps> = ({ allSlugs, continentData, guid, onChange, onClear }) => {
  const filtered = allSlugs?.filter((place) => place.place_type === 'country' || place.place_type === 'city')
  const options = continentData?.map((continent) => ({
    value: continent.guid,
    label: continent.title,
    children: continent.countries.map((country) => {
      const children = filtered
        ?.filter((place) => place.guid.includes(country.guid) && place.place_type !== 'country')
        .map((place) => ({ value: place.guid, label: place.title }))
      return { value: country.guid, label: country.title, children }
    }),
  }))

  const place = findPlaceByGuid(guid, options)
  const [continent, country, city] = place?.value?.split('/')?.filter(Boolean) ?? []
  const displayName =
    continent && country
      ? `${capitalize(continent)} / ${capitalize(country)} ${city ? `/ ${capitalize(city)}` : ''}`.trim()
      : ('' as any)

  return (
    <Cascader
      placeholder="Select a place"
      value={displayName}
      onClear={onClear}
      style={{ width: 300 }}
      options={options}
      onChange={onChange}
      changeOnSelect
    />
  )
}

type Option = { value: string; label: string }
type OptionWithChildren = Option & { children?: Option[] }

export function findPlaceByGuid(id: string, array?: OptionWithChildren[]) {
  let result: any
  array?.some((o) => (o.value === id && (result = o)) || (result = findPlaceByGuid(id, (o.children as any) || [])))
  return result
}

const Location: React.FC<Filters['location'] & FilterState> = ({ values, filters, setFilters }) => {
  const {
    data: allSlugs,
    isLoading: allSlugsLoading,
    error: allSlugsError,
  } = useQuery(['allSlugs'], async () => {
    const { data } = await placeService.getAllPlaces()
    return data
  })

  const { continentData, isLoading: continentDataLoading, error: continentDataError } = useAllCountries()

  const handleChange = (path: SingleValueType) => {
    const lastPath = last(path) as string
    setFilters({ ...filters, location: lastPath })
  }

  return (
    <div>
      {allSlugsLoading || continentDataLoading ? (
        <Spin />
      ) : continentDataError || allSlugsError ? (
        <div>
          Something went wrong - {JSON.stringify(allSlugsError)}, {JSON.stringify(continentDataError)}
        </div>
      ) : continentData && allSlugs ? (
        <PlaceCascader
          allSlugs={allSlugs}
          continentData={continentData}
          guid={filters.location}
          onChange={handleChange}
        />
      ) : null}
    </div>
  )
}
export default Location
