import { notification } from 'antd'
import { AxiosError } from 'axios'
import { head } from 'lodash'
import { useMutation } from '@tanstack/react-query'
import { userService } from '../api/api-user'

export const useCreateNewUser = (
  email: string,
  onSuccessCallback: (user: { name: string; email: string; id: number }) => void,
) => {
  const { mutate, isLoading } = useMutation(
    ['createNewUser', email, onSuccessCallback],
    async () => {
      const name = email.split('@')[0]
      const { data } = await userService.createNewUser({ name, email })
      return { email, name, data }
    },
    {
      onSuccess: (res) => {
        const { data, name, email } = res
        notification.open({ type: 'success', message: 'Success', description: 'New user created' })
        onSuccessCallback({ name, email, id: data.id })
      },
      onError: (err: AxiosError) => {
        const errors = (err?.response?.data as Record<string, string[]>) ?? {}
        const messages = Object.entries(errors)
          .map(([errorKey, errors]) => `${errorKey}: ${head(errors)}`)
          .join(', ')

        notification.open({
          type: 'error',
          message: 'Error',
          description: messages,
        })
      },
    },
  )

  return { mutate, isLoading }
}
