import { Alert, Button, Card, Form, Input } from 'antd'
import React, { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { userService } from '../../../api/api-user'
import { permissionsStore } from '../../../data/permissions'

const CreateUserForm: React.FC = () => {
  const [form] = Form.useForm()
  const [errors, setErrors] = useState<any>({})
  const navigate = useNavigate()
  const [hasPermissionToCreate] = permissionsStore.getState().hasPermissions(['create:user'])

  const { mutate: createNewUserMutation, isLoading } = useMutation(
    ['createNewUser', form],
    async () => {
      const values = form.getFieldsValue()
      return await userService.createNewUser(values)
    },
    {
      onSuccess: (res) => {
        navigate('/dashboard/users')
      },
      onError: (err: any) => {
        setErrors(err.response.data.error)
      },
    },
  )

  return (
    <Card
      title={<span style={{ fontWeight: 'bold' }}>Add New User</span>}
      extra={
        <Button
          disabled={!hasPermissionToCreate}
          loading={isLoading}
          type="primary"
          onClick={() => hasPermissionToCreate && createNewUserMutation()}
        >
          Add
        </Button>
      }
    >
      {Object.keys(errors).map((key) => (
        <Alert message="Error" description={errors[key]} type="error" closable style={{ marginBottom: 8 }} />
      ))}

      <Form form={form} style={{ marginTop: 20 }} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
        <Form.Item label="Nickname" name="name" rules={[{ required: true, message: 'required!' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'required!' }]}>
          <Input />
        </Form.Item>
      </Form>
    </Card>
  )
}

export default CreateUserForm
