import create from 'zustand'
import { Auth0Scope } from '../constants/auth0'

interface Datastore {
  permissions: Auth0Scope[]
  setPermissions: (permissions: Auth0Scope[]) => void
  hasPermission: (permission: Auth0Scope) => boolean
  hasPermissions: (permissions: Auth0Scope[]) => boolean[]
}

export const permissionsStore = create<Datastore>((set, get) => ({
  permissions: [],
  setPermissions: (permissions) => set({ permissions }),
  hasPermission: (permission: Auth0Scope) => get().permissions.includes(permission),
  hasPermissions: (permissions: Auth0Scope[]) => permissions.map((p) => get().permissions.includes(p)),
}))
