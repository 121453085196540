import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AdminWithRolesAndPermissions, UpdateAdminForm, adminService } from '../../../api/api-admin'
import { mutationHandler } from '../../../utils/mutationHandler'

export const useUpdateOrCreateAdmin = (admin?: AdminWithRolesAndPermissions, onSuccessCallback?: () => void) => {
  const queryClient = useQueryClient()
  return useMutation(
    ['updateAdminDetail', onSuccessCallback],
    async (formValues: UpdateAdminForm & { adminId: string }) => {
      if (formValues?.adminId !== '0') {
        const newRole = formValues.role
        const existingRoles = (admin?.roles || []).map((role) => role.id)
        const updateRolePromise =
          newRole && !existingRoles.includes(newRole)
            ? adminService.updateAdminRole(admin?.user_id, { role: formValues.role, existingRoles })
            : Promise.resolve()

        const promises = [updateRolePromise, adminService.updateAdminDetail(admin?.user_id, formValues)]
        return Promise.all(promises)
      } else {
        await adminService.createNewAdmin(formValues)
      }
    },
    mutationHandler({
      onSuccessCallback: () => {
        queryClient.invalidateQueries(['getAllAdmins'])
        queryClient.invalidateQueries(['getAdminDetail', admin?.user_id])
        onSuccessCallback && onSuccessCallback()
      },
    }),
  )
}
