import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { Button, Card, Form, FormInstance, Input, InputNumber, notification, Space, Table } from 'antd'
import { AxiosError } from 'axios'
import React, { useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { redirectService } from '../../api/api-redirections'
import { Modal } from '../../components/Modal'
import { permissionsStore } from '../../data/permissions'
import AuthorizedButton from '../../components/Auth0/AuthorizedButton'

interface Item {
  id: string
  source_url: string
  taret_url: number
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean
  dataIndex: string
  title: any
  inputType: 'number' | 'text'
  record: Item
  index: number
  children: React.ReactNode
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

type ActionCellProps = {
  item: { id: string }
  editingId: string
  onChange: () => void
  onEditing: (id: string) => void
  form: FormInstance<any>
}

const ActionCell: React.FC<ActionCellProps> = ({ item, editingId, onChange, onEditing, form }) => {
  const [hasPermissionToUpdate, hasPermissionToDelete] = permissionsStore
    .getState()
    .hasPermissions(['update:redirect', 'delete:redirect'])

  const onClickEditBtn = () => {
    onEditing(item.id)
    form.setFieldsValue(item)
  }

  const { mutate: deleteRedirectionMutation, isLoading: isDeleteLoading } = useMutation(
    ['deleteRedirection', item.id],
    async () => await redirectService.deleteRedirection(item.id),
    {
      onSuccess: (res) => onChange(),
      onError: (err: AxiosError) => {
        notification.open({
          type: 'error',
          message: 'Something went wrong',
          description: `An error occurred trying to delete redirection - ${JSON.stringify(err.response?.data)}`,
          placement: 'bottomRight',
        })
      },
    },
  )

  const { mutate: updateRedirectionMutation, isLoading: isUpdateLoading } = useMutation(
    ['updateRedirection', item.id],
    async () => await redirectService.updateRedirection(item.id, form.getFieldsValue()),
    {
      onSuccess: (res) => {
        onEditing('')
        onChange()
      },
      onError: (err: AxiosError) => {
        notification.open({
          type: 'error',
          message: 'Something went wrong',
          description: `An error occurred trying to update redirection - ${JSON.stringify(err.response?.data)}`,
          placement: 'bottomRight',
        })
        onEditing('')
      },
    },
  )

  if (editingId === item.id) {
    return (
      <Space size="middle">
        <Button
          disabled={!hasPermissionToUpdate}
          loading={isUpdateLoading}
          icon={<CheckCircleOutlined />}
          onClick={() => hasPermissionToUpdate && updateRedirectionMutation()}
        />
        <Button icon={<CloseCircleOutlined />} onClick={() => onEditing('')} />
      </Space>
    )
  }

  return (
    <Space size="middle">
      <AuthorizedButton
        hasPermission={hasPermissionToUpdate}
        loading={isUpdateLoading}
        icon={<EditOutlined />}
        onClick={() => onClickEditBtn()}
      />
      <AuthorizedButton
        hasPermission={hasPermissionToDelete}
        loading={isDeleteLoading}
        icon={<DeleteOutlined />}
        onClick={() => deleteRedirectionMutation()}
      />
    </Space>
  )
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

const Redirections = () => {
  const [form] = Form.useForm()
  const [createForm] = Form.useForm()
  const [editingId, setEditingId] = useState('')
  const [isModalVisible, setModalVisible] = useState(false)
  const [hasPermissionToCreate] = permissionsStore.getState().hasPermissions(['create:redirect'])

  const [tablePaginationOption, setTablePaginationOption] = useState<{
    total: number
    curPage: number
    pageSize: number
  }>({ total: 0, curPage: 1, pageSize: 10 })

  const {
    data: tableData,
    isLoading,
    refetch,
  } = useQuery(['getRedirects', tablePaginationOption], async () => {
    const { pageSize, curPage } = tablePaginationOption
    const { data } = await redirectService.getAll(pageSize * (curPage - 1), pageSize, '')
    setTablePaginationOption({ curPage, pageSize, total: data.total })
    return data?.data
  })

  const columns = [
    {
      title: 'Source',
      dataIndex: 'source_url',
      editable: true,
    },
    {
      title: 'Target',
      dataIndex: 'target_url',
      editable: true,
    },
    {
      title: '',
      key: 'action',
      width: '20%',
      render: (text: string, record: any) => (
        <ActionCell
          form={form}
          item={record}
          editingId={editingId}
          onChange={() => refetch()}
          onEditing={(val: string) => {
            setEditingId(val)
          }}
        />
      ),
    },
  ]

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: record.id === editingId,
      }),
    }
  })

  const { mutate: createRedirectionMutation, isLoading: isCreateLoading } = useMutation(
    ['createRedirection', createForm],
    async () => await redirectService.createRedirection(createForm.getFieldsValue()),
    {
      onSuccess: (res) => {
        refetch()
        setModalVisible(false)
        createForm.resetFields()
      },
      onError: (err) => {},
    },
  )

  return (
    <>
      <Card
        title="Redirections"
        extra={
          <div style={{ display: 'flex' }}>
            <AuthorizedButton
              hasPermission={hasPermissionToCreate}
              type="primary"
              style={{ marginLeft: 'auto' }}
              onClick={() => setModalVisible(true)}
            >
              Add more
            </AuthorizedButton>
          </div>
        }
      >
        <Form form={form} component={false}>
          <Table
            rowKey="id"
            loading={isLoading}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            columns={mergedColumns}
            dataSource={tableData}
            onChange={(pagination) =>
              setTablePaginationOption({ ...tablePaginationOption, curPage: pagination.current ?? 0 })
            }
            pagination={{
              defaultPageSize: tablePaginationOption.pageSize,
              showSizeChanger: false,
              total: tablePaginationOption.total,
            }}
          />
        </Form>
      </Card>

      <Modal
        title="New"
        visible={isModalVisible}
        onOk={() => hasPermissionToCreate && createRedirectionMutation()}
        onCancel={() => setModalVisible(false)}
        footer={[
          <AuthorizedButton
            hasPermission={hasPermissionToCreate}
            key="submit"
            type="primary"
            loading={isCreateLoading}
            onClick={() => createRedirectionMutation()}
          >
            Ok
          </AuthorizedButton>,
        ]}
      >
        <Form form={createForm} {...layout}>
          <Form.Item
            label="Source URL"
            name="source_url"
            tooltip={{ title: 'Source URL', icon: <InfoCircleOutlined /> }}
            required
            rules={[{ required: true, message: 'required!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Target URL"
            name="target_url"
            tooltip={{ title: 'Target URL', icon: <InfoCircleOutlined /> }}
            required
            rules={[{ required: true, message: 'required!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default Redirections
