import { useAuth0 } from '@auth0/auth0-react'
import jwt_decode from 'jwt-decode'
import get from 'lodash/get'
import { useNavigate } from 'react-router-dom'
import { useAsync, useToggle } from 'react-use'
import { setApiClientTokenInterceptor } from '../api/interceptors'
import { AUTH0_NAMESPACE, Jwt } from '../constants/auth0'
import { PATHS } from '../constants/routes'
import { permissionsStore } from '../data/permissions'
import { shouldReauthenticate } from '../utils/auth/shouldReauthenticate'

export const useAuth = () => {
  const [isLoading, toggleLoading] = useToggle(true)
  const { setPermissions } = permissionsStore()
  const { isAuthenticated, isLoading: isAuth0Loading, loginWithRedirect, getAccessTokenSilently, error } = useAuth0()
  const navigate = useNavigate()
  const { MFA } = PATHS

  const { loading } = useAsync(async () => {
    if (isAuth0Loading) {
      return
    }

    if (error) {
      toggleLoading(false)
      return navigate(PATHS.UNAUTHORIZED, { replace: true })
    }

    if (isAuthenticated) {
      const token = await getAccessTokenSilently()
      const jwt = jwt_decode<Jwt>(token)
      jwt?.permissions && setPermissions(jwt?.permissions)
      const appMeta = get(jwt, `${AUTH0_NAMESPACE}`)?.appMeta

      // user doesn't have 2fa set. redirect them to setup
      if (!appMeta?.admin_panel_2fa) {
        await navigate(`/${MFA}/create`, { replace: true })
        return toggleLoading(false)
      }

      // if user has 2fa set, but hasn't verified it, redirect them to verify
      if (!appMeta?.admin_panel_2fa_last_authenticated) {
        await navigate(`/${MFA}/challenge`, { replace: true })
        return toggleLoading(false)
      }

      // check if they have authenticated in the last 3 days.
      const isLastAuthenticatedInvalid = shouldReauthenticate(appMeta?.admin_panel_2fa_last_authenticated)

      if (isLastAuthenticatedInvalid) {
        await navigate(`/${MFA}/challenge`, { replace: true })
        return toggleLoading(false)
      }

      await setApiClientTokenInterceptor(token)
      return toggleLoading(false)
    }

    return loginWithRedirect({
		source: 'admin'
	});
  }, [
    isAuthenticated,
    isAuth0Loading,
    navigate,
    loginWithRedirect,
    setPermissions,
    getAccessTokenSilently,
    error,
    toggleLoading,
  ])

  return { isAuthenticated, isLoading: isAuth0Loading || loading || isLoading }
}
