import { AxiosInstance } from 'axios'
import { apiClient } from './apiClient'

class RegionService {
  public URI = '/regions'
  constructor(private apiClient: AxiosInstance) {}

  public getAllRegions = (offset = 0, limit = 20, query = '') => {
    return this.apiClient.get(`${this.URI}?offset=${offset}&limit=${limit}&query=${query}`)
  }

  public createRegion = (data: unknown) => {
    return this.apiClient.post(`${this.URI}`, data)
  }

  public updateRegion = (id: string | number, data: unknown) => {
    return this.apiClient.put(`${this.URI}/${id}`, data)
  }

  public deleteRegion = (id: string | number) => {
    return this.apiClient.delete(`${this.URI}/${id}`)
  }
}

export const regionService = new RegionService(apiClient)
