import { useState } from 'react'
import toast from 'react-hot-toast'

type Copied = string | null
type Copy = (text: string) => Promise<boolean>

export function useCopyToClipboard() {
  const [copiedText, setCopiedText] = useState<Copied>(null)

  const copyToClipboard: Copy = async (text) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported')
      return false
    }

    try {
      await navigator.clipboard.writeText(text)
      setCopiedText(text)
      toast.success('Copied to clipboard')
      return true
    } catch (error) {
      console.warn('Copy failed', error)
      setCopiedText(null)
      return false
    }
  }

  return { copiedText, copyToClipboard }
}
