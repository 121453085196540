import React from 'react'
import { useContinents } from '../../../hooks/useContinents'
import { usePlaceDetail } from '../../../hooks/usePlaceDetail'
import { PlaceDetail } from '../../../types/place-api'
import CountryForm from './CountryForm/CountryForm'

const NewCountryForm: React.FC = () => {
  const placeDetail = {
    where_to_stay: [],
    external_links: [],
    id: 0,
    category_pages: [],
    title: 'Add New Country',
    guid: '',
    seo: {
      title: '',
    },
  } as unknown as PlaceDetail
  const { continents } = useContinents()
  const { refetch } = usePlaceDetail('0')
  return <CountryForm lang="pl" placeDetail={placeDetail} continents={continents} refetch={refetch} />
}

export default NewCountryForm
