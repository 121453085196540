import { Route, Routes } from 'react-router-dom'
import { RequireAuth } from './components/Auth0/RequireAuth'
import ProtectedRoute from './components/ProtectedRoute'
import { Auth0Roles, AUTH0_NAMESPACE } from './constants/auth0'
import { PATHS } from './constants/routes'
import { useTokenClaims } from './hooks/useTokenClaims'
import FourZeroThree from './pages/403'
import FourZeroFour from './pages/404'
import AllAdmins from './pages/Admins'
import AdminForm from './pages/Admins/AdminForm'
import AnalysticPhotos from './pages/Analystic/Photos'
import AllComments from './pages/Comments'
import Dashboard from './pages/dashboard'
import CommentForm from './pages/dashboard/CommentForm'
import LoginPage from './pages/Login/LoginPage'
import Photos from './pages/Photos/Photos'
import { AllPlacesTable } from './pages/Places/AllPlacesTable'
import PlaceForm from './pages/Places/PlaceForm'
import NewCityForm from './pages/Places/PlaceForm/NewCityForm'
import NewCountryFrom from './pages/Places/PlaceForm/NewCountryForm'
import AllRedirections from './pages/Redirections'
import AllRegions from './pages/Regions'
import AllSeos from './pages/Seo'
import AllUsers from './pages/Users'
import CreateUserForm from './pages/Users/CreateUserForm'
import UserForm from './pages/Users/UserForm'
import WakacjeLog from './pages/WakacjeLog'
import MFAChallenge from './pages/MFA/Challenge'
import MFACreate from './pages/MFA/Create'
import { isRoleAuthorized } from './utils/auth/isRoleAuthorized'
import MFALayout from './layouts/MFALayout'
import get from 'lodash/get'

function App() {
  const { claims } = useTokenClaims()
  const { roles } = get(claims, AUTH0_NAMESPACE) || {}

  return (
    <>
      <Routes>
        <Route path={PATHS.UNAUTHORIZED} element={<FourZeroThree />} />
        <Route path="*" element={<FourZeroFour />} />
        <Route index element={<LoginPage />} />
        <Route element={<RequireAuth />}>
          <Route path={PATHS.MFA} element={<MFALayout />}>
            <Route path={'challenge'} element={<MFAChallenge />} />
            <Route path={'create'} element={<MFACreate />} />
          </Route>
          <Route path={PATHS.DASHBOARD} element={<Dashboard />}>
            <Route path={PATHS.PLACES}>
              <Route index element={<AllPlacesTable />} />
              <Route path={':placeId'} element={<PlaceForm />} />
              <Route path="city/new" element={<NewCityForm />} />
              <Route path="country/new" element={<NewCountryFrom />} />
            </Route>

            <Route path={PATHS.COMMENTS}>
              <Route index element={<AllComments />} />
              <Route path={':commentId'} element={<CommentForm />} />
            </Route>

            <Route path={PATHS.USERS}>
              <Route index element={<AllUsers />} />
              <Route path={':userId'} element={<UserForm />} />
              <Route path={'create'} element={<CreateUserForm />} />
            </Route>

            <Route
              path={PATHS.PHOTOS}
              element={
                <ProtectedRoute
                  predicate={isRoleAuthorized({
                    roles,
                    authorized: [Auth0Roles.EDITOR, Auth0Roles.ADMIN, Auth0Roles.OWNER],
                  })}
                  outlet={<Photos />}
                />
              }
            />

            <Route
              path={PATHS.REDIRECTIONS}
              element={
                <ProtectedRoute
                  predicate={isRoleAuthorized({
                    roles,
                    authorized: [Auth0Roles.EDITOR, Auth0Roles.ADMIN, Auth0Roles.OWNER],
                  })}
                  outlet={<AllRedirections />}
                />
              }
            />

            <Route
              path={PATHS.REGIONS}
              element={
                <ProtectedRoute
                  predicate={isRoleAuthorized({
                    roles,
                    authorized: [Auth0Roles.EDITOR, Auth0Roles.ADMIN, Auth0Roles.OWNER],
                  })}
                  outlet={<AllRegions />}
                />
              }
            />

            <Route
              path={PATHS.SEO}
              element={
                <ProtectedRoute
                  predicate={isRoleAuthorized({
                    roles,
                    authorized: [Auth0Roles.EDITOR, Auth0Roles.ADMIN, Auth0Roles.OWNER],
                  })}
                  outlet={<AllSeos />}
                />
              }
            />

            <Route
              path={PATHS.ANALYSTIC_PHOTO}
              element={
                <ProtectedRoute
                  predicate={isRoleAuthorized({
                    roles,
                    authorized: [Auth0Roles.EDITOR, Auth0Roles.ADMIN, Auth0Roles.OWNER],
                  })}
                  outlet={<AnalysticPhotos />}
                />
              }
            />

            <Route
              path={PATHS.WAKACJE_LOG}
              element={
                <ProtectedRoute
                  predicate={isRoleAuthorized({
                    roles,
                    authorized: [Auth0Roles.EDITOR, Auth0Roles.ADMIN, Auth0Roles.OWNER],
                  })}
                  outlet={<WakacjeLog />}
                />
              }
            />

            <Route
              path={PATHS.ANALYSTIC_PHOTO}
              element={
                <ProtectedRoute
                  predicate={isRoleAuthorized({
                    roles,
                    authorized: [Auth0Roles.EDITOR, Auth0Roles.ADMIN, Auth0Roles.OWNER],
                  })}
                  outlet={<AnalysticPhotos />}
                />
              }
            />

            <Route
              path={PATHS.ADMINS}
              element={
                <ProtectedRoute
                  predicate={isRoleAuthorized({
                    roles,
                    authorized: [Auth0Roles.ADMIN, Auth0Roles.OWNER],
                  })}
                  outlet={<AllAdmins />}
                />
              }
            />
            <Route
              path={`${PATHS.ADMINS}/:adminId`}
              element={
                <ProtectedRoute
                  predicate={isRoleAuthorized({
                    roles,
                    authorized: [Auth0Roles.ADMIN, Auth0Roles.OWNER],
                  })}
                  outlet={<AdminForm />}
                />
              }
            />

            <Route path={PATHS.TRANSLATION} element={null} />
          </Route>
        </Route>
      </Routes>
    </>
  )
}

export default App
