import { Col, Row, Card, Spin, Tabs, Form, Input, Button, notification, Table } from 'antd'
import { useParams } from 'react-router-dom'
import { useContinents } from '../../../hooks/useContinents'
import { usePlaceDetail } from '../../../hooks/usePlaceDetail'
import { useConfig } from '../../../hooks/useConfig'
import ChildPlace from './ChildPlace/ChildPlace'
import CityForm from './CityForm/CityForm'
import CountryForm from './CountryForm/CountryForm'
import HomePageForm from './HomePageForm'
import TranslatePlace from './TranslatePlace/TranslatePlace'
import { useState, useEffect } from 'react'
import { placeService } from '../../../api/api-place'
import { PlaceNotesModel } from '../../../types/place-api'
import moment from 'moment'


export type PlaceNoteFormModel = {
  note: string
}


const PlaceForm = () => {
  const params = useParams()
  const placeId = params.placeId

  const { data: config, isLoading: isConfigLoading, refetch: refecthConfig } = useConfig()
  const { placeDetail, allPlaces, isLoading, refetch } = usePlaceDetail(placeId)
  const { continents, continentsLoading } = useContinents()
  const [notesForm] = Form.useForm<PlaceNoteFormModel>()
  const [currentLang, setCurrentLang] = useState('pl')
  const [notes, setNotes] = useState<PlaceNotesModel[] | undefined>(undefined)
  
	useEffect( () => {
		console.log( 'placeDetail changed', placeDetail );
		setNotes(placeDetail?.notes);
	}, [placeDetail] )

	if (isLoading || isConfigLoading || continentsLoading) {
		return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '70vh' }}>
			<Spin />
		</div>
		)
	}


  
	const updateAndRefetch = async () => {
		if( placeDetail?.id ) {
			const payload = {
				placeId: placeDetail?.id.toString(),
				note: notesForm.getFieldsValue().note,
			}
			const { data } = await placeService.addNote(payload);
			notification.open({ type: 'success', message: 'Success', description: 'Note added!' })
			notesForm.setFieldsValue( {
				note: ''
			} );

			if(data.item) {
				let newNotes = [...placeDetail?.notes]
				newNotes.push(data.item);
				setNotes(newNotes);
			}
			refetch();
		}
	}

	
	const columns = [
		{
			title: 'No.',
			key: 'index',
			render: (text:string, record: any, index:number) => (index+1),
		},
		{
			title: 'Note',
			dataIndex: 'note',
			key: 'note',
			render: (text: string, item: any) => (
				<div style={{ whiteSpace: 'pre'}}>
					{item.note}
				</div>
			),
		},
		{
			title: 'Creator',
			key: 'admin_name',
			render: (text: string, item: any) => (
				<div>
					{item.admin.name}
				</div>
			),
		},
		{
			title: 'Date',
			key: 'created_at',
			render: (text: string, item: any) => (
				<div>
				{moment(new Date(item.created_at)).format('DD.MM.YYYY HH:mm')}
				</div>
			),
		},
	]

  

  
  return <>
  
	<Row gutter={16}>
		<Col span={20} >
			<Tabs id="lang-tabs" style={{ background: 'white', margin: '0 0 -16px 0', padding: '0px 16px' }} className="custom-tab" activeKey={currentLang} onChange={(tab) => setCurrentLang(tab)}>
				{config?.langs.map((tab) => (
					<Tabs.TabPane tab={tab.name} key={tab.locale} />
				))}
			</Tabs>
		</Col>
	</Row>

	
  	{ placeDetail?.place_type === 'city' && <CityForm lang={currentLang} placeDetail={placeDetail} allPlaces={allPlaces} refetch={refetch} /> }
  	{ placeDetail?.place_type === 'country' && <CountryForm lang={currentLang} placeDetail={placeDetail} continents={continents} refetch={refetch} /> }
  	{ placeDetail?.place_type === 'homepage' && <HomePageForm lang={currentLang} placeDetail={placeDetail} refetch={refetch} /> }
  	{ placeDetail?.place_type === 'child' && <ChildPlace lang={currentLang} placeDetail={placeDetail} refetch={refetch} /> }

	<Row gutter={16}>
		<Col span={20} >
			<Card 
				id="notes-section"
				title="Notes"
			>

				<Table
        			pagination={false} 
					rowKey="id"
					columns={columns}
					dataSource={notes}
				/>

				<Form form={notesForm} style={{ marginTop: 20 }}>
					<Form.Item name="note">
						<Input.TextArea
							placeholder="Enter an internal note, accessible only by the team"
						/>
					</Form.Item>
					<Button type="primary" onClick={updateAndRefetch} >
						Add Note
					</Button>
				</Form>
			</Card>
		</Col>
	</Row>

	{ placeDetail?.title && <TranslatePlace lang={currentLang} placeDetail={placeDetail} refetch={refetch} /> }

  </>
}

export default PlaceForm
