import { SettingOutlined } from '@ant-design/icons'
import { Button, Popover, Tooltip } from 'antd'
import { memo } from 'react'
import { ListFilesRequestParams } from '../../types/image-api'
import AdvancedSearch from './AdvancedSearch'
import { FilterState } from './Photos'

const AdvancedSearchPopover: React.FC<{
  visible: boolean
  filters: FilterState['filters']
  setFilters: FilterState['setFilters']
  handleTogglePopover: () => void
  setParams: React.Dispatch<React.SetStateAction<ListFilesRequestParams>>
}> = memo(({ visible, handleTogglePopover, filters, setFilters, setParams }) => (
  <Popover
    visible={visible}
    content={
      <AdvancedSearch
        handleTogglePopover={handleTogglePopover}
        filters={filters}
        setFilters={setFilters}
        setParams={setParams}
      />
    }
    placement="bottom"
    title="Advanced Search"
  >
    <Tooltip title="Filters">
      <Button onClick={handleTogglePopover} type="ghost">
        <SettingOutlined />
      </Button>
    </Tooltip>
  </Popover>
))

export default AdvancedSearchPopover
