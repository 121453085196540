import { useAuth0 } from '@auth0/auth0-react'
import { Button, Layout, Modal } from 'antd'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { Sidebar } from '../../components/Sidebar'

const confirm = Modal.confirm
const { Header, Content, Footer } = Layout

type Props = {}
const Dashboard: React.FC<Props> = (props) => {
  const { logout } = useAuth0()
  const onClickLogoutBtn = () => {
    confirm({
      centered: true,
      icon: null,
      title: <div style={{ textAlign: 'center' }}>Do you really want to log out?</div>,
      okText: 'Logout',
      okType: 'default',
      cancelText: 'Cancel',
      okButtonProps: {
        style: {
          backgroundColor: '#fff',
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: '#0ab068',
          color: '#fff',
        },
      },
      onOk() {
        logout()
      },
    })
  }

  return (
    <Layout>
      <Header className="header">
        <div className="logo">
          <a href="https://travelbay.pl">
            <img alt="" src="/images/logo-white.svg" width="176" />
          </a>
        </div>
        <Button
          ghost
          icon={<img alt="" src="/images/icons-logout.svg" />}
          style={{ border: 'none' }}
          onClick={onClickLogoutBtn}
        >
          <span style={{ marginLeft: 8 }}>Log out</span>
        </Button>
      </Header>
      <Layout>
        <Sidebar />
        <Layout style={{ padding: 0, marginLeft: 200, marginTop: 64 }}>
          <Content style={{ padding: 24, margin: 0, minHeight: 500 }}>
            <Outlet />
          </Content>
          <Footer style={{ textAlign: 'center' }}>Travelbay ©{new Date().getFullYear()}</Footer>
        </Layout>
      </Layout>
    </Layout>
  )
}
export default Dashboard
