import { AxiosInstance } from 'axios'
import qs from 'qs'
import { GetAllPhotosResponse, ListFilesRequestParams, ImageModel, ImageLinkModel } from '../types/image-api'
import { apiClient } from './apiClient'

class ImageService {
  public URI: string = '/images'
  constructor(private apiClient: AxiosInstance) {}

  public getAll = (params?: ListFilesRequestParams) => {
    const queryString = qs.stringify(params)
    return this.apiClient.get<{ success: GetAllPhotosResponse[] }>(`${this.URI}?${queryString}`)
  }

  public getImageDetail = (imageId: string) => {
    return this.apiClient.get<ImageModel>(`${this.URI}/detail/${imageId}`)
  }

  public updateImageDetail = (imageId: string, data: { alt: string; description: string; tags?: string }) => {
    return this.apiClient.put(`${this.URI}/${imageId}`, data)
  }

  /** Delete images from CDN and Database */
  public deleteImages = (imageIds: string[]) => {
    return this.apiClient.post(`${this.URI}/delete`, { ids: imageIds })
  }

  /** Delete images from Database only */
  public deleteImagesInDB = (imageIds: string[]) => {
    return this.apiClient.post(`${this.URI}/deleteFromDb`, { ids: imageIds })
  }

  public createImage = (data: FormData) => {
    return this.apiClient.post(`${this.URI}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  public linkImage = (data: ImageLinkModel) => {
    return this.apiClient.post(`${this.URI}/link`, data)
  }
}

export const imageService = new ImageService(apiClient)
